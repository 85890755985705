import {Harvest, HarvestSeason} from '../models/interfaces';
import {isGrapes} from '../selectors/harvest';
import {HarvestKey} from '../selectors/harvest-key';

const harvestSeasonRegex = new RegExp('-(' + HarvestSeason.join('|') + ')');

export function getHarvestSeason(crop_id: string | null): null | HarvestSeason {
  const match = crop_id?.match(harvestSeasonRegex);
  return match ? (match[1] as HarvestSeason) : null;
}

// A list of crop prefixes; similar to HarvestCrop, except for some cases such as "corn".
const cropFamilies = [
  'sugar-beet',
  'sunflower',
  'corn',
  'barley',
  'soybeans',
  'rapeseed',
  'wheat',
  'cotton',
  'peas',
  'grapes',
  'apples',
  'pears',
  'sugar-cane',
  'olives',
  'walnuts',
  'beans',
  'sorghum',
  'rice',
  'lentils',
  'linseed',
  'triticale',
  'potatoes',
  'oats',
  'buckwheat',
  'cauliflowers',
  'onions',
  'tomatoes',
  'grassland',
  'rye',
  'mustard',
  'spelt',
  'tobacco',
  'chickpeas',
  'millet',
  'hemp',
  'mangos',
  'peanuts',
  'peaches',
  'apricots',
  'nectarines',
  'cherries',
];

export function isSameOrMoreSpecificCropId(fieldCropId: null | string, farmCropId: null | string) {
  if (!fieldCropId || !farmCropId) {
    return false;
  }
  if (isGrapes(farmCropId)) {
    return fieldCropId == 'grapes';
  }

  const fieldCropSeason = getHarvestSeason(fieldCropId);
  const farmCropSeason = getHarvestSeason(farmCropId);
  const fieldCropFamily = cropFamilies.find(family => fieldCropId.includes(family));
  const farmCropFamily = cropFamilies.find(family => farmCropId.includes(family));
  return fieldCropSeason == farmCropSeason && fieldCropFamily == farmCropFamily;
}

export function shouldOverwriteWithFarmHarvest(harvests: HarvestKey[], farmHarvest: HarvestKey): boolean {
  const fieldHarvestsForYear = harvests.filter(harvest => harvest.harvest_year === farmHarvest.harvest_year);
  if (fieldHarvestsForYear.length != 1) {
    return false;
  }

  if (!isSameOrMoreSpecificCropId(fieldHarvestsForYear[0].crop_id, farmHarvest.crop_id)) {
    return false;
  }

  return (
    fieldHarvestsForYear[0].crop_id != farmHarvest.crop_id ||
    fieldHarvestsForYear[0].irrigated != farmHarvest.irrigated ||
    fieldHarvestsForYear[0].organic != farmHarvest.organic ||
    fieldHarvestsForYear[0].variety != farmHarvest.variety
  );
}

// Check that the given harvests external_harvest_id is unique enough.
// siblingHarvests should only include harvest for the same farm/field/harvest_year.
export function isUniqueSubplotId(siblingHarvests: Harvest[], current: Partial<Harvest>): boolean {
  return (
    typeof current.external_harvest_id == 'string' &&
    current.external_harvest_id.trim().length > 0 &&
    !siblingHarvests.some(
      h =>
        // Don't compare the harvest to itself.
        h.harvest_id != current.harvest_id &&
        // Only compare harvests from the same year, the caller should have already filtered by year.
        h.harvest_year == current.harvest_year &&
        // No duplicate external_harvest_id within a single harvest_year.
        h.external_harvest_id == current.external_harvest_id,
    )
  );
}
