import {Switch, Table, TableColumnType} from 'antd';
import React, {FC, useCallback, useState} from 'react';
import {MembershipType, UserGroup} from '../../../src/models/interfaces';
import {useApis} from '../apis/ApisContext';
import {
  AdminUserEntity,
  MembershipUpdate,
  MembershipUpdates,
  toName,
  useAdminUserGroupFilter,
  useTransitiveUserGroups,
} from '../util/admin-util';

type UserGroupMembershipMatrixValue = {checked: boolean; existingCount?: number};
type UserGroupMembershipMatrix = {
  [key in MembershipType]: UserGroupMembershipMatrixValue;
} & {
  user_group: UserGroup;
};

// We don't support setting user-admin rights via the UI for the moment.
const assignableMembershipTypes: Readonly<MembershipType[]> = MembershipType.filter(m => m != 'user-admin');

export const AdminUserEntityEdit: FC<{
  selected: AdminUserEntity[];
  onChange: (updates: MembershipUpdates) => void;
}> = ({selected, onChange}) => {
  const {t} = useApis();
  const {grantees} = useTransitiveUserGroups();
  const transitiveUserAdminGroups = useAdminUserGroupFilter(selected);
  const [matrix, setMatrix] = useState<UserGroupMembershipMatrix[]>(
    transitiveUserAdminGroups.map(admin_user_group => {
      const byMembership = {
        ...Object.fromEntries(
          assignableMembershipTypes.map(type => [
            type,
            {
              checked: selected.every(s =>
                s.memberships.some(
                  m => m.user_group.user_group == admin_user_group.user_group && m.membership_type == type,
                ),
              ),
              existingCount: selected.filter(s =>
                s.memberships.some(
                  m => m.user_group.user_group == admin_user_group.user_group && m.membership_type == type,
                ),
              ).length,
            },
          ]),
        ),
      } as {
        [key in MembershipType]: UserGroupMembershipMatrixValue;
      };
      return {
        user_group: admin_user_group,
        ...byMembership,
      };
    }),
  );
  const updateMatrix = useCallback(
    (userGroup: UserGroup, key: MembershipType) => (checked: boolean) => {
      setMatrix(m => {
        const index = m.findIndex(x => x.user_group.user_group == userGroup.user_group);
        const updatedMatrix: UserGroupMembershipMatrix[] = [
          ...m.slice(0, index),
          {
            ...m[index],
            [key]: {...m[index][key], checked: checked},
          },
          ...m.slice(index + 1),
        ];
        onChange(getMembershipChanges(updatedMatrix, selected, transitiveUserAdminGroups));
        return updatedMatrix;
      });
    },
    [onChange, selected, transitiveUserAdminGroups],
  );
  const columns: TableColumnType<UserGroupMembershipMatrix>[] = [
    {
      key: 'user_group',
      dataIndex: 'user_group',
      title: t('user_group'),
      filters: transitiveUserAdminGroups.map(u => ({text: toName(u, true), value: u.user_group})),
      filterSearch: true,
      filterMultiple: true,
      onFilter: (value, record) =>
        toName(record.user_group).includes(String(value)) || record.user_group.user_group.includes(String(value)),
      render: v => <MatrixUserGroup userGroup={v} />,
    },
    ...assignableMembershipTypes.map<TableColumnType<UserGroupMembershipMatrix>>(type => ({
      key: type,
      dataIndex: type,
      title: t(type),
      width: '12em',
      render: (_, record) => {
        const grantingGrantees = grantees[record.user_group.user_group]
          .filter(g => g.user_group != record.user_group.user_group) // Don't show self-grants.
          .filter(g => {
            const userGroupState = matrix.find(m => m.user_group.user_group == g.user_group);
            return userGroupState?.[type].checked;
          });
        const grantExplanation =
          record[type].checked && grantingGrantees.length > 0
            ? t('AlsoInheritedFrom') + ': ' + grantingGrantees.map(g => toName(g)).join(', ')
            : !record[type].checked && grantingGrantees.length > 0
            ? t('InheritedFrom') + ': ' + grantingGrantees.map(g => toName(g)).join(', ')
            : '';
        const grantedAnyWay = record[type].checked || grantingGrantees.length > 0;
        return (
          <>
            <Switch
              checked={grantedAnyWay}
              onChange={updateMatrix(record.user_group, type)}
              aria-label={record.user_group.user_group + ':' + type}
            />
            <div style={{color: '#bbb', marginTop: 5}}>{grantExplanation}</div>
          </>
        );
      },
    })),
  ];
  return (
    <>
      <Table
        dataSource={matrix}
        size="small"
        rowKey={x => x.user_group.user_group}
        columns={columns}
        pagination={{
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
        }}
      />
    </>
  );
};

const MatrixUserGroup: FC<{userGroup: UserGroup}> = ({userGroup}) => {
  const {t} = useApis();
  const {grantees} = useTransitiveUserGroups();
  const myGrantees: UserGroup[] = grantees[userGroup.user_group].filter(g => g.user_group != userGroup.user_group);
  return (
    <div>
      <div>{toName(userGroup, true)}</div>
      {myGrantees.length > 0 ? (
        <div style={{color: '#bbb'}}>
          {t('GrantsTo')}: {myGrantees.map(g => toName(g)).join(', ')}
        </div>
      ) : null}
    </div>
  );
};

function getMembershipChanges(
  matrix: UserGroupMembershipMatrix[],
  selected: AdminUserEntity[],
  transitiveUserAdminGroups: UserGroup[],
) {
  const nextMemberships: MembershipUpdate[] = matrix.flatMap(row =>
    assignableMembershipTypes
      .filter(type => row[type].checked)
      .flatMap(type =>
        selected.map(u => {
          return {email: u.email, user_group: row.user_group, membership_type: type};
        }),
      ),
  );

  const prevMemberships = selected
    .flatMap(s => s.memberships)
    // We don't manage user-admins here for the moment. So we pretend they don't exist during updates.
    .filter(m => m.membership_type != 'user-admin')
    // Only show user_group memberships updates, for which current user has user-admin rights.
    // E.g. a user might be a full member of a user group, but lacks (transitive) user-admin rights on that group.
    // If we'd show it in the user table, the user would get the impression that he can manage the group.
    .filter(m => transitiveUserAdminGroups.some(a => a.user_group == m.user_group.user_group));
  const inserts = nextMemberships.filter(
    next =>
      !prevMemberships.some(
        prev =>
          prev.email == next.email &&
          prev.user_group.user_group == next.user_group.user_group &&
          prev.membership_type == next.membership_type,
      ),
  );
  const deletes = prevMemberships.filter(
    x =>
      !nextMemberships.some(
        y =>
          y.email == x.email &&
          y.user_group.user_group == x.user_group.user_group &&
          y.membership_type == x.membership_type,
      ),
  );
  return {
    grant: inserts,
    revoke: deletes,
  };
}
