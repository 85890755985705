import {FeatureCollection} from 'geojson';
import mapboxgl, {LngLatBounds} from 'mapbox-gl';
import {RefObject, useContext, useEffect, useRef, useState} from 'react';
import equal from '../../../src/fast-deep-equal';
import {FollowedLayer} from '../../../src/follow-layer';
import {getBoundingBox} from '../../../src/geo';
import {LayerParams} from '../../../src/layers/layer-params';
import {MapLayers, isFieldLayer, isRegionalLayer} from '../../../src/layers/map-layers';
import {strToLayerType} from '../../../src/models/types';
import {ApisContext} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {LayerFetcher} from './layer-fetcher';

export function useLayerFeatures(
  map: RefObject<undefined | mapboxgl.Map>,
  layer: MapLayers,
  canonical_date: null | string,
  visibleFieldIds: Set<string>,
  layerParams: null | LayerParams,
): {layerGeojson: null | FeatureCollection; loadingLayer: boolean} {
  const {authedFetcher, followLayer} = useContext(ApisContext);
  const [layerGeojson, setLayerGeojson] = useState<null | FeatureCollection>(null);
  const [loadingLayer, setLoadingLayer] = useState<boolean>(false);
  const recenterToNextLayer = useRef<boolean>(false);
  const layerFetcher = useRef(new LayerFetcher(authedFetcher, setLayer, setLoadingLayer));
  const followedLayer = useRef<null | FollowedLayer>(null);
  const stopFollowLayer = useRef<null | (() => void)>(null);
  const [layerUpdatedAt, setLayerUpdatedAt] = useState<null | string>(null);
  useEffect(
    () => {
      const newFollowedLayer: null | FollowedLayer =
        isRegionalLayer(layer) && layer != 'crop-mon' && canonical_date
          ? {layer_type: layer, canonical_date: canonical_date, fieldIds: null}
          : visibleFieldIds.size && isFieldLayer(layer) && canonical_date
          ? {layer_type: layer, canonical_date: canonical_date, fieldIds: Array.from(visibleFieldIds)}
          : null;
      if (!equal(newFollowedLayer, followedLayer.current)) {
        followedLayer.current = newFollowedLayer;
        stopFollowLayer.current && stopFollowLayer.current();
        stopFollowLayer.current = null;
        if (newFollowedLayer) {
          stopFollowLayer.current = followLayer(newFollowedLayer, setLayerUpdatedAt);
        } else {
          stopFollowLayer.current = null;
        }
      }

      if (
        layer == 'crop-mon' &&
        layerParams?.layer_type == 'crop-mon' &&
        layerParams?.params[0] != layerFetcher.current.layerParams?.params[0]
      ) {
        recenterToNextLayer.current = true;
      }

      layerFetcher.current
        .fetch(
          strToLayerType(layer),
          canonical_date || null,
          layerUpdatedAt,
          isFieldLayer(layer) ? visibleFieldIds : null,
          layerParams,
        )
        .catch(reportErr);

      return () => {
        stopFollowLayer.current && stopFollowLayer.current();
      };
    }, // Serialize deps in order to be sure to avoid unnecessary duplicate fetches.
    [layer, canonical_date, Array.from(visibleFieldIds).join(','), JSON.stringify(layerParams), layerUpdatedAt],
  );

  return {layerGeojson, loadingLayer};

  function setLayer(geojson: null | FeatureCollection) {
    setLayerGeojson(geojson);
    if (recenterToNextLayer.current && geojson) {
      recenterToNextLayer.current = false;
      const bbox = getBoundingBox(geojson);
      if (bbox) {
        map.current?.fitBounds(new LngLatBounds(bbox.sw, bbox.ne), {
          padding: {
            right: 400,
            top: 50,
            bottom: 50,
            left: 50,
          },
        });
      }
    }
  }
}
