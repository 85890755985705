import {FetcherFunc} from '../FetcherFunc';
import {FormyI} from '../Formy';
import {PALETTE_COLORS} from '../constants/colors';
import {ReportConfig} from '../forms/ReportConfig';
import {StateOrm} from '../gt-pack/stateOrm';
import {I18nFunction} from '../i18n/i18n';
import {Farm, HarvestData, HarvestYear, Policy, Sample} from '../models/interfaces';
import {DbResponseContents} from '../redux/actions/db';
import {CoreDbState, DbState} from '../redux/reducers/db';
import {filterNulls, remove, unique} from '../util/arr-util';
import cmp from '../util/cmp';
import {fetchEntitiesBy, fetchEntity} from '../util/fetchEntity';
import {PostgrestQuery, getPostgrestQueryParams} from '../util/postgrest-query';
import {clientLogos, headerLogo} from './logos';

export async function updateReportWithOptions(
  authedFetcher: FetcherFunc,
  field: keyof ReportConfig,
  formy: FormyI<ReportConfig>,
) {
  if (field != 'farm_id' && field != 'harvest_year') {
    return;
  }
  if (!formy.getValue('farm_id') || !formy.getValue('harvest_year')) {
    formy.getChangeHandler('sampleDates')({});
    formy.getChangeHandler('cropIds')({});
    return;
  }

  const params = getPostgrestQueryParams({
    and: [
      {column: 'farm_id', operator: 'eq', value: formy.getValue('farm_id')},
      {column: 'harvest_year', operator: 'eq', value: formy.getValue('harvest_year')},
    ],
  });
  const harvestData: HarvestData[] = await authedFetcher({method: 'GET', path: 'api/harvest_data', params});
  formy.getChangeHandler('sampleDates')(
    Object.fromEntries(harvestData.map(h => (h.sample_dates ?? []).map(s => [s, true])).flat()),
  );
  formy.getChangeHandler('cropIds')(Object.fromEntries(harvestData.map(h => [h.crop_id, true])));
  const farm = await fetchEntity(authedFetcher, 'farm', formy.getValue('farm_id')!);
  formy.getChangeHandler('farmer_email')(farm.farmer_email);
  formy.getChangeHandler('numFields')(harvestData.reduce((acc, x) => acc + (x.field_id ? 1 : 0), 0));
}

async function fetchClaimEntities(stateOrm: StateOrm, farm_id: string) {
  const claims = await stateOrm.fetchEntitiesBy('claim', {
    column: 'farm_id',
    operator: 'eq',
    value: farm_id,
  });
  return {
    claims,
    visits: await stateOrm.fetchEntitiesBy('visit', {
      column: 'claim_id',
      operator: 'in',
      value: claims.map(claim => claim.claim_id),
    }),
    claimDamages: await stateOrm.fetchEntitiesBy('claim_damage', {
      operator: 'in',
      column: 'claim_id',
      value: claims.map(claim => claim.claim_id),
    }),
  };
}

export async function fetchCoreFarmEntities(stateOrm: StateOrm, farm_id: string): Promise<CoreFarmEntities> {
  const [farm, fields] = await Promise.all([
    stateOrm.fetchEntitiesBy('farm', {column: 'farm_id', operator: 'eq', value: farm_id}),
    stateOrm.fetchEntitiesBy('field', {column: 'farm_id', operator: 'eq', value: farm_id}),
  ]);
  if (!farm.length) {
    throw new Error(`Farm ${farm_id} not found`);
  }
  const harvests = await stateOrm.fetchEntitiesBy('harvest', {
    or: [
      {operator: 'eq', column: 'farm_id', value: farm_id},
      {operator: 'in', column: 'field_id', value: fields.map(x => x.field_id)},
    ],
  });
  const policyIds = unique(filterNulls(harvests.map(h => h.policy_id)));
  const [policies, samples] = await Promise.all([
    stateOrm.fetchEntitiesBy('policy', {operator: 'in', column: 'policy_id', value: policyIds}),
    stateOrm.fetchEntitiesBy('sample', {
      operator: 'in',
      column: 'harvest_id',
      value: harvests.map(h => h.harvest_id),
    }),
  ]);
  return {
    farm: farm[0],
    policy: policies,
    field: fields,
    harvest: harvests,
    sample: samples,
  };
}

export async function fetchFarmEntities(stateOrm: StateOrm, farm_id: string): Promise<FarmEntities> {
  const [farm, fields, {claims, visits, claimDamages}] = await Promise.all([
    stateOrm.fetchEntitiesBy('farm', {column: 'farm_id', operator: 'eq', value: farm_id}),
    stateOrm.fetchEntitiesBy('field', {column: 'farm_id', operator: 'eq', value: farm_id}),
    fetchClaimEntities(stateOrm, farm_id),
  ]);
  if (!farm.length) {
    throw new Error(`Farm ${farm_id} not found`);
  }
  const harvests = await stateOrm.fetchEntitiesBy('harvest', {
    or: [
      {operator: 'eq', column: 'farm_id', value: farm_id},
      {operator: 'in', column: 'field_id', value: fields.map(x => x.field_id)},
    ],
  });
  const policyIds = unique(filterNulls(harvests.map(h => h.policy_id).concat(claims.map(x => x.policy_id))));
  const [policies, samples] = await Promise.all([
    stateOrm.fetchEntitiesBy('policy', {operator: 'in', column: 'policy_id', value: policyIds}),
    stateOrm.fetchEntitiesBy('sample', {
      operator: 'in',
      column: 'harvest_id',
      value: harvests.map(h => h.harvest_id),
    }),
  ]);
  return {
    farm: farm[0],
    policy: policies,
    field: fields,
    harvest: harvests,
    sample: samples,
    visit: visits,
    claim: claims,
    claim_damage: claimDamages,
  };
}

export async function fetchReportState(
  authedFetcher: FetcherFunc,
  config: ReportConfig,
): Promise<null | Pick<DbState, 'farm' | 'field' | 'harvest' | 'sample' | 'policy'>> {
  if (!config.farm_id || !config.harvest_year) {
    return null;
  }
  const crop_id: string[] = Object.entries(config.cropIds)
    .filter(x => x[1])
    .map(x => x[0]);
  const sample_date: string[] = Object.entries(config.sampleDates)
    .filter(x => x[1])
    .map(x => x[0]);

  const [farm, fields] = await Promise.all([
    fetchEntity(authedFetcher, 'farm', config.farm_id),
    fetchEntitiesBy(authedFetcher, 'field', {column: 'farm_id', operator: 'eq', value: config.farm_id}),
  ]);
  const harvestFilters: PostgrestQuery[] = [{column: 'harvest_year', operator: 'eq', value: config.harvest_year}];
  if (crop_id?.length) {
    harvestFilters.push({column: 'crop_id', operator: 'in', value: crop_id});
  }
  const [farmHarvests, fieldHarvests] = await Promise.all([
    fetchEntitiesBy(authedFetcher, 'harvest', {
      and: [...harvestFilters, {column: 'farm_id', operator: 'eq', value: config.farm_id}],
    }),
    fetchEntitiesBy(authedFetcher, 'harvest', {
      and: [...harvestFilters, {column: 'field_id', operator: 'in', value: fields.map(x => x.field_id)}],
    }),
  ]);
  const harvests = farmHarvests.concat(fieldHarvests);
  const sampleFilters: PostgrestQuery[] = [
    {
      column: 'harvest_id',
      operator: 'in',
      value: harvests.map(x => x.harvest_id),
    },
  ];
  if (sample_date?.length) {
    sampleFilters.push({column: 'sample_date', operator: 'in', value: sample_date});
  }
  const [samples, policies] = await Promise.all([
    fetchEntitiesBy(authedFetcher, 'sample', {and: sampleFilters}),
    fetchEntitiesBy(authedFetcher, 'policy', {
      column: 'policy_id',
      operator: 'in',
      value: unique(filterNulls(harvests.map(x => x.policy_id))),
    }),
  ]);

  const harvestFieldIds = new Set(filterNulls(harvests.map(x => x.field_id)));
  return {
    farm: {[config.farm_id]: farm},
    field: Object.fromEntries(fields.filter(x => harvestFieldIds.has(x.field_id)).map(x => [x.field_id, x])),
    harvest: Object.fromEntries(harvests.map(x => [x.harvest_id, x])),
    sample: Object.fromEntries(samples.map(x => [x.sample_id, x])),
    policy: Object.fromEntries(policies.map(x => [x.policy_id, x])),
  };
}

export function getReportStats(db: Omit<DbState, 'visit'>): {numFields: number; numPhotos: number} {
  const numFields = Object.keys(db.field).length;
  let numPhotos = 0;
  for (const sample_id in db.sample) {
    numPhotos += db.sample[sample_id].images.length;
  }
  return {numFields, numPhotos};
}

export const getReportGenerationPath = (
  config: ReportConfig,
  locale: string,
): null | {path: string; params: [string, string][]} => {
  try {
    const params = config.getUrlParams();
    params.push(['locale', locale]);
    return {path: `api2/farm-report`, params};
  } catch (e) {
    return null;
  }
};

export async function sendPDFReport(config: ReportConfig, locale: string, emails: string[], fetcher: FetcherFunc) {
  const {path, params} = getReportGenerationPath(config, locale)!;

  for (const email of emails) {
    params.push(['email', email]);
  }
  return await fetcher({method: 'POST', path, params});
}

export function cmpSamples(aS: Sample, bS: Sample): number {
  return cmp((aS.sample_date || '') + '-' + aS.sample_id, (bS.sample_date || '') + '-' + bS.sample_id);
}

export function getMainUserGroup(user_group: string) {
  // This assumes that the user group is of the form MAINCLIENT-...-...
  return user_group.split('-')[0];
}

export function getReportStyling(user_group: string) {
  const mainUserGroupCode = getMainUserGroup(user_group);
  return clientLogos[mainUserGroupCode] || {color: PALETTE_COLORS.primary, logo: headerLogo, offset: 22};
}

export function getReportFilename(
  t: I18nFunction,
  prefix: 'VisitReport' | 'ExpertReport',
  farm: Farm,
  harvestYear: HarvestYear,
  policies: Policy[],
) {
  const policyNumbers = [...new Set<string>(policies.map(x => x.policy_number).filter(remove.nulls))];
  const policiesLabel =
    policyNumbers && policyNumbers.length > 0
      ? policyNumbers.slice(0, 6).join('_') + (policyNumbers.length > 6 ? '_etc' : '')
      : '';

  const sanitize = (x: string) => x.replace(/[^a-zA-Z0-9'_. \-]/g, '-');
  return sanitize([t(prefix), farm.farm_name, policiesLabel, harvestYear].filter(remove.falsy).join(' - ') + '.pdf');
}

export function getArchivedTelepacReportFilename(farm_id: string, harvest_year: HarvestYear) {
  return `telepac-import-report-${harvest_year}-${farm_id}.pdf`;
}

export type FarmEntities = Omit<DbResponseContents, 'update_log' | 'farm'> & {
  farm: Farm;
};

export type CoreFarmEntities = Pick<FarmEntities, 'farm' | 'policy' | 'field' | 'harvest' | 'sample'>;

export function filterFarmEntities(
  entities: FarmEntities | DbState,
  harvest_year: null | HarvestYear,
  cropIds: null | string[],
  sampleDates: null | string[],
): DbState;
export function filterFarmEntities(
  entities: CoreFarmEntities | CoreDbState,
  harvest_year: null | HarvestYear,
  cropIds: null | string[],
  sampleDates: null | string[],
): CoreDbState;
export function filterFarmEntities(
  entities: FarmEntities | CoreFarmEntities | DbState | CoreDbState,
  harvest_year: null | HarvestYear,
  cropIds: null | string[],
  sampleDates: null | string[],
): DbState | CoreDbState {
  const res: DbState = {
    farm: {},
    policy: {},
    field: {},
    harvest: {},
    sample: {},
    visit: {},
    claim: {},
    claim_damage: {},
  };

  const farm: null | Farm = 'farm_id' in entities.farm ? (entities.farm as Farm) : Object.values(entities.farm)[0];
  if (farm) {
    res.farm[farm.farm_id] = farm;
  }
  for (const harvest of Object.values(entities.harvest)) {
    if (harvest.harvest_year == harvest_year && (!cropIds || !cropIds.length || cropIds.includes(harvest.crop_id!))) {
      res.harvest[harvest.harvest_id] = harvest;
      if (harvest.policy_id) {
        res.policy[harvest.policy_id] = Object.values(entities.policy).find(x => x.policy_id == harvest.policy_id)!;
      }
      if (harvest.field_id) {
        const field = Object.values(entities.field).find(x => x.field_id == harvest.field_id);
        if (field) {
          res.field[field.field_id] = field;
        } else {
          console.error(`Missing field ${harvest.field_id} for harvest ${harvest.harvest_id}`);
        }
      }
    }
  }

  for (const sample of Object.values(entities.sample)) {
    if ((!sampleDates || sampleDates.includes(sample.sample_date!)) && res.harvest[sample.harvest_id]) {
      res.sample[sample.sample_id] = sample;
    }
  }

  if ('claim' in entities) {
    for (const claim of Object.values(entities.claim)) {
      const harvestYearMatches = Object.values(entities.claim_damage).some(
        claimDamage => claimDamage.claim_id == claim.claim_id && claimDamage.harvest_year == harvest_year,
      );
      if (harvestYearMatches) {
        res.visit = Object.fromEntries(
          Object.values(entities.visit)
            .filter(visit => visit.claim_id == claim.claim_id)
            .map(visit => [visit.visit_id, visit]),
        );
      }
    }
  }

  return res;
}
