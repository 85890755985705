import {History} from 'history';
import React, {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {FormyChanged, FormyErrors, FormyI, keepChangedFields} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {FormyComponent} from '../../../src/Formy/FormyComponent';
import {FarmForm, validateFarmForm} from '../../../src/forms/FarmForm';
import {updateFarm} from '../../../src/redux/actions/db';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {FormyAddress} from '../Formy/FormyAddress';
import FormyEditorsSelector from '../Formy/FormyEditorsSelector';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormySuggest} from '../Formy/FormySuggest';
import FormyUserGroupSelector from '../Formy/FormyUserGroupSelector';
import {Apis} from '../apis/Apis';
import {ApisContext, useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {commitMutations} from './commit';

function validateFarmForms(x: FarmForms): FormyErrors<FarmForms> {
  const res: FormyErrors<{[farm_id: string]: FarmForm}> = {};
  for (const farm_id in x.farms) {
    res[farm_id] = validateFarmForm(x.farms[farm_id]);
  }
  return {farms: res};
}

class FarmForms {
  farms: {[farm_id: string]: FarmForm} = {};
}

class FarmRows extends FormyComponent<FarmForms, 'farms'> {
  static contextType = ApisContext;
  context!: Apis;

  renderRow(farm_id: string, formy: FormyI<FarmForm>) {
    return (
      <span className="import-row" key={farm_id}>
        <span className="import-cell">
          <FormyUserGroupSelector formy={formy} field="user_group" />
        </span>
        <span className="import-cell">
          <FormySuggest onEntitySelected={null} label="FarmReference" field="external_farm_id" formy={formy} />
        </span>
        <span className="import-cell">
          <FormySuggest onEntitySelected={null} label="FarmName" field="farm_name" formy={formy} />
        </span>
        <span className="import-cell">
          <FormyAddress formy={formy} />
        </span>
        <span className="import-cell">
          <FormyEditorsSelector formy={formy} />
        </span>
      </span>
    );
  }

  render() {
    const farm_ids = Object.keys(this.value);
    const formy = this.props.formy.getSectionFormy('farms');
    return (
      <span className="import-form">
        <span>
          <span className="import-row header">
            <span className="import-cell">{formy.t('UserGroup')}</span>
            <span className="import-cell">{formy.t('FarmReference')}</span>
            <span className="import-cell">{formy.t('FarmName')}</span>
            <span className="import-cell">{formy.t('FarmAddress')}</span>
            <span className="import-cell">{formy.t('Editors')}</span>
          </span>
          {farm_ids.map(farm_id => this.renderRow(farm_id, formy.getSectionFormy(farm_id)))}
        </span>
      </span>
    );
  }
}

async function init(apis: Apis, history: History, farm_ids: string[]) {
  const initialValues: FarmForms = new FarmForms();
  const farms = await fetchEntities(apis.authedFetcher, 'farm', farm_ids);
  for (const farm of farms) {
    initialValues.farms[farm.farm_id] = new FarmForm(farm, null, null);
  }

  async function onSubmit(values: FarmForms, changedCols: FormyChanged<FarmForms>) {
    try {
      const changedFarms =
        typeof changedCols == 'object' && typeof changedCols.farms == 'object' ? changedCols.farms : {};
      for (const farm_id in values.farms) {
        const changed = keepChangedFields(values.farms[farm_id], changedFarms[farm_id]);
        if (changed) {
          apis.store.dispatch(updateFarm(apis, farm_id, changed));
        }
      }

      if (await commitMutations(apis)) {
        history.goBack();
      }
    } catch (e) {
      reportErr(e, 'EditFarms::onSubmit');
    }
  }

  const formy = new Formy('edit', initialValues, apis.t, onSubmit, validateFarmForms);
  return {formy};
}

export default function EditFarms() {
  const apis = useApis(),
    location = useLocation(),
    history = useHistory();
  const farm_ids = useMemo(() => new URLSearchParams(location.search).get('farm_ids')?.split(',') ?? [], [location]);
  const content = useAsyncMemo(() => init(apis, history, farm_ids), [apis, farm_ids, history]);
  if (!content) {
    return null;
  }

  return (
    <span key={farm_ids.join(',')}>
      <FarmRows formy={content.formy} field="farms" />
      <span className="import-save">
        <FormySubmit label="Save" formy={content.formy} />
      </span>
    </span>
  );
}
