import {ArrowLeftOutlined, SettingOutlined} from '@ant-design/icons';
import {PageHeader, Space, message} from 'antd';
import {saveAs} from 'file-saver';
import React, {ReactElement} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {serializeParams} from '../../../src/FetcherFunc';
import {FormyI} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {ReportConfig, validateReportConfig} from '../../../src/forms/ReportConfig';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {generateReport} from '../../../src/report/generate-report';
import {FarmReportPackage} from '../../../src/report/report-types';
import {
  fetchReportState,
  getReportGenerationPath,
  sendPDFReport,
  updateReportWithOptions,
} from '../../../src/report/report-util';
import {getUserConfig} from '../../../src/selectors/userConfig';
import {farmDesc} from '../../../src/text/desc';
import {filterFalsy} from '../../../src/util/arr-util';
import {fetchFieldShapes} from '../../../src/util/fetchFieldShapes';
import {FormyCheckbox} from '../Formy/FormyCheckbox';
import {FormyFarmerEmail} from '../Formy/FormyFarmerEmail';
import {FormySubmit} from '../Formy/FormySubmit';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import '../map/Map.css';
import {FarmReport} from '../report/FarmReport';
import {ReportConfigForm} from '../report/ReportConfigForm';
import {reportErr} from '../util/err';
import {parseQueryParams} from '../util/parse-query-params';

globalThis.reportRouterState = {state: 'Init'};

interface ReportRouterStateInit {
  type: 'Init';
}

interface ReportRouterStateConfig {
  type: 'Config';
}

interface ReportRouterStateReport {
  type: 'Report';
  report: FarmReportPackage;
}

type ReportRouterState = ReportRouterStateInit | ReportRouterStateConfig | ReportRouterStateReport;

export default class ReportRouter extends React.Component<RouteComponentProps, ReportRouterState> {
  static contextType = ApisContext;
  context!: Apis;
  state: ReportRouterState = {type: 'Init'};
  private formy: Formy<ReportConfig> = new Formy(
    'new',
    new ReportConfig(),
    this.context.t,
    async () => {},
    validateReportConfig,
  );

  componentDidMount() {
    this.formy.addOnChangeListener(this.onChange);
    this.formy.getChangeHandler('requireEmail')(false);
    this.updateFromProps();
  }

  componentDidUpdate(prevProps: Readonly<RouteComponentProps>) {
    if (this.props.location.search != prevProps.location.search) {
      this.updateFromProps();
    }
  }

  onChange = (field: keyof ReportConfig, values: ReportConfig) => {
    updateReportWithOptions(this.context.authedFetcher, field, this.formy).catch(reportErr);
  };

  setStateAndGlobalVariable = (state: ReportRouterState) => {
    this.setState(state);
    if (state.type == 'Report') {
      const {farm, summaryByHarvest, config} = state.report;
      const farmName: string = farm && farm.farm_name ? farm.farm_name : '';
      const harvestYear = config.harvest_year!;
      const policies = [
        ...new Set<string>(filterFalsy(summaryByHarvest.map(s => s.harvests.map(h => h.policy?.policy_number)).flat())),
      ];
      globalThis.reportRouterState = {state: state.type, data: {farmName, policies, harvestYear}};
    } else {
      globalThis.reportRouterState = {state: state.type};
    }
  };

  updateFromProps = async () => {
    if (this.props.location.search) {
      try {
        const params = parseQueryParams(this.props.location.search);
        fillReportConfigFromUrlParams(this.formy, params);
        const config = this.formy.getValues();
        if (config.isValid()) {
          this.showLoadingMessage('Loading', 'preview');
          const fieldShapes = await fetchFieldShapes(this.context.authedFetcher, [['farm_id', 'eq.' + config.farm_id]]);
          const state = this.context.store.getState();
          const dbState = await fetchReportState(this.context.authedFetcher, config);
          if (!dbState) {
            throw new Error(`Couldn't fetch dbState for report.`);
          }
          const report = await generateReport(this.context, getUserConfig(state), dbState, config, false, fieldShapes);
          message.destroy('preview');
          this.setStateAndGlobalVariable({type: 'Report', report});
        } else {
          // Deal with situation where query only contains language change, e.g. /farm_report?hl=en
          this.props.history.push('/farm-report');
        }
      } catch (e) {
        message.error({content: this.context.t('Error'), duration: 5, key: 'preview'});
        reportErr(e, 'ReportRouter::updateFromProps');
      }
    } else {
      this.setStateAndGlobalVariable({type: 'Config'});
    }
  };

  showLoadingMessage = (messageText: I18nSimpleKey, key: string) => {
    message.loading({
      content: this.context.t(messageText) + '...',
      duration: 0,
      key: key,
      icon: <SpinningDots size={16} />,
    });
  };

  onPreview = async (): Promise<boolean> => {
    const config = this.formy.getValues();

    this.context.analytics.logEvent({
      event_name: 'ReportRouter::preview',
      props: {config, locale: this.context.locale},
    });

    this.props.history.push('/farm-report?' + serializeParams(config.getUrlParams()));
    return true;
  };

  onDownload = async (): Promise<boolean> => {
    const config = this.formy.getValues();
    const {params, path} = getReportGenerationPath(config, this.context.locale)!;

    this.context.analytics.logEvent({
      event_name: 'ReportRouter::download::request',
      props: {config, locale: this.context.locale},
    });

    this.showLoadingMessage('Downloading', 'download');
    try {
      const res = await this.context.authedFetcher({method: 'GET', path, params, responseType: 'response'});
      const blob = await res.blob();
      const contentDisposition = res.headers.get('content-disposition');
      const match = contentDisposition && contentDisposition.match(`filename=(.+\\.pdf)`);
      const filename = match ? match[1] : 'report.pdf';
      saveAs(blob, filename);
      this.context.analytics.logEvent({
        event_name: 'ReportRouter::download::success',
        props: {filename, config, locale: this.context.locale},
      });
      message.success({content: this.context.t('Done'), duration: 2, key: 'download'});
      return true;
    } catch (e) {
      reportErr(e, 'ReportRouter::download');
      message.error({content: this.context.t('Error'), duration: 5, key: 'download'});
      return false;
    }
  };

  onSend = async (): Promise<boolean> => {
    const config = this.formy.getValues();
    const {t} = this.context;
    const emails = [];

    if (config.sendToUser) {
      emails.push(this.context.store.getState().dbMeta.curUser!.email);
    }

    // TODO(sad): if farmer_email is missing, we should be showing a link to a prompt to add his/her email.
    if (config.sendToFarmer && config.farmer_email) {
      emails.push(config.farmer_email);
    }

    if (emails.length == 0) {
      return false;
    }

    this.showLoadingMessage('Sending', 'email');
    try {
      await sendPDFReport(config, this.context.locale, emails, this.context.authedFetcher);
      message.success({content: t('Sent'), duration: 2, key: 'email'});
      return true;
    } catch (e) {
      reportErr(e, 'ReportRouter::email');
      message.error({content: t('Error'), duration: 5, key: 'email'});
      return false;
    }
  };

  renderHeader = (opts: {suffix: string; icon?: ReactElement; tags?: ReactElement[]; onBack?: () => void}) => {
    const {t} = this.context;
    const {suffix, icon, tags, onBack} = opts;

    return (
      <PageHeader
        title={t('ExpertReport') + ' - ' + suffix}
        backIcon={icon}
        onBack={onBack ? onBack : () => {}}
        className="no-print" //Hide header when printing
        tags={tags}
      />
    );
  };

  render() {
    const {t, clock, fetchFn, authedFetcher} = this.context;
    const userEmail = this.context.store.getState().dbMeta.curUser!.email;

    if (this.state.type == 'Config') {
      return (
        <>
          {this.renderHeader({suffix: t('Configuration'), icon: <SettingOutlined />})}
          <ReportConfigForm
            formy={this.formy}
            onPreview={this.onPreview}
            onDownload={this.onDownload}
            onSend={this.onSend}
          />
        </>
      );
    } else if (this.state.type == 'Report') {
      const {report} = this.state;
      return (
        <>
          {this.renderHeader({
            suffix: farmDesc(t, report.farm!),
            icon: <ArrowLeftOutlined />,
            onBack: () => {
              this.props.history.replace('/farm-report');
              this.setStateAndGlobalVariable({type: 'Config'});
            },
            tags: [
              <Space key="tag-0">
                <FormySubmit label="Download" formy={this.formy} onSubmitOverride={this.onDownload} />

                <FormyCheckbox formy={this.formy} field="sendToUser" label={() => userEmail} labelPosition={'left'} />
                <FormyFarmerEmail formy={this.formy} field="farmer_email" />
                <FormySubmit label="Send" formy={this.formy} onSubmitOverride={this.onSend} />
              </Space>,
            ],
          })}
          <FarmReport {...report} t={t} clock={clock} fetchFn={fetchFn} authedFetcher={authedFetcher} />
        </>
      );
    } else {
      return null;
    }
  }
}

function fillReportConfigFromUrlParams(formy: FormyI<ReportConfig>, params: [string, string][]) {
  // params order is not guaranteed, so don't process sequentially

  const farm_id = params.filter(row => row[0] == 'farm_id')[0]?.[1];
  const harvest_year = params.filter(row => row[0] == 'harvest_year')[0]?.[1];
  const sample_dates = params.filter(row => row[0] == 'sample_date').map(row => row[1]);
  const cropIds = params.filter(row => row[0] == 'crop_id').map(row => row[1]);

  // Need to first process farm_id, then harvest_year, then the rest
  formy.getChangeHandler('farm_id')(farm_id);
  formy.getChangeHandler('harvest_year')(harvest_year);

  // Unselect all crop types and sample dates (selected by default)
  for (const crop of Object.keys(formy.getSectionFormy('cropIds').getValues())) {
    formy.getSectionFormy('cropIds').getChangeHandler(crop)(false);
  }
  cropIds.forEach(x => formy.getSectionFormy('cropIds').getChangeHandler(x)(true));

  for (const sampleDate of Object.keys(formy.getSectionFormy('sampleDates').getValues())) {
    formy.getSectionFormy('sampleDates').getChangeHandler(sampleDate)(false);
  }
  sample_dates.forEach(x => formy.getSectionFormy('sampleDates').getChangeHandler(x)(true));
}
