import {Affix, Layout, Modal} from 'antd';
import {History} from 'history';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Route, Router, Switch, useLocation} from 'react-router-dom';
import {getEnabledFlags} from '../../../src/feature-flags';
import BulkImportPage from '../admin/BulkImportPage';
import {ReviewFarm} from '../admin/ReviewFarm';
import AdminPage from '../admin/UserAdminPage';
import {ApisContext} from '../apis/ApisContext';
import Dashboard from '../dashboard/Dashboard';
import AddFarms from '../edit/AddFarms';
import AddHarvests from '../edit/AddHarvests';
import {AddOrEditClaim} from '../edit/AddOrEditClaim';
import ChangeAdminGroup from '../edit/ChangeAdminGroup';
import EditFarms from '../edit/EditFarms';
import EditFields from '../edit/EditFields';
import MergeEntity from '../edit/MergeEntity';
import ImportData from '../import/ImportData';
import AddEditors from '../mutate/AssignEditors';
import PortfolioReport from '../report/PortfolioReport';
import MapRouter from '../routers/MapRouter';
import List from './List';
import ReportRouter from './ReportRouter';
import Sidebar from './Sidebar';

const {Content} = Layout;
const MAX_ALLOWED_PAGE_AGE = 7 * 24 * 60 * 60 * 1000;

function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function MainSwitch() {
  const context = useContext(ApisContext);
  let location = useLocation();
  const flags = useSelector(getEnabledFlags);

  useEffect(() => {
    const pathCmp = location.pathname.split('/');
    context.analytics.curScreen = pathCmp[0] || 'main';
    context.analytics.curScreenParams = {subscreen: pathCmp[1]};
    context.analytics.logEvent({event_name: 'pageview'});
  }, [context.analytics, location]);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={_ => <Dashboard />} />
        <Route path="/farm-report" component={ReportRouter} />
        <Route path="/portfolio-report" component={PortfolioReport} />
        <Route path="/list" component={List} />
        <Route path="/map" component={MapRouter} />
        <Route path="/import" component={ImportData} />
        <Route path="/change-admin-group" component={ChangeAdminGroup} />
        {!flags.has('hideEditingFunctionality') && <Route path="/add/farms" component={AddFarms} />}
        {!flags.has('hideEditingFunctionality') && <Route path="/add/fields" component={AddFarms} />}
        {!flags.has('hideEditingFunctionality') && <Route path="/edit/farms" component={EditFarms} />}
        {!flags.has('hideEditingFunctionality') && <Route path="/edit/fields" component={EditFields} />}
        {!flags.has('hideEditingFunctionality') && <Route path="/add/claim" component={AddOrEditClaim} />}
        {!flags.has('hideEditingFunctionality') && <Route path="/edit/claim" component={AddOrEditClaim} />}
        {!flags.has('hideEditingFunctionality') && <Route path="/add-harvests" component={AddHarvests} />}
        {!flags.has('hideEditingFunctionality') && (
          <Route path="/merge/:entity_type/:entity_ids" component={MergeEntity} />
        )}
        {!flags.has('hideEditingFunctionality') && (
          <Route path="/assign-editors/:entity_type/:entity_ids" component={AddEditors} />
        )}
        <Route path="/admin/bulk-import" component={BulkImportPage} />
        <Route path="/admin/review/farm" component={ReviewFarm} />
        <Route path="/admin" component={AdminPage} />
      </Switch>
    </>
  );
}

export const Main: React.FC<{history: History}> = ({history}) => {
  const apis = useContext(ApisContext);
  const [showAppVersionDeprecatedWarning, setShowAppVersionDeprecatedWarning] = useState<boolean>(false);
  const lastFullPageReload = useRef(apis.clock.now());

  useEffect(() => {
    const listener = (_: any) => {
      const timeSinceLastFullPageReload = apis.clock.now() - lastFullPageReload.current;
      if (timeSinceLastFullPageReload > MAX_ALLOWED_PAGE_AGE) {
        setShowAppVersionDeprecatedWarning(true);
      }
    };

    window.addEventListener('focus', listener);
    window.addEventListener('online', listener);
    const removeHistoryListener = history.listen(listener);

    return () => {
      window.removeEventListener('focus', listener);
      window.removeEventListener('online', listener);
      removeHistoryListener();
    };
  }, [history, apis.clock]);

  return (
    <Router history={history}>
      <Layout hasSider style={{minHeight: '100vh'}}>
        <Modal
          closable={false}
          okText={apis.t('Refresh')}
          open={showAppVersionDeprecatedWarning}
          title={apis.t('AppVersionDeprecated')}
          onCancel={() => setShowAppVersionDeprecatedWarning(false)}
          onOk={() => location.reload()}>
          <p>{apis.t('AppVersionDeprecatedMessage')}</p>
        </Modal>
        <Affix>
          <Sidebar />
        </Affix>
        <Layout>
          <Content style={{height: '100%'}}>
            <MainSwitch />
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default Main;
