import {ArrowsAltOutlined, CloseCircleFilled, LinkOutlined, ShrinkOutlined} from '@ant-design/icons';
import {useQuery} from '@tanstack/react-query';
import {Tooltip, message} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {Anomaly, FieldSeriesResponse, getEnabledAnomalies} from '../../../src/components/FieldSeriesPlot';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import {MapNavFocus} from '../../../src/map/map-focus';
import {Field, HarvestData, getFieldSeries} from '../../../src/models/interfaces';
import {formatMonthYear} from '../../../src/text/date';
import {fetchEntity} from '../../../src/util/fetchEntity';
import {useApis} from '../apis/ApisContext';
import EntityInfo from '../components/EntityInfo';
import FieldCharts from './FieldCharts';
import './MapOverlay.css';

type MapOverlayProps = {
  focus: null | MapNavFocus;
  expanded: boolean;
  onToggleSize: (expanded: boolean) => void;
  onClose: () => void;
  onTsDateSelected: (date: string) => void;
  canonical_date: string | null;
};

export function MapOverlay({
  focus,
  onClose,
  onToggleSize,
  onTsDateSelected,
  canonical_date,
  expanded,
}: MapOverlayProps) {
  const {authedFetcher, store, t} = useApis();
  const anomalies: {[P in Anomaly]: boolean} = useMemo(() => getEnabledAnomalies(store.getState(), 'web'), [store]);
  const [selectedTsDate, setSelectedTsDate] = React.useState(canonical_date ?? null);
  const onTsDateSelected_ = useCallback(
    (date: string) => {
      setSelectedTsDate(date);
      onTsDateSelected(date);
    },
    [onTsDateSelected],
  );

  const {data, isLoading} = useQuery(['timeseries', focus], async () => {
    if (focus?.type != 'field') {
      return null;
    }

    const [allSeries, field, harvestData]: [FieldSeriesResponse[], Field, HarvestData[]] = await Promise.all([
      getFieldSeries(authedFetcher, {_field_ids: [focus.id]}, true),
      fetchEntity(authedFetcher, 'field', focus.id),
      authedFetcher({method: 'GET', path: 'api/harvest_data', params: [['field_id', 'eq.' + focus.id]]}),
    ]);

    return {
      field,
      series: allSeries[0]?.series ?? null,
      harvestData: harvestData && Object.fromEntries(harvestData.map((x: HarvestData) => [x.harvest_id, x])),
    };
  });

  const toggleSize = useCallback(() => onToggleSize(!expanded), [onToggleSize, expanded]);
  if (!focus || focus.type == 'address') {
    return null;
  }
  let className = `card map-overlay map-overlay-${expanded ? 'expanded' : 'collapsed'}`;
  const expandable = !expanded && focus.type == 'field';
  if (expandable) {
    className += ' expandable-card';
  }

  return (
    <div id="map-overlay" className={className} onClick={expanded || !expandable ? undefined : toggleSize}>
      <span>
        <Title expanded={expanded} focus={focus} onClose={onClose} toggleSize={toggleSize} />
      </span>
      <EntityInfo harvestDataObj={data?.harvestData ?? null} focus={focus} expanded={expanded} />
      {data?.series ? (
        <FieldCharts
          t={t}
          selectedDate={selectedTsDate}
          fieldSeries={data.series}
          bandWidth={expanded ? 50 : 10}
          bandHeight={expanded ? 25 : 20}
          gapHeight={0}
          onDateSelected={onTsDateSelected_}
          expanded={expanded}
          showSelectedItem={expanded}
          showLabels={expanded}
          groupFn={expanded ? date => date.slice(0, 7) : () => 'all'}
          formatDate={expanded ? date => formatMonthYear(t, date) : undefined}
          anomalies={anomalies}
          field={data.field}
        />
      ) : isLoading ? (
        <div className="center-text">
          <div className="lds-ellipsis">
            {/*Following empty divs are required for the lds-ellipsis CSS class to do its magic*/}
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

interface TitleProps extends Pick<MapOverlayProps, 'focus' | 'expanded'> {
  toggleSize: () => void;
  onClose: () => void;
}

function Title({focus, expanded, toggleSize, onClose}: TitleProps) {
  const {t} = useApis();
  const copyCurrentLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    message.info(t('LinkCopiedToClipboard'));
  }, [t]);

  let ExpandIcon = null;
  if (focus?.type == 'field') {
    ExpandIcon = expanded ? ShrinkOutlined : ArrowsAltOutlined;
  }
  const shareButton = !!navigator?.clipboard?.writeText;
  const onCloseCb = useCallback(() => {
    onClose();
    return false;
  }, [onClose]);

  return (
    <div className="card-title">
      {t(
        focus?.type == 'sample'
          ? 'Sample'
          : focus?.type == 'field'
          ? 'Field'
          : focus?.type == 'farm'
          ? 'Farm'
          : focus?.type == 'lnglat'
          ? 'Location'
          : 'Entity',
      )}
      {shareButton && (
        <Tooltip title={t('CopyLink')}>
          <LinkOutlined style={{color: PALETTE_COLORS.secondary, marginLeft: 10}} onClick={copyCurrentLink} />
        </Tooltip>
      )}
      <span className="card-top-right-button">
        {ExpandIcon && (
          <a>
            <ExpandIcon style={{color: PALETTE_COLORS.secondary, marginRight: 10}} onClick={toggleSize} />
          </a>
        )}
        <a>
          <CloseCircleFilled style={{color: PALETTE_COLORS.secondary}} onClick={onCloseCb} />
        </a>
      </span>
    </div>
  );
}
