import {InfoCircleOutlined} from '@ant-design/icons';
import {notification} from 'antd';
import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {UnreachableCaseError} from 'ts-essentials';
import {MAPBOX_BACKGROUND_GREY, blendColors, getLegendItems} from '../../../src/constants/colors';
import diagonalStripes from '../../../src/images/diagonal-stripes.png';
import {LayerParams} from '../../../src/layers/layer-params';
import {MapLayers} from '../../../src/layers/map-layers';
import {getUnitSystem} from '../../../src/selectors/units';
import {useApis} from '../apis/ApisContext';

interface MapLegendProps {
  layer: MapLayers;
  layerParams: null | LayerParams;
}

export function MapLegend({layer, layerParams}: MapLegendProps) {
  const {t} = useApis();
  const units = useSelector(getUnitSystem);
  const infoText = useMemo(getInfoText, [layer, t]);
  const showInfoText = useCallback(() => {
    notification.open({message: t(layer), description: infoText, duration: 30});
  }, [infoText, layer, t]);

  let items = useMemo(() => getLegendItems(t, layer, layerParams, units), [layer, layerParams, t, units]);
  if (!items) {
    return null;
  }

  return <MapLegendView infoText={infoText} showInfoText={showInfoText} items={items} />;

  function getInfoText() {
    switch (layer) {
      case 'base':
      case 'satellite':
      case null:
        return null;
      case 'intrafield':
        return t('IntrafieldInfo');
      case 'interfield':
        return t('InterfieldInfo');
      case 'interyield':
        return t('InteryieldInfo');
      case 'vegetation':
        return t('VegetationInfo');
      case 'soil-moisture':
        return t('SoilMoistureInfo');
      case 'temperature':
        return t('TemperatureInfo');
      case 'surface-temperature':
        return t('SurfaceTemperatureInfo');
      case 'precipitation':
        return t('PrecipitationInfo');
      case 'wind':
        return t('WindInfo');
      case 'crop-mon':
        // TODO(savv): Consider adding info messages for crop monitoring layers here.
        return null;
      case 'hail':
        return t('HailInfo');
      case 'custom-a':
        return t('custom-a');
      default:
        throw new UnreachableCaseError(layer);
    }
  }
}

interface MapLegendViewProps {
  infoText: null | string;
  showInfoText?: () => void;
  items: [string, string, number][];
}

export function MapLegendView({infoText, showInfoText, items}: MapLegendViewProps) {
  const [selectedItem, setSelectedItem] = useState<null | number>(null);

  return (
    <span id="map-legend">
      {infoText && showInfoText && <InfoCircleOutlined id="map-legend-icon" onClick={showInfoText} />}
      <span id="map-legend-container">
        {items.map(([val, color, opacity], index) => (
          <LegendItem
            key={index}
            itemIdx={index}
            color={color}
            opacity={opacity}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        ))}
      </span>
      {selectedItem != null && items[selectedItem] && (
        <span id="map-legend-selected-item">
          {
            <LegendItem
              itemIdx={selectedItem}
              color={items[selectedItem][1]}
              opacity={items[selectedItem][2]}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          }
          <span id="map-legend-info">{items[selectedItem][0]}</span>
        </span>
      )}
    </span>
  );
}

interface LegendItemProps {
  itemIdx: number;
  color: string;
  opacity: number;
  selectedItem: null | number;
  setSelectedItem: Dispatch<SetStateAction<null | number>>;
}

function LegendItem({selectedItem, setSelectedItem, itemIdx, color, opacity}: LegendItemProps) {
  const onClick = useCallback(() => setSelectedItem(itemIdx), [itemIdx, setSelectedItem]);
  return color == 'diagonal-stripes' ? (
    <img
      key={itemIdx + color}
      onClick={onClick}
      className={`map-legend-item-style ${selectedItem == itemIdx ? 'map-legend-item-style-selected' : ''}`}
      src={diagonalStripes}
    />
  ) : (
    <span
      key={itemIdx + color}
      onClick={onClick}
      className={`map-legend-item-style ${selectedItem == itemIdx ? 'map-legend-item-style-selected' : ''}`}
      // Final background color is a mix between zone color and Mapbox grey, proportional to zone opacity.
      style={{backgroundColor: blendColors(color, MAPBOX_BACKGROUND_GREY, opacity)}}
    />
  );
}
