import {CheckCircleOutlined} from '@ant-design/icons';
import React, {useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {BadStatusResponse} from '../../../src/FetcherFunc';
import {ChangeAdminGroupRequest} from '../../../src/models/interfaces';
import {useApis} from '../apis/ApisContext';

export default function ChangeAdminGroup() {
  const {
    params: {user_group},
  } = useRouteMatch<{user_group: string}>();
  const {authedFetcher} = useApis();
  const [disabled, setDisabled] = useState(false);
  const [msg, setMsg] = useState('');

  return (
    <form
      onSubmit={async event => {
        event.preventDefault();
        setDisabled(true);
        const target = event.target as any;
        const user_group = (event.target as any).user_group.value;
        const req: ChangeAdminGroupRequest = {user_group};
        try {
          await authedFetcher({method: 'POST', path: 'api/rpc/change_admin_group', json_body: {user_group}});
          setMsg('Done!');
        } catch (e) {
          setMsg(e instanceof BadStatusResponse ? e.text : (e as Error)?.message ?? e);
        }
      }}>
      <label htmlFor="lname">User group: </label>
      <input disabled={disabled} type="text" id="user_group" />
      <input disabled={disabled} type="submit" value="Submit" />
      {msg && (
        <div>
          <CheckCircleOutlined /> {msg}
        </div>
      )}
    </form>
  );
}
