import {message} from 'antd';
import {History} from 'history';
import React from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {FormyErrors} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {Farm, Policy} from '../../../src/models/interfaces';
import {TableName} from '../../../src/models/serialization';
import {farmDesc, policyDesc} from '../../../src/text/desc';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {isArr, isStr} from '../../../src/validator-constraints';
import FormyEditorsSelector from '../Formy/FormyEditorsSelector';
import {FormySubmit} from '../Formy/FormySubmit';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {entitiesToCommonUserGroup} from '../redux/selectors';
import {reportErr} from '../util/err';
import './mutate.css';

function validateEditors(x: Editors): FormyErrors<Editors> {
  return {
    user_group: !isStr(x.user_group, 1),
    editors: !isArr(x.editors, 1),
  };
}

class Editors {
  readonly user_group: string;
  public editors: string[];

  constructor(user_group: string, editors: string[] = []) {
    if (!user_group) {
      throw new Error(`Cannot initialize AddEditors::Editors without a user_group!`);
    }
    this.user_group = user_group;
    this.editors = editors;
  }
}

async function init(apis: Apis, history: History, entityType: 'farm' | 'policy', entityIds: string[]) {
  const {t, authedFetcher} = apis;
  const entities: (Farm | Policy)[] = await fetchEntities(authedFetcher, entityType as 'farm', entityIds);
  const userGroup = entitiesToCommonUserGroup(entities);
  if (!userGroup || entityIds.length == 0) {
    throw new Error(`Couldn't initialize AddEditors: userGroup=${userGroup}; entity_ids=${entityIds.join(',')}`);
  }

  async function onSubmit({editors}: Editors) {
    try {
      await authedFetcher({
        method: 'POST',
        path: entityType == 'farm' ? 'api/rpc/assign_farms_to_users' : 'api/rpc/assign_policies_to_users',
        json_body: {
          [entityType == 'farm' ? 'farm_ids' : 'policy_ids']: entityIds,
          assigned_editors: editors,
        },
      });
      message.success(t('Done') + '!');
      history.goBack();
    } catch (e) {
      message.error(t('Error'));
      reportErr(e, 'assign-editors');
    }
  }

  const formy = new Formy('new', new Editors(userGroup), t, onSubmit, validateEditors);

  return {formy, entities};
}

export default function AssignEditors() {
  const apis = useApis(),
    history = useHistory();
  const {
    params: {entity_type, entity_ids},
  } = useRouteMatch<{entity_ids: string; entity_type: TableName}>();
  const key = entity_type + ':' + entity_ids;
  if (entity_type != 'farm' && entity_type != 'policy') {
    throw new Error('Not implemented: ' + entity_type);
  }

  const entityIds = entity_ids.split(',');
  const content = useAsyncMemo(() => init(apis, history, entity_type, entityIds), [key]);
  if (!content) {
    return <SpinningDots size={75} />;
  }

  const {formy, entities} = content;

  return (
    <div className="mutate-form" key={key}>
      <Link to={entity_type == 'farm' ? '/list/farms' : '/list/policies'} className="bottom-margin-1em">
        &lt; {apis.t('Back')}
      </Link>
      <span>{apis.t(entity_type == 'farm' ? 'Farms' : 'Policies')}:</span>
      <ol>
        {entities.map(e => (
          <li key={entity_type == 'farm' ? (e as Farm).farm_id : (e as Policy).policy_id}>
            {entity_type == 'farm' ? farmDesc(apis.t, e as Farm) : policyDesc(apis.t, e as Policy)}
            <br />
            {apis.t('UserGroup') + ': ' + e.user_group}
            <br />
            {apis.t('Editors') + ': ' + e.editors.map(x => (x[0] == '@' ? x.slice(1) : x)).join(', ')}
          </li>
        ))}
      </ol>
      <b>{apis.t('AddEditors')}:</b>
      <FormyEditorsSelector formy={formy} />
      <FormySubmit label="Save" formy={formy} />
    </div>
  );
}
