import {message} from 'antd';
import {PolicyData} from '../../../src/models/data';
import {insertPolicy, syncQueuedMutations} from '../../../src/redux/actions/db';
import {MutationQueueItem} from '../../../src/redux/reducers/db';
import {Apis} from '../apis/Apis';
import {reportErr} from '../util/err';
import {HarvestForm} from './EditFields';

export async function commitMutations(apis: Apis) {
  let errors: unknown[] = [];
  const onError = async (item: MutationQueueItem, err: unknown) => {
    console.error('Error commiting mutation for item', JSON.stringify(item), ' -- ', err);
    errors.push(err);
  };
  console.info(
    'Committing to DB:',
    apis.store
      .getState()
      .dbMeta.mutationQueue.map(x => `${x.mutationType} ${x.mutationType != 'insert-many' ? x.table : ''}`),
  );
  await apis.store.dispatch(syncQueuedMutations(onError, apis.authedFetcher, apis.clock));
  if (errors.length) {
    message.error(apis.t('Error'));
    reportErr(errors, 'add-harvests');
    return false;
  } else {
    message.success(apis.t('Done'));
    return true;
  }
}

export function insertMissingPolicy(apis: Apis, field_id: string, harvestForm: HarvestForm, user_group: string) {
  if (harvestForm.policy_id == null && harvestForm.policy_number) {
    const editor = '@' + apis.store.getState().dbMeta.curUser!.email;
    const data: PolicyData = {
      policy_number: harvestForm.policy_number,
      editors: [editor],
      user_group,
      comments: null,
      metadata: null,
      custom_columns: null,
    };
    harvestForm.policy_id = apis.store.dispatch(insertPolicy(apis, data));
  }
}
