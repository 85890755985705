import {Button, message} from 'antd';
import {BaseButtonProps} from 'antd/lib/button/button';
import React, {MouseEventHandler} from 'react';
import {reportFormy} from '../../../src/Formy';
import {FormySubmitBase} from '../../../src/Formy/FormySubmitBase';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import './Formy.css';

export class FormySubmit<F> extends FormySubmitBase<F, Partial<Omit<BaseButtonProps, 'children' | 'className'>>> {
  static contextType = ApisContext;
  context!: Apis;

  onClick: MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();
    try {
      if (!(await this.handleSubmit())) {
        message.warn(this.props.formy.t('pleaseCheckForm'));
        return false;
      }

      // noinspection ES6MissingAwait
      reportFormy(this.context.analytics, this.props.formy);
      return true;
    } catch (e) {
      console.info('Formy submit error:', e);
      reportErr(e, 'FormySubmit');
      message.error(this.props.formy.t('UnknownErrorOccurred'));
      return false;
    }
  };

  render() {
    const {label, formy, onSubmitOverride, ...possiblyButtonProps} = this.props;

    return this._mode.mode === 'view' ? null : (
      <Button
        {...possiblyButtonProps}
        className="formy-input formy-submit"
        onClick={this.onClick}
        disabled={this.state.isSubmitting}>
        {this.props.formy.t(label)}
      </Button>
    );
  }
}
