import {FormyI} from './Formy';
import {I18nSimpleKey} from './i18n/i18n';
import {HarvestCrop, Sample, WeightUnit} from './models/interfaces';
import {YieldValue} from './models/types';
import {Cereals, Sunflower} from './selectors/harvest';
import {
  UnitSystem,
  convertDensity,
  convertDistance,
  convertWeight,
  convertYield,
  roundValueUnit,
} from './selectors/units';

const Method = ['PlantDensity', 'RowsAndWidth', 'Harvester'] as const;
export type Method = (typeof Method)[number];

export type YieldComponents = Pick<
  Sample,
  | 'plant_density'
  | 'space_between_rows'
  | 'space_between_plants'
  | 'units_per_plant'
  | 'kernels_per_unit'
  | 'weight_per_unit'
  | 'vinification_ratio'
  | 'harvester_platform_width'
  | 'harvester_distance_collected'
  | 'harvester_gross_sample'
  | 'conversion_ratio'
>;

export const cornSilageDefaultConversionRatio = 1.59;

export function getPlantDensitySqM<F extends YieldComponents>(method: Method, formy: FormyI<F>): null | number {
  const {plant_density, space_between_rows, space_between_plants} = formy.getValues();
  const numPlants = method == 'PlantDensity' ? convertDensity('units-per-m2', plant_density)?.val : 1;
  const productionAreaSqm =
    method == 'PlantDensity'
      ? 1
      : method == 'RowsAndWidth'
      ? (convertDistance('meters', space_between_rows)?.val ?? 0) *
        (convertDistance('meters', space_between_plants)?.val ?? 0)
      : 0;
  if (productionAreaSqm == 0) {
    return null;
  }
  return numPlants == null ? null : numPlants / productionAreaSqm;
}

export function getEstimatedYield<F extends YieldComponents>(
  units: UnitSystem,
  method: Method,
  cropFamily: null | HarvestCrop,
  formy: FormyI<F>,
): null | YieldValue {
  if (method == 'Harvester') {
    return calcHarvester(units, cropFamily, formy);
  }

  const {units_per_plant, kernels_per_unit, weight_per_unit, vinification_ratio, conversion_ratio} = formy.getValues();

  const density_m2 = getPlantDensitySqM(method, formy);
  if (!density_m2) {
    return null;
  }

  const config = getYieldCalcConfig(cropFamily);
  if (!config) {
    return null;
  }

  let yieldTHa = density_m2 * 10000;

  if (config.unitsPerPlantMsg) {
    if (units_per_plant != null) {
      yieldTHa *= units_per_plant;
    } else {
      return null;
    }
  }

  if (config.kernelsPerUnitMsg) {
    if (kernels_per_unit != null) {
      yieldTHa *= kernels_per_unit;
    } else {
      return null;
    }
  }

  if (config.weightPerUnitMsg) {
    const weight_per_unit_kg = convertWeight('kilograms', weight_per_unit)?.val;
    if (weight_per_unit_kg != null) {
      yieldTHa *= weight_per_unit_kg / 1000;
    } else {
      return null;
    }
  }

  if (cropFamily == 'grapes') {
    if (vinification_ratio != null) {
      return roundValueUnit({unit: 'hectoliters-per-hectare', val: (yieldTHa * 1000) / vinification_ratio});
    } else {
      return null;
    }
  }

  if (cropFamily == 'corn-silage') {
    if (conversion_ratio != null) {
      return roundValueUnit({unit: 'tons-per-hectare', val: yieldTHa * conversion_ratio});
    } else {
      return null;
    }
  }

  return composeYield(units, yieldTHa, cropFamily);
}

function calcHarvester<F extends YieldComponents>(
  units: UnitSystem,
  cropFamily: null | HarvestCrop,
  formy: FormyI<F>,
): null | YieldValue {
  const {harvester_platform_width, harvester_distance_collected, harvester_gross_sample} = formy.getValues();
  const widthM = convertDistance('meters', harvester_platform_width)?.val;
  const distanceM = convertDistance('meters', harvester_distance_collected)?.val;
  const areaSqm = widthM == null || distanceM == null ? null : widthM * distanceM;
  const productionKg = convertWeight('kilograms', harvester_gross_sample)?.val;
  const estimateTHa = areaSqm == null || productionKg == null ? null : productionKg / 1000 / (areaSqm / 10000);
  return composeYield(units, estimateTHa, cropFamily);
}

function composeYield(units: UnitSystem, estimateTHa: null | number, cropFamily: null | HarvestCrop) {
  if (estimateTHa == null) {
    return null;
  }

  return roundValueUnit(convertYield(units.yieldUnit, {val: estimateTHa, unit: 'tons-per-hectare'}, cropFamily));
}

export function getYieldCalcConfig(cropFamily: null | HarvestCrop): null | {
  unitsPerPlantMsg: null | I18nSimpleKey;
  kernelsPerUnitMsg: null | I18nSimpleKey;
  weightPerUnitMsg: null | I18nSimpleKey;
  weightPerUnitUnit: WeightUnit[];
  method: Method;
} {
  if (cropFamily == 'grapes') {
    return {
      unitsPerPlantMsg: 'BunchesPerPlant',
      kernelsPerUnitMsg: null,
      weightPerUnitMsg: 'BunchWeight',
      weightPerUnitUnit: ['grams'],
      method: 'RowsAndWidth',
    };
  } else if (cropFamily == 'soybeans') {
    return {
      unitsPerPlantMsg: 'AvgPodsPerShaft',
      kernelsPerUnitMsg: 'AvgGrainsPerPod',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (cropFamily == 'corn-silage') {
    return {
      unitsPerPlantMsg: 'AvgEarsPerPlant',
      kernelsPerUnitMsg: 'AvgGrainsPerEar',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'RowsAndWidth',
    };
  } else if (cropFamily == 'corn-grain' || cropFamily == 'corn-seeds') {
    // Note that this needs to come before cereals, as it's a special case.
    return {
      unitsPerPlantMsg: 'AvgEarsPerPlant',
      kernelsPerUnitMsg: 'AvgGrainsPerEar',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'RowsAndWidth',
    };
  } else if (Cereals.includes(cropFamily!)) {
    return {
      unitsPerPlantMsg: 'AvgEarsPerPlant',
      kernelsPerUnitMsg: 'AvgGrainsPerEar',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (cropFamily == 'linseed') {
    return {
      unitsPerPlantMsg: 'AvgBollsPerPlant',
      kernelsPerUnitMsg: 'AvgKernelsPerBoll',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (Sunflower.includes(cropFamily!)) {
    return {
      unitsPerPlantMsg: null,
      kernelsPerUnitMsg: null,
      weightPerUnitMsg: 'SeedWeightPerHead',
      weightPerUnitUnit: ['grams'],
      method: 'RowsAndWidth',
    };
  } else if (cropFamily == 'rapeseed') {
    return {
      unitsPerPlantMsg: 'AvgSiliquesPerShaft',
      kernelsPerUnitMsg: 'AvgGrainsPerSilique',
      weightPerUnitMsg: 'ThousandKernelWeightGrams',
      weightPerUnitUnit: ['thousand-kernel-weight-grams'],
      method: 'PlantDensity',
    };
  } else if (cropFamily == 'sugar-beet' || cropFamily == 'potatoes') {
    return {
      unitsPerPlantMsg: null,
      kernelsPerUnitMsg: null,
      weightPerUnitMsg: 'WeightPerPlant',
      weightPerUnitUnit: ['grams'],
      method: 'RowsAndWidth',
    };
  }

  return null;
}
