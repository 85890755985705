import {unsampledFieldsAreUndamagedEnabled} from '../feature-flags';
import {excludeSamplesWithEmptyLossInAggregationEnabled} from '../feature-flags';
import {HarvestCustomColumns} from '../models/CustomColumns';
import {
  CostType,
  Farm,
  Field,
  GetFarmHarvestRowsRow,
  Harvest,
  HarvestCrop,
  HarvestData,
  LossCause,
  Policy,
  Sample,
} from '../models/interfaces';
import {
  AreaUnit,
  AreaValue,
  DensityUnit,
  DensityValue,
  UnitPriceValue,
  ValueUnit,
  YieldUnit,
  YieldValue,
} from '../models/types';
import {IndexedCrops} from '../redux/reducers/crops';
import {DbState} from '../redux/reducers/db';
import {HarvestDesc} from '../report/report-types';
import {aggregate, filterNulls, remove} from '../util/arr-util';
import cmp from '../util/cmp';
import {isObjEmpty} from '../util/obj-util';
import {getBaseCrop} from './crops';
import {getSamplesByHarvestId} from './getSamplesByHarvestId';
import {UnitSystem, convertArea, convertDensity, convertYield, getIdealYieldUnit} from './units';
import {YieldStats, calculateYieldStats, getSampleLosses} from './yield';

export function getMinMaxSampleDate(samples: Sample[]): null | [string, string] {
  let minDate = null,
    maxDate = null;
  for (const sample of samples) {
    if (minDate == null || (sample.sample_date && sample.sample_date < minDate)) {
      minDate = sample.sample_date;
    }
    if (maxDate == null || (sample.sample_date && sample.sample_date > maxDate)) {
      maxDate = sample.sample_date;
    }
  }

  if (!minDate || !maxDate) {
    return null;
  } else {
    return [minDate, maxDate];
  }
}

export function getSum<U extends ValueUnit>(
  values: U[],
  fallbackUnit: U['unit'],
  harvest_crop: null | HarvestCrop,
): null | U {
  if (values.length == 0) {
    return null;
  }

  // Collect only units with non-zero value.val as zeroes won't change the sum, but may unnecessarily cause the usage of fallbackUnit.
  const units = new Set<U['unit']>(values.filter(value => value.val).map(x => x.unit));
  const sum = {
    val: 0,
    unit: (units.size == 1 ? Array.from(units)[0] : fallbackUnit) as U['unit'],
  } as U;

  const mode = YieldUnit.includes(sum.unit as YieldUnit)
    ? 'yield'
    : AreaUnit.includes(sum.unit as AreaUnit)
    ? 'area'
    : DensityUnit.includes(sum.unit as DensityUnit)
    ? 'density'
    : 'percent';
  for (const value of values) {
    const conv =
      mode == 'yield'
        ? convertYield(sum.unit as YieldUnit, value as YieldValue, harvest_crop)
        : mode == 'area'
        ? convertArea(sum.unit as AreaUnit, value as AreaValue)
        : mode == 'density'
        ? convertDensity(sum.unit as DensityUnit, value as DensityValue)
        : mode == 'percent'
        ? value
        : null;
    if (!conv) {
      return null;
    }
    sum.val += conv.val;
  }

  return sum;
}

export function getAvg<U extends ValueUnit>(
  values: (null | U)[],
  fallbackUnit: U['unit'],
  harvest_crop: null | HarvestCrop,
  weights?: number[],
): null | U {
  if (weights && weights.length != values.length) {
    console.error('getAvg called with weights of different length:', values.length, weights.length);
    return null;
  }

  const summableValues: U[] = [];
  let totalWeight = 0;
  for (let i = 0; i < values.length; ++i) {
    const value = values[i];
    if (value) {
      const weight = weights ? weights[i] : 1;
      totalWeight += weight;
      summableValues.push({...value, val: value.val * weight});
    }
  }

  if (totalWeight == 0) {
    return null;
  }

  const sum = getSum(summableValues, fallbackUnit, harvest_crop);
  return sum ? ({unit: sum.unit, val: sum.val / totalWeight} as U) : null;
}

// Returns the area-weighted average yield of the given fields' samples. Assumes that all samples' harvests are
// consistent and are of the same crop type. Fields without samples do not affect the weighted average.
type YieldColumns = Pick<Sample, 'estimated_yield' | 'feasible_yield' | 'losses'>;

export interface HarvestSamples {
  field: Pick<Field, 'field_area'>;
  harvest: Pick<Harvest, 'crop_id'>;
  samples: YieldColumns[];
}

export interface AggregatedHarvest {
  stats: YieldStats;
  data: (YieldStats & {sample_id: string | null})[];
  harvest: Harvest;
  field: Field | null;
  farm: Farm;
  policy: null | Policy;
  samples: Sample[];
}

export type CostsData = {[P in CostType]?: {[field_id: string]: number | null}};

export interface HarvestAnalytics {
  // Stats for this group of harvests. A grouping may be a farm-crop-season-year tuple, or a crop-season-year triplet.
  stats: YieldStats;

  // The harvests and their stats.
  harvests: AggregatedHarvest[];

  // Costs (e.g. resowing)
  costs: CostsData;

  // Farm-harvest level sample/observations
  overrideSample: Sample | null;

  numFields: number;
  numSamples: number;
}

export function harvestToAggregates(
  defaultYieldUnit: YieldUnit,
  harvest_crop: null | HarvestCrop,
  farm: Farm,
  policy: null | Policy,
  field: null | Field,
  harvest: Harvest,
  samples: Sample[],
): AggregatedHarvest {
  const excludeSamplesWithEmptyLossInAggregation = excludeSamplesWithEmptyLossInAggregationEnabled(farm.user_group);
  // Treat detached samples as belonging to a "virtual" field of area equal to harvest area or sum of all affected areas
  if (!field) {
    const nonOverwriteSamples = samples.filter(x => !!x.sample_area || !!x.sample_location);
    const areaHa = getTotalAreaInHectares(harvest, nonOverwriteSamples);
    const {data, ...stats} = calculateYieldStats(
      defaultYieldUnit,
      areaHa != null ? {unit: 'hectares', val: areaHa} : null,
      harvest_crop,
      harvest.insured_yield,
      harvest.insured_area,
      nonOverwriteSamples,
      excludeSamplesWithEmptyLossInAggregation,
    );
    return {
      stats,
      data,
      harvest,
      farm,
      policy,
      samples: nonOverwriteSamples,
      field: null,
    };
  } else {
    const {data, ...stats} = calculateYieldStats(
      defaultYieldUnit,
      // TODO(seb): Prioritize harvest_area over field_area
      // The best area metric is generally the harvest_area, followed by the field_area. As we are on the field-level
      // here, we cannot fall back on the insured_area which will be only defined on the farm-level.
      field.field_area,
      harvest_crop,
      // TODO(seb): Do not use the field-level insured_{yield,area} if there is a farm-level harvest.
      harvest.insured_yield,
      harvest.insured_area,
      samples,
      excludeSamplesWithEmptyLossInAggregation,
    );
    return {
      stats,
      data,
      harvest,
      field,
      farm,
      policy,
      samples,
    };
  }
}

export function getHarvestAnalytics<T>(
  units: UnitSystem,
  dbState: Pick<DbState, 'farm' | 'field' | 'harvest' | 'sample' | 'policy'>,
  crops: IndexedCrops,
  fn: (h: Harvest, dbState: Pick<DbState, 'farm' | 'field' | 'harvest' | 'sample' | 'policy'>) => T,
): (T & HarvestAnalytics)[] {
  const {field: fieldObj, harvest: harvestObj} = dbState;
  const allHarvests: {[key: string]: AggregatedHarvest[]} = {};
  const harvestIdToKey: {[harvest_id: string]: string} = {};
  const harvestIdToSamples = getSamplesByHarvestId(dbState.sample);

  // Go over each Harvest, aggregate its stats, and group the Harvest under its grouping key.
  for (const harvest_id in harvestObj) {
    const harvest = harvestObj[harvest_id];
    const key = fn(harvest, dbState);
    if (key == null) {
      continue;
    }
    const keyStr = JSON.stringify(key);
    harvestIdToKey[harvest_id] = keyStr;

    if (!allHarvests[keyStr]) {
      allHarvests[keyStr] = [];
    }

    const cropFamily = getBaseCrop(crops, harvest.crop_id);
    allHarvests[keyStr].push(
      harvestToAggregates(
        getIdealYieldUnit(units, cropFamily),
        cropFamily,
        // TODO(savv): Simplify this statement after harvest.farm_id is not null (2024).
        dbState.farm[harvest.field_id ? dbState.field[harvest.field_id].farm_id : harvest.farm_id!],
        harvest.policy_id == null ? null : dbState.policy[harvest.policy_id],
        harvest.field_id ? (fieldObj[harvest.field_id] as Field) : null,
        harvest,
        harvestIdToSamples[harvest.harvest_id] ?? [],
      ),
    );
  }

  const result: (T & HarvestAnalytics)[] = [];

  for (const k in allHarvests) {
    const key: T = JSON.parse(k);
    const harvests = allHarvests[k];
    const harvest_crop = getBaseCrop(crops, harvests[0]?.harvest?.crop_id);
    const farmOverrideSample = getFarmOverrideSample(
      harvests.map(aggregatedHarvest => aggregatedHarvest.harvest),
      harvestIdToSamples,
    );
    const res = {
      ...key,
      ...reaggregateHarvestAnalytics(units, harvest_crop, harvests, farmOverrideSample),
      costs: getCosts(harvests),
    };
    result.push(res);
  }

  return result;
}

// IMPORTANT: the logic here needs to match the logic in get_farm_harvest_rows, for getting the farm_override_sample
export function getFarmOverrideSample(
  harvests: Harvest[],
  harvestIdToSamples: {
    [harvest_id: string]: Sample[] | undefined;
  },
) {
  let farmOverrideSample = null;
  for (const harvest of harvests) {
    const samples = harvestIdToSamples[harvest.harvest_id];
    const farmLevelSamples = samples?.filter(sample => isOverrideSample(sample, harvest)) ?? [];
    if (farmLevelSamples.length == 0) {
      continue;
    }
    farmLevelSamples.sort((a, b) => cmp(b.sample_date || '', a.sample_date || ''));
    farmOverrideSample = farmLevelSamples[0];
  }
  return farmOverrideSample;
}

export function isOverrideSample(sample: Sample, harvest: Harvest) {
  return !harvest.field_id && !sample.sample_area && !sample.sample_location;
}

function reaggregateHarvestAnalytics(
  units: UnitSystem,
  harvest_crop: null | HarvestCrop,
  harvests: AggregatedHarvest[],
  farmOverrideSample: null | Sample,
): HarvestAnalytics {
  const harvestStats = harvests.map(x => ({
    ...x.stats,
    isFarmHarvest: !x.field?.field_id && !x.harvest.external_harvest_id,
  }));
  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(harvests[0]?.farm?.user_group);
  return {
    overrideSample: farmOverrideSample,
    harvests,
    numFields: harvests.filter(x => !!x.field).length,
    // TODO(seb): We should only include samples which are used, so need to filter out override samples AND samples
    //  which are too old.
    numSamples: (harvests.map(x => x.samples.length).reduce(...aggregate.sum) ?? 0) + (farmOverrideSample ? 1 : 0),
    costs: {},
    stats: aggregateFarmYieldStats(units, harvest_crop, harvestStats, farmOverrideSample, unsampledFieldsAreUndamaged),
  };
}

export function getCosts(harvests: AggregatedHarvest[]) {
  let costsData = {} as CostsData;

  for (const h of harvests) {
    const harvestCosts = {} as Record<CostType, (number | null)[]>;

    for (const sample of h.samples) {
      for (const cost of sample.costs) {
        if (!!cost.cost_type) {
          if (!(cost.cost_type in harvestCosts)) {
            harvestCosts[cost.cost_type] = [];
          }
          harvestCosts[cost.cost_type].push(cost.cost);
        }
      }
    }

    for (const costType in harvestCosts) {
      if (!(costType in costsData)) {
        costsData[costType as CostType] = {};
      }
      costsData[costType as CostType]![h.field ? h.field.field_id : '-'] = harvestCosts[costType as CostType].reduce(
        ...aggregate.sum,
      );
    }
  }

  return costsData;
}

export function getSharedValues(
  units: UnitSystem,
  farmHarvest: HarvestAnalytics & HarvestDesc,
): {
  insured_price: null | UnitPriceValue;
  insured_area: null | AreaValue;
  deductibleTexts: string[];
  historicalYields: [null | number, null | number, null | number, null | number, null | number];
  meanHistoricalYield: null | number;
  updatedYields: [null | number, null | number, null | number, null | number, null | number];
} {
  const inputHarvest = getInputHarvest(farmHarvest);
  const inputCustomColumns = inputHarvest && new HarvestCustomColumns(inputHarvest.custom_columns);

  const insured_price = inputHarvest?.insured_price ?? null;
  const insuredAreaHa = farmHarvest.stats.insuredAreaHa ?? farmHarvest.stats.areaHa;
  const insured_area =
    insuredAreaHa == null ? null : convertArea(units.areaUnit, {unit: 'hectares', val: insuredAreaHa});

  // Groupama provides the historical yields AND the mean historical yield upon mission import.
  // updatedYields only exist in the context of Groupama, allowing them to overwrite the imported historical yields.
  if (
    inputCustomColumns?.grpmPastYields ||
    inputCustomColumns?.grpmUpdatedYields ||
    inputCustomColumns?.grpmAvgYield ||
    inputCustomColumns?.grpmFranchises
  ) {
    const historicalYields = inputCustomColumns.grpmPastYields ?? [null, null, null, null, null];
    const updatedYields = inputCustomColumns.grpmUpdatedYields ?? [null, null, null, null, null];
    const meanHistoricalYield = inputCustomColumns.grpmAvgYield ?? null;
    const deductibleTexts = inputCustomColumns.grpmFranchises ?? [];
    return {
      insured_price,
      insured_area,
      deductibleTexts,
      historicalYields,
      meanHistoricalYield,
      updatedYields,
    };
  }

  if (inputCustomColumns?.etoileFranchises) {
    const deductibleTexts = inputCustomColumns.etoileFranchises ?? [];
    return {
      insured_price,
      insured_area,
      deductibleTexts,
      // Below values are not defined for this customer.
      historicalYields: [null, null, null, null, null],
      meanHistoricalYield: null,
      updatedYields: [null, null, null, null, null],
    };
  }

  return {
    insured_price,
    insured_area,
    // Below values are only defined for some customers.
    deductibleTexts: [],
    historicalYields: [null, null, null, null, null],
    meanHistoricalYield: null, // Would be the average of historicalYields.
    updatedYields: [null, null, null, null, null],
  };
}

export function aggregateFarmYieldStats(
  units: UnitSystem,
  harvest_crop: null | HarvestCrop,
  harvestStats: (YieldStats & {isFarmHarvest: boolean})[],
  farmOverrideSample: null | Sample,
  unsampledFieldsAreUndamaged: boolean,
): YieldStats {
  const fieldHarvestStats = harvestStats.filter(x => !x.isFarmHarvest);
  const fieldAreas = fieldHarvestStats.map(x => x.areaHa);
  // use simple average if no harvest has an area
  const fieldAreaWeights = filterNulls(fieldAreas).length == 0 ? undefined : fieldAreas.map(x => x ?? 0);
  const yieldUnit = getIdealYieldUnit(units, harvest_crop);

  let insured = getAvg(
    fieldHarvestStats.map(x => (x.insured == null ? null : {unit: x.unit, val: x.insured})),
    yieldUnit,
    harvest_crop,
    fieldAreaWeights,
  );
  const farmHarvestWithInsured = harvestStats.find(x => x.isFarmHarvest && x.insured);
  const farmHarvestInsuredYield =
    // Do not use the insured yield from the farm harvest, if we consider unsampled fields to be undamaged.
    // TODO(savv): Implement a similar logic to ignore the insured yield from field level harvests.
    farmHarvestWithInsured && unsampledFieldsAreUndamaged
      ? {unit: farmHarvestWithInsured.unit, val: farmHarvestWithInsured.insured!}
      : null;
  if (!insured && farmHarvestInsuredYield) {
    insured = farmHarvestInsuredYield;
  }
  let estimated = getAvg(
    // If x.estimated is null, it means that this harvest did not have any feasible OR insured yield; so we fall back
    // at the farm level insured yield. This is line with calculateYieldStats.
    // TODO(savv): it may be better to pass the farm harvest to calculateYieldStats.
    fieldHarvestStats.map(x => (x.estimated == null ? farmHarvestInsuredYield : {unit: x.unit, val: x.estimated})),
    yieldUnit,
    harvest_crop,
    fieldAreaWeights,
  );
  let feasible = getAvg(
    // If x.feasible is null, it means that this harvest did not have any feasible OR insured yield; so we fall back
    // at the farm level insured yield.
    // TODO(savv): should we first consider falling back to the farm harvest feasible yield?
    fieldHarvestStats.map(x => (x.feasible == null ? farmHarvestInsuredYield : {unit: x.unit, val: x.feasible})),
    yieldUnit,
    harvest_crop,
    fieldAreaWeights,
  );
  if (!feasible) {
    feasible = insured;
  }
  let totalLoss = getAvg(
    fieldHarvestStats.map(x => (x.totalLoss == null ? null : {unit: x.unit, val: x.totalLoss})),
    yieldUnit,
    harvest_crop,
    fieldAreaWeights,
  );

  const yieldUnits: Set<YieldUnit> = new Set(
    fieldHarvestStats
      .filter(x => x.estimated != null || x.feasible != null || (x.lossByCause && !isObjEmpty(x.lossByCause)))
      .map(x => x.unit),
  );
  const unit = yieldUnits.size == 1 ? Array.from(yieldUnits)[0] : yieldUnit;

  if (farmOverrideSample && farmOverrideSample.estimated_yield != null) {
    estimated = farmOverrideSample.estimated_yield;
  }
  if (farmOverrideSample && farmOverrideSample.feasible_yield) {
    feasible = farmOverrideSample.feasible_yield;
  }

  let insuredAreaHa: number | null = null;
  for (const harvest of harvestStats) {
    if (harvest.insuredAreaHa == null) {
      continue;
    }
    if (harvest.isFarmHarvest) {
      insuredAreaHa = harvest.insuredAreaHa;
      break;
    } else {
      insuredAreaHa = (insuredAreaHa ?? 0) + harvest.insuredAreaHa;
    }
  }

  let areaHa: number | null = fieldHarvestStats.map(h => h.areaHa).reduce(...aggregate.sum) ?? 0;
  if (!areaHa) {
    areaHa = harvestStats
      .filter(x => x.isFarmHarvest)
      .map(h => h.areaHa)
      .reduce(...aggregate.sum);
  }
  const sampledAreaHa = harvestStats
    // We cannot filter out farm harvests here, as they may have detached/subplot samples attached.
    .map(h => h.sampledAreaHa)
    .reduce(...aggregate.sum);

  const farmStats: YieldStats = {
    unit,
    areaHa,
    insuredAreaHa,
    sampledAreaHa,
    insured: convertYield(unit, insured, harvest_crop)?.val ?? null,
    estimated: convertYield(unit, estimated, harvest_crop)?.val ?? null,
    feasible: convertYield(unit, feasible, harvest_crop)?.val ?? null,
    totalLoss: convertYield(unit, totalLoss, harvest_crop)?.val ?? null,
    lossByCause: {},
  };

  const lossCauses: Set<LossCause> = new Set(
    fieldHarvestStats.map(x => Object.keys(x.lossByCause ?? {}) as LossCause[]).flat(1),
  );
  if (lossCauses.size > 0) {
    for (const loss_cause of lossCauses) {
      const lossesForCause: YieldValue[] = fieldHarvestStats.map(x => ({
        unit: x.unit,
        val: x.lossByCause?.[loss_cause] ?? 0,
      }));
      let avgLoss = convertYield(unit, getAvg(lossesForCause, yieldUnit, null, fieldAreaWeights), harvest_crop);
      const lossByCause = farmStats.lossByCause;
      if (avgLoss && lossByCause) {
        lossByCause[loss_cause] = avgLoss.val;
      }
    }
  }
  if (farmOverrideSample && farmOverrideSample.losses && farmOverrideSample.losses.length > 0) {
    const losses = getSampleLosses(farmOverrideSample.losses, unit, harvest_crop, farmStats.feasible);
    // Override sample can have losses with empty loss value (e.g. by toggling recognized), which will return an empty losses object.
    // We don't want to override lossByCause and totalLoss in that case.
    // Question occurs on why would we keep the losses of the overwritten samples, when the overwrite sample does not contain any losses.
    // Override sample logic is due for revamp and we will address the question there https://app.asana.com/0/1204441850448359/1206947953712356
    if (losses && Object.keys(losses).length) {
      farmStats.lossByCause = losses;
      farmStats.totalLoss = Object.values(losses).reduce(...aggregate.sum) ?? 0;
    }
  }

  return farmStats;
}

export function aggregateFarmHarvestRows(units: UnitSystem, crops: IndexedCrops, farmHarvests: GetFarmHarvestRowsRow) {
  const farmStats: (YieldStats & {
    isFarmHarvest: boolean;
  })[] = filterNulls(farmHarvests.harvest_data ?? []).map(h => ({
    unit:
      h.loss_by_cause?.length && h.loss_by_cause.every(x => x.loss?.unit == 'percent') ? 'percent' : 'tons-per-hectare',
    areaHa: h.yield_area_ha,
    insuredAreaHa: h.insured_area_ha,
    insured: h.insured_yield_t_ha,
    feasible: h.feasible_yield_t_ha,
    estimated: h.harvest_yield_t_ha,
    totalLoss: h.total_loss_yield_t_ha,
    lossByCause: Object.fromEntries(h.loss_by_cause?.map(x => [x.loss_cause, x.loss?.val]) ?? []),
    sampledAreaHa: h.sampled_area_ha,
    isFarmHarvest: !h.field_id,
  }));

  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(farmHarvests?.farm?.user_group ?? null);
  return aggregateFarmYieldStats(
    units,
    getBaseCrop(crops, farmHarvests.crop_id),
    farmStats,
    farmHarvests.farm_override_sample,
    unsampledFieldsAreUndamaged,
  );
}

// TODO(seb): Better define harvestData aggregations for policies.
export function aggregatePolicyHarvestData(
  units: UnitSystem,
  crops: IndexedCrops,
  harvests: HarvestData[],
  cropId: string,
): YieldStats {
  const farmStats: (YieldStats & {isFarmHarvest: boolean})[] = harvests.map(h => ({
    unit:
      h.loss_by_cause?.length && h.loss_by_cause.every(x => x?.loss?.unit == 'percent')
        ? 'percent'
        : 'tons-per-hectare',
    areaHa: h.yield_area_ha,
    insuredAreaHa: h.insured_area_ha,
    insured: h.insured_yield_t_ha,
    feasible: h.feasible_yield_t_ha,
    estimated: h.harvest_yield_t_ha,
    totalLoss: h.total_loss_yield_t_ha,
    lossByCause: Object.fromEntries(h.loss_by_cause?.map(x => [x?.loss_cause, x?.loss?.val]) ?? []),
    sampledAreaHa: h.sampled_area_ha,
    isFarmHarvest: !h.field_id,
  }));

  const unsampledFieldsAreUndamaged = unsampledFieldsAreUndamagedEnabled(harvests[0]?.user_group ?? null);
  return aggregateFarmYieldStats(units, getBaseCrop(crops, cropId), farmStats, null, unsampledFieldsAreUndamaged);
}

// Return the total affected area for a given farm level harvest, and it's associated samples.
// Will return null for field-level harvests.
// Preferences for the source of the total affected area: harvest_area > sum(sample_area) > insured_area > null
function getTotalAreaInHectares(harvest: Harvest, samples: Sample[]) {
  if (harvest.field_id) {
    return null; // Total area is not defined on field-level harvests.
  }
  const harvestArea = convertArea('hectares', harvest.harvest_area);
  if (harvestArea) {
    return harvestArea.val;
  }
  const sampleAreas = samples
    .map(x => convertArea('hectares', x.sample_area))
    .filter(remove.nulls)
    .map(x => x.val);
  if (sampleAreas.length > 0) {
    return sampleAreas.reduce(...aggregate.sum) ?? 0;
  }
  const insuredArea = convertArea('hectares', harvest.insured_area);
  if (insuredArea) {
    return insuredArea.val;
  }
  return null;
}

export function getInputHarvest(farmHarvest: HarvestAnalytics & HarvestDesc): Harvest | null {
  const inputHarvests = farmHarvest.harvests.map(x => x.harvest).filter(x => x.farm_id && x.insured_yield);
  return inputHarvests.find(isImportedHarvest) ?? inputHarvests[0] ?? null;
}

export function isImportedHarvest(harvest: null | Harvest): boolean {
  return (
    !!harvest?.metadata?.ptf_imported_at ||
    !!harvest?.metadata?.ptf_updated_at ||
    !!new HarvestCustomColumns(harvest?.custom_columns).grpmCulture ||
    !!harvest?.metadata?.Culture
  );
}
