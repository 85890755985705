import type {I18nFunction, I18nSimpleKey, Locale} from './i18n';
import t_de from './t-de';
import t_en from './t-en';
import t_en_US from './t-en-US';
import t_es from './t-es';
import t_fr from './t-fr';
import t_it from './t-it';
import t_pl from './t-pl';
import t_pt from './t-pt';
import t_ro from './t-ro';

export function intl_num(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  if (Math.abs(v) < 0.001) {
    // avoids displaying 0.00 for very small numbers
    return v.toFixed(1);
  } else if (Math.abs(v) < 1) {
    return v.toFixed(2);
  } else {
    return v.toFixed(1);
  }
}

export function intl_num_2(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  return v.toFixed(2);
}

export function intl_num_4(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  return v.toFixed(4);
}

export function intl_int(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  return String(Math.round(v));
}

const Msgs: {[P in Locale]: I18nFunction} = {
  de: t_de,
  en: t_en,
  'en-US': t_en_US,
  es: t_es,
  fr: t_fr,
  it: t_it,
  pl: t_pl,
  pt: t_pt,
  ro: t_ro,
};

// Take any locale and return the closest matching locale for which we have a translation file at hand.
export function resolveLocale(locale: string | null): Locale {
  if (isAvailableLocale(locale)) {
    return locale;
  }

  const baseLanguage = getBaseLanguage(locale);
  if (isAvailableLocale(baseLanguage)) {
    return baseLanguage;
  }
  return 'en';
}

// TODO(from 2022-11-25): Handle demo accounts, where for e.g. demo-usa the locale should be en-US
//  independent of the users device settings. Further down the road we might also want to allow
//  users to overwrite their device locale manually.
//  See: https://github.com/greentriangle/agro/pull/1937#discussion_r1031573971
export function initI18n(locale: null | string): I18nFunction {
  return Msgs[resolveLocale(locale)];
}

export function isAvailableLocale(locale: string | null): locale is Locale {
  return !!Msgs[locale as Locale];
}

export function getBaseLanguage(locale: string | null): string {
  return !locale ? 'en' : locale.split('-')[0];
}

export function translateValue(t: I18nFunction, value: string | null | undefined, prefix?: string) {
  return value ? t((prefix + value) as I18nSimpleKey) : null;
}
