import {createColumnHelper} from '@tanstack/react-table';
import {Button, Checkbox, Tooltip} from 'antd';
import React, {useCallback, useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import 'react-table/react-table.css';
import {getEnabledFlags} from '../../../src/feature-flags';
import {
  GetPolicyRowsRequest,
  GetPolicyRowsRow,
  HarvestCrop,
  HarvestData,
  HarvestYear,
  getPolicyRows,
} from '../../../src/models/interfaces';
import {aggregatePolicyHarvestData} from '../../../src/selectors/aggregators';
import {getCrops} from '../../../src/selectors/crops';
import {getUnitSystem} from '../../../src/selectors/units';
import {filtersToRequest} from '../../../src/util/req-util';
import {ApisContext, useApis} from '../apis/ApisContext';
import {CropTags} from '../components/CropTags';
import {DownloadButton} from '../components/DownloadButton';
import {InfinityTable, InfinityTableProps, defaultColumnSizes, getAllowedOrdering} from '../components/InfinityTable';
import {YieldTHa} from '../components/Yield';
import {ErrorBoundary} from '../util/ErrorBoundary';
import './Farms.css';
import './ListView.css';

// PolicyListItem stores re-aggregated data as GetPolicyRowsRow only delivers the raw data, which is then re-aggregated
// at the policy level.
interface PolicyListItem {
  id: string;
  policy_id: string;
  added_on: string;
  policy_number: string;
  user_group: string;
  comments?: string;
  crop_id?: string;
  harvest_year?: HarvestYear;
  feasible_yield_t_ha?: number;
  insured_yield_t_ha?: number;
  estimated_yield_t_ha?: number;
}

interface GroupedHarvestData {
  crop_id: string;
  harvest_year: HarvestYear;
  harvest_data: HarvestData[];
}

const columnHelper = createColumnHelper<PolicyListItem>();

// Explicitly list the sortable columns for get_policy_rows here. If a new sortable column is added to get_policy_rows,
// it must be added here as well. This is a safety measure to ensure we are only trying to sort on supported columns.
// TODO(seb): Ideally we could get this list directly from the API. One way could maybe be a custom type for the
//  ordering columns?
const SortableColumns = ['added_on', 'policy_number'] as const;
type SortableColumn = (typeof SortableColumns)[number];
const initialSorting = {id: 'added_on', desc: true} as const;
const fetchSetSize: number = 100;

const getRowId = (row: PolicyListItem): string => row.id;
const getSortValue = (row: PolicyListItem, columnId: SortableColumn): string | null =>
  (row && (row[columnId] as string)) || null;
const getPolicyNumber = (row: PolicyListItem): string | null => row.policy_number;
const getUserGroup = (row: PolicyListItem): string => row.user_group;
const getComments = (row: PolicyListItem): string | null => row.comments ?? null;
const getCrop = (row: PolicyListItem): string | null => row.crop_id ?? null;
const getHarvestYear = (row: PolicyListItem): HarvestYear => row.harvest_year as HarvestYear;
const getFeasibleYield = (row: PolicyListItem): number | undefined => row.feasible_yield_t_ha;
const getInsuredYield = (row: PolicyListItem): number | undefined => row.insured_yield_t_ha;
const getEstimatedYield = (row: PolicyListItem): number | undefined => row.estimated_yield_t_ha;
const placeholders = getPlaceholders(20);

export default function Policies() {
  const {authedFetcher, t} = useContext(ApisContext);
  const [selectedPolicy, setSelectedPolicy] = React.useState<PolicyListItem[]>([]);
  const [hasData, setHasData] = React.useState<boolean>(false);
  const units = useSelector(getUnitSystem);
  const crops = useSelector(getCrops);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: '__selection__',
        cell: ({row}) =>
          row.getCanSelect() && <Checkbox checked={row.getIsSelected()} onChange={() => row.toggleSelected()} />,
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getPolicyNumber, ReturnType<typeof getPolicyNumber>>(getPolicyNumber, {
        id: 'policy_number',
        header: t('PolicyNumber'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: true,
      }),
      columnHelper.accessor<typeof getUserGroup, ReturnType<typeof getUserGroup>>(getUserGroup, {
        id: 'user_group',
        header: t('UserGroup'),
        size: defaultColumnSizes.s,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getComments, ReturnType<typeof getComments>>(getComments, {
        id: 'comments',
        header: t('Comments'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getCrop, ReturnType<typeof getCrop>>(getCrop, {
        id: 'crop_id',
        header: t('harvest_crop'),
        size: defaultColumnSizes.l,
        cell: ({getValue}) => (
          <span className="mask">
            <CropTags cropIds={[getValue()]} />
          </span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getHarvestYear, ReturnType<typeof getHarvestYear>>(getHarvestYear, {
        id: 'harvest_year',
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        header: t('HarvestYear'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFeasibleYield, ReturnType<typeof getFeasibleYield>>(getFeasibleYield, {
        id: 'feasible_yield',
        cell: ({getValue, row}) => (
          <span className="mask numbers">
            <YieldTHa value={getValue()} cropId={getCrop(row.original)} />
          </span>
        ),
        header: t('FeasibleYield'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getInsuredYield, ReturnType<typeof getInsuredYield>>(getInsuredYield, {
        id: 'insured_yield',
        header: t('InsuredYield'),
        size: defaultColumnSizes.xs,
        cell: ({getValue, row}) => (
          <span className="mask numbers">
            <YieldTHa value={getValue()} cropId={getCrop(row.original)} />
          </span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getEstimatedYield, ReturnType<typeof getEstimatedYield>>(getEstimatedYield, {
        id: 'estimated_yield',
        header: t('EstimatedYield'),
        size: defaultColumnSizes.xs,
        cell: ({getValue, row}) => (
          <span className="mask numbers">
            <YieldTHa value={getValue()} cropId={getCrop(row.original)} />
          </span>
        ),
        enableSorting: false,
      }),
    ],
    [t],
  );

  const regroupPolicyHarvestData: (row: GetPolicyRowsRow) => PolicyListItem[] = useCallback(
    row => {
      const {policy, harvest_data} = row;
      if (!harvest_data || harvest_data.length === 0) {
        // There are policies without harvests.
        return [
          {
            id: policy!.policy_id + '$$$none$$$none',
            policy_id: policy!.policy_id,
            added_on: policy!.added_on,
            policy_number: policy!.policy_number,
            comments: policy!.comments ?? undefined,
            user_group: policy!.user_group,
          },
        ];
      }

      const byCropAndYear: Record<string, GroupedHarvestData> =
        harvest_data?.reduce(
          (grouped, hd) => {
            if (!hd) {
              return grouped;
            }
            const key = hd.harvest_year + '-' + hd.crop_id;
            grouped[key] = grouped[key] || {
              crop_id: hd.crop_id,
              harvest_year: hd.harvest_year,
              harvest_data: [],
            };
            grouped[key].harvest_data.push(hd);
            return grouped;
          },
          {} as Record<string, GroupedHarvestData>,
        ) ?? {};

      return Object.values(byCropAndYear).map(byYearCrop => {
        const stats = aggregatePolicyHarvestData(units, crops, byYearCrop.harvest_data, byYearCrop.crop_id);
        return {
          id: policy!.policy_id + '$$$' + byYearCrop.crop_id + '$$$' + byYearCrop.harvest_year,
          policy_id: policy!.policy_id,
          added_on: policy!.added_on,
          policy_number: policy!.policy_number,
          comments: policy!.comments ?? undefined,
          user_group: policy!.user_group,
          crop_id: byYearCrop.crop_id,
          harvest_year: byYearCrop.harvest_year,
          insured_yield_t_ha: stats.insured ?? undefined,
          feasible_yield_t_ha: stats.feasible ?? undefined,
          estimated_yield_t_ha: stats.estimated ?? undefined,
        };
      });
    },
    [units, crops],
  );

  const fetchDataFromApi: InfinityTableProps<PolicyListItem>['fetchData'] = useCallback(
    async ({orderBy, filters, pageParam}): Promise<PolicyListItem[]> => {
      const req: GetPolicyRowsRequest = {
        ...filtersToRequest(filters),
        // getPolicyRows may fail in subtle ways if used with unsupported ordering.
        ordering: getAllowedOrdering(orderBy, SortableColumns, 'added_on'),
        row_count: fetchSetSize,
        continue_from_val: pageParam?.continue_from_val,
        // PolicyListItem has an aggregate id consisting of policy_id, crop_id and harvest_year.
        // For pagination on the api, we only need the policy_id, which are the first 36 chars (uuid).
        continue_from_id: pageParam?.continue_from_id?.substring(0, 36),
      };
      const aggregatedPolicies: GetPolicyRowsRow[] = (await getPolicyRows(authedFetcher, req)) as GetPolicyRowsRow[];
      return aggregatedPolicies.flatMap<PolicyListItem>(regroupPolicyHarvestData);
    },
    [authedFetcher, regroupPolicyHarvestData],
  );

  return (
    <div className="list-view list-policies">
      <ErrorBoundary>
        <PolicyListActions selectedPolicies={selectedPolicy} hasData={hasData} />
        <InfinityTable<PolicyListItem>
          columns={columns}
          initialSorting={initialSorting}
          fetchData={fetchDataFromApi}
          fetchSetSize={fetchSetSize}
          tableId="list/policy"
          getRowId={getRowId}
          getSortValue={getSortValue}
          indentDetails={1}
          placeholderData={placeholders}
          onRowSelectionChange={setSelectedPolicy}
          onNumberOfDataItemsChange={count => setHasData(count > 0)}
        />
      </ErrorBoundary>
    </div>
  );
}

interface PolicyListActionsProps {
  hasData: boolean;
  selectedPolicies: PolicyListItem[];
}

// Component holding the action buttons on top of the list.
const PolicyListActions: React.FC<PolicyListActionsProps> = React.memo(({hasData, selectedPolicies}) => {
  const {t} = useApis();
  const selectedPoliciesCount: number = selectedPolicies.length;
  const hasSelectedPolicies: boolean = selectedPoliciesCount > 0;
  const selectedPolicyIds: string[] = selectedPolicies.map(selected => selected.policy_id);
  const numGroups: number = new Set(selectedPolicies.map(selected => selected.user_group)).size;
  const flags = useSelector(getEnabledFlags);

  return (
    <>
      <div className="list-view-action-buttons">
        <DownloadButton label="DownloadXLSX" enabled={hasData} downloadType="policy" />
        {flags.has('hideEditingFunctionality') ? null : !hasSelectedPolicies ? (
          <Tooltip title={t('PleaseSelectFarm')}>
            <Button disabled>{t('AddEditors')}</Button>
          </Tooltip>
        ) : numGroups > 1 ? (
          <Tooltip title={t('AddEditorsOneGroup')}>
            <Button disabled>{t('AddEditors')}</Button>
          </Tooltip>
        ) : (
          <Link to={'/assign-editors/policy/' + selectedPolicyIds.join(',')}>
            <Button id="add-editors">{t('AddEditors')}</Button>
          </Link>
        )}
      </div>
    </>
  );
});

function getPlaceholders(count: number): PolicyListItem[] {
  const placeholders: PolicyListItem[] = [];
  const randomStr = (min: number, max: number) => ''.padStart(min + Math.floor(Math.random() * (max - min)), 'x');
  const randomElement = <T,>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];
  for (let i = 0; i < count; i++) {
    placeholders.push({
      id: '__placeholder__' + i,
      policy_id: '__placeholder__' + i,
      comments: randomStr(0, 50),
      added_on: new Date().toISOString(),
      policy_number: randomStr(5, 20),
      user_group: randomStr(10, 30),
      crop_id: randomElement(HarvestCrop as unknown as string[]),
      insured_yield_t_ha: Math.random() * 10,
      feasible_yield_t_ha: Math.random() * 10,
      estimated_yield_t_ha: Math.random() * 10,
    });
  }
  return placeholders;
}
