import {EnvironmentOutlined} from '@ant-design/icons';
import {Row, createColumnHelper} from '@tanstack/react-table';
import queryString from 'query-string';
import React, {MouseEventHandler, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import {GetSampleRowsRequest, GetSampleRowsRow, getSampleRows, UserEntity} from '../../../src/models/interfaces';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getCrops} from '../../../src/selectors/crops';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {farmDesc, fieldDesc, harvestDesc} from '../../../src/text/desc';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {DownloadButton} from '../components/DownloadButton';
import EntityInfo from '../components/EntityInfo';
import Gallery from '../components/Gallery';
import {
  InfinityTable,
  InfinityTableProps,
  FetchDataOptions,
  defaultColumnSizes,
  getAllowedOrdering,
} from '../components/InfinityTable';
import {UserEntityInfo} from '../components/UserEntityInfo';
import {Yield} from '../components/Yield';
import {fetchSelectedEntity} from '../map/useCurrentMarker';
import {ErrorBoundary} from '../util/ErrorBoundary';
import {ExpanderCol} from './ListView';

const PhotoLink = ({idx, onClick}: {idx: number; onClick: MouseEventHandler}) => (
  <a href="#" key={'photolink-' + idx} onClick={onClick}>
    ({idx}){' '}
  </a>
);

const SampleDetails: React.FC<{row: Row<GetSampleRowsRow>}> = React.memo(({row}) => (
  <EntityInfo focus={{type: 'sample', id: row.original.sample!.sample_id}} expanded={false} harvestDataObj={null} />
));

// Explicitly list the sortable columns for get_sample_rows here. If a new sortable column is added to get_sample_rows,
// it must be added here as well. This is a safety measure to ensure we are only trying to sort on supported columns.
const SortableColumns = ['added_on'] as const;
type SortableColumn = (typeof SortableColumns)[number];

const getRowId = (row: GetSampleRowsRow) => row.sample!.sample_id;
const getSampleLocation = (row: GetSampleRowsRow) => row.sample!.sample_id;
const getSampleDate = (row: GetSampleRowsRow) => row.sample?.sample_date;
const getAddedBy = (row: GetSampleRowsRow) => row.sample?.added_by;
const getPhotos = (row: GetSampleRowsRow) => row.sample?.images;
const getSortValue = (row: GetSampleRowsRow, columnId: SortableColumn) => row.sample![columnId];

const fetchSetSize = 100;
const columnHelper = createColumnHelper<GetSampleRowsRow>();

export const Samples: React.FC = () => {
  const {authedFetcher, t} = useApis();
  const [hasData, setHasData] = useState<boolean>(false);
  const crops: IndexedCrops = useSelector(getCrops);
  const countryGroups: string[] = useSelector(getCountryCodeGroups);

  const {location} = useHistory();
  const params = location && queryString.parse(location.search);
  const filteredSampleId = params && params.sample_id;

  const getFarmDesc = useCallback((row: GetSampleRowsRow) => farmDesc(t, row.farm!), [t]);
  const getFieldDesc = useCallback(
    (row: GetSampleRowsRow) =>
      row.field ? fieldDesc(t, crops, countryGroups, row.field!, row.harvest!, row.policy, undefined, true) : '',
    [countryGroups, crops, t],
  );
  const getHarvestDesc = useCallback(
    (row: GetSampleRowsRow) =>
      harvestDesc(t, crops, row.harvest!, row.field?.field_location ?? null, countryGroups, row.policy),
    [countryGroups, crops, t],
  );
  const getCropCondition = useCallback(
    (row: GetSampleRowsRow) => row.sample?.crop_condition && t(row.sample.crop_condition),
    [t],
  );
  const getEstimatedYield = (row: GetSampleRowsRow) => row.sample?.estimated_yield ?? undefined;

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: '__details__',
        cell: ({row}) =>
          row.getCanExpand() && (
            <span onClick={() => row.toggleExpanded()} title={row.getIsExpanded() ? undefined : t('Details')}>
              <ExpanderCol.Expander isExpanded={row.getIsExpanded()} />
            </span>
          ),
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getSampleLocation, ReturnType<typeof getSampleLocation>>(getSampleLocation, {
        id: 'sample_location',
        header: '',
        cell: ({getValue}) =>
          getValue() ? (
            <Link to={{pathname: '/map/base', search: '?sample_id=' + getValue()}} title={t('ShowOnMap')}>
              <EnvironmentOutlined style={{color: PALETTE_COLORS.secondary}} />
            </Link>
          ) : null,
        size: defaultColumnSizes.xxs,
        enableSorting: false,
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getFarmDesc, ReturnType<typeof getFarmDesc>>(getFarmDesc, {
        id: 'farm',
        header: t('Farm'),
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        size: defaultColumnSizes.l,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getFieldDesc, ReturnType<typeof getFieldDesc>>(getFieldDesc, {
        id: 'field',
        header: t('Field'),
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        size: defaultColumnSizes.l,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getHarvestDesc, ReturnType<typeof getHarvestDesc>>(getHarvestDesc, {
        id: 'harvest',
        header: t('Harvest'),
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        size: defaultColumnSizes.m,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getSampleDate, ReturnType<typeof getSampleDate>>(getSampleDate, {
        id: 'sample_date',
        header: t('SampleDate'),
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        size: defaultColumnSizes.s,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getCropCondition, ReturnType<typeof getCropCondition>>(getCropCondition, {
        id: 'crop_condition',
        header: t('CropCondition'),
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        size: defaultColumnSizes.s,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getEstimatedYield, ReturnType<typeof getEstimatedYield>>(getEstimatedYield, {
        id: 'estimated_yield',
        header: t('EstimatedYield'),
        cell: ({getValue, row}) => (
          <span className="mask numbers">
            <Yield value={getValue()} cropId={row.original.harvest?.crop_id ?? null} />
          </span>
        ),
        size: defaultColumnSizes.s,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getAddedBy, ReturnType<typeof getAddedBy>>(getAddedBy, {
        id: 'added_by',
        header: t('AddedBy'),
        cell: ({getValue}) => <span className="mask">{<UserEntityInfo email={getValue()} />}</span>,
        size: defaultColumnSizes.s,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getPhotos, ReturnType<typeof getPhotos>>(getPhotos, {
        id: 'photos',
        header: t('Photos'),
        cell: ({getValue}) => {
          if (!getValue()) return null;
          return (
            <span className="mask">
              <Gallery showPreview={false} images={getValue()!} LinkComponent={PhotoLink} />
            </span>
          );
        },
        size: defaultColumnSizes.xs,
        enableSorting: false,
        enableResizing: true,
      }),
    ],
    [getCropCondition, getFarmDesc, getFieldDesc, getHarvestDesc, t],
  );

  const onFetchData: InfinityTableProps<GetSampleRowsRow>['fetchData'] = React.useCallback(
    async (options: FetchDataOptions) => {
      if (filteredSampleId && filteredSampleId.length > 0) {
        const id = filteredSampleId instanceof Array ? filteredSampleId[0] : filteredSampleId;
        const resolved = await fetchSelectedEntity(authedFetcher, {type: 'sample', id});
        if (!resolved.sample) {
          return [];
        }
        const row: GetSampleRowsRow = {
          farm: resolved.farm ?? null,
          field: resolved.field ?? null,
          harvest: resolved.harvests[0],
          sample: resolved.sample,
          policy: null,
          region_info: null,
        };
        return [row];
      }

      const {filters, pageParam, orderBy} = options;
      const req: GetSampleRowsRequest = {
        ...filtersToRequest(filters),
        ...pageParam,
        // getSampleRows may fail in subtle ways if used with unsupported ordering.
        ordering: getAllowedOrdering(orderBy, SortableColumns, 'added_on'),
        row_count: fetchSetSize,
      };
      return getSampleRows(authedFetcher, req);
    },
    [authedFetcher, filteredSampleId],
  );

  return (
    <div className="list-samples">
      <div className="list-view-action-buttons">
        <DownloadButton label={'DownloadXLSX'} downloadType="sample" enabled={hasData} />
      </div>
      <ErrorBoundary>
        <InfinityTable<GetSampleRowsRow>
          columns={columns}
          initialSorting={{id: 'added_on', desc: true}}
          tableId="list/samples"
          fetchData={onFetchData}
          fetchSetSize={fetchSetSize}
          getRowId={getRowId}
          getSortValue={getSortValue}
          renderDetails={SampleDetails}
          onNumberOfDataItemsChange={n => setHasData(n > 0)}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Samples;
