import SocketIOClient from 'socket.io-client';
import {Clock} from '../../../src/Clock';
import {AuthI} from '../../../src/CommonApis';
import {baseFetcher} from '../../../src/FetcherFunc';
import {initI18n} from '../../../src/i18n/i18n-util';
import {reportErr} from '../util/err';
import {Apis} from './Apis';
import {WebAnalytics} from './WebAnalytics';
import {createServices} from './createServices';
import {versionStr} from './env';
import {getLocale} from './i18n';

export async function createApis(auth: AuthI): Promise<Apis> {
  const clock = new Clock();

  const fetcher = baseFetcher.bind(null, window.location.origin, clock);
  const socket = SocketIOClient(window.location.origin, {path: '/api2/socket', autoConnect: false});
  const locale = getLocale(auth);
  const t = initI18n(locale);
  const services = await createServices(auth, clock, t, fetcher, socket);
  const logout = async () => {
    services.store.dispatch({type: 'RESET'});
    await auth.logout();
  };
  const analytics = new WebAnalytics(
    auth.getUser().email,
    versionStr,
    locale,
    clock,
    services.authedFetcher,
    reportErr,
    30,
  );

  return {
    ...services,
    locale,
    clock,
    t,
    fetchFn: fetch.bind(window),
    getAuthToken: auth.getAuthToken,
    logout,
    getUser: auth.getUser,
    analytics,
  };
}
