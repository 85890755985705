import React from 'react';
import {BlurEventHandler, ChangeEventHandler, FormyI, FormyMode, FormyState} from './index';

export interface FormyComponentProps<F, Fk extends keyof F> {
  formy: FormyI<F>;
  field: Fk;

  // Formy components will normally update the value (F[Fk]) by themselves. However, composite components may
  // want to hook back the change handler and process the value, prior to updating it. For example, FormyYieldLosses
  // uses this to set the estimated yield, when the feasible yield or the loss is changed.
  // NOTE: if set, the callback is responsible for setting the value for F[Fk]!
  // TODO(savv): Deprecate Formy.addOnChangeHandler in favor of direct onChange handlers.
  onChange?: ChangeEventHandler<F, Fk>;
  onBlur?: BlurEventHandler;
}

export class FormyComponent<
  F,
  Fk extends keyof F,
  P extends FormyComponentProps<F, Fk> = FormyComponentProps<F, Fk>,
  S = {},
> extends React.PureComponent<P, S> {
  private valueCb: () => F[Fk] = this.props.formy.watchValue(this.props.field, this);
  private errorCb: () => boolean = this.props.formy.watchError(this.props.field, this);
  private _mode: Readonly<Pick<FormyState, 'mode'>> = this.props.formy.watchState('mode', this);

  get mode(): FormyMode {
    return this._mode.mode;
  }

  get value(): F[Fk] {
    return this.valueCb();
  }

  get error(): boolean {
    return this.errorCb();
  }

  handleChange: ChangeEventHandler<F, Fk> = async fieldValue => {
    if (this.props.onChange) {
      this.props.onChange(fieldValue);
    } else {
      this.props.formy.getChangeHandler(this.props.field)(fieldValue);
    }
  };

  handleBlur: BlurEventHandler = async () => {
    if (this.props.onBlur) {
      await this.props.onBlur();
    } else {
      await this.props.formy.getBlurHandler(this.props.field)();
    }
  };

  componentWillUnmount() {
    this.props.formy.unwatch(this);
  }
}
