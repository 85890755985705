import {TelepacHarvestContents} from '../gt-pack/telepac';
import {FarmReportValues, ReportSignature, VisitReportOptions} from '../report/report-types';
import {isBool, isNum} from '../validator-constraints';
import {LossCause, VisitType} from './interfaces';

export class FarmCustomColumns {
  constructor(x: null | any) {}
}

export class PolicyCustomColumns {
  constructor(x: null | any) {}
}

export class FieldCustomColumns {
  constructor(x: null | any) {}
}

export type AffectedZone = {
  // TODO(seb): Remove optionality after Q2 2024.
  inseeCode?: string;
  identifiers: string[];
  affected: boolean;
  area: number;
  lossCauses: LossCause[];
  lossLabels: string[];
};

export class HarvestCustomColumns {
  grpmFranchises: string[] | null;
  grpmPastYields: [null | number, null | number, null | number, null | number, null | number] | null;
  grpmAvgYield: number | null;
  grpmUpdatedYields: [null | number, null | number, null | number, null | number, null | number] | null;
  grpmAffectedZones: AffectedZone[] | null;
  grpmCulture: string | null;
  grpmCropDetails: {
    Espece: string | null;
    Variete: string | null;
    Saisonnalite: string | null;
    DetailRegional: string | null;
    Couleur: string | null;
    ModeDeProduction: string | null;
    Destination: string | null;
  } | null;
  etoileFranchises: string[] | null;
  etoileCropCode: string;
  etoileCropInfoCode: string;
  etoileCngraCode: string;
  telepacData: TelepacHarvestContents | null;

  constructor(x: null | any) {
    Object.assign(this, x);
    this.grpmFranchises = getStrArray(x, 'grpmFranchises');
    this.grpmPastYields = x?.grpmPastYields ?? null;
    if (this.grpmPastYields?.length != 5 || this.grpmPastYields.some(x => !isNum(x))) {
      this.grpmPastYields = null;
    }
    this.grpmUpdatedYields = x?.grpmUpdatedYields ?? null;
    if (this.grpmUpdatedYields?.length != 5 || this.grpmUpdatedYields.some(x => !isNum(x))) {
      this.grpmUpdatedYields = null;
    }
    this.grpmAvgYield = getNum(x, 'grpmAvgYield');
    this.grpmAffectedZones = x?.grpmAffectedZones ?? null;
    if (this.grpmAffectedZones) {
      if (
        !this.grpmAffectedZones.every(y => getStrArray(y, 'identifiers') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossCauses') != null) ||
        !this.grpmAffectedZones.every(y => getStrArray(y, 'lossLabels') != null) ||
        !this.grpmAffectedZones.every(y => isBool(y?.affected)) ||
        !this.grpmAffectedZones.every(y => getNum(y, 'area') != null)
      ) {
        this.grpmAffectedZones = null;
      }
    }
    this.telepacData = x?.telepacData ?? null;
    this.grpmCulture = getStr(x, 'grpmCulture');
    this.grpmCropDetails = x?.grpmCropDetails
      ? {
          Espece: getStr(x.grpmCropDetails, 'Espece'),
          Variete: getStr(x.grpmCropDetails, 'Variete'),
          Saisonnalite: getStr(x.grpmCropDetails, 'Saisonnalite'),
          DetailRegional: getStr(x.grpmCropDetails, 'DetailRegional'),
          Couleur: getStr(x.grpmCropDetails, 'Couleur'),
          ModeDeProduction: getStr(x.grpmCropDetails, 'ModeDeProduction'),
          Destination: getStr(x.grpmCropDetails, 'Destination'),
        }
      : null;

    this.etoileFranchises = getStrArray(x, 'etoileFranchises');
    this.etoileCropCode = x?.etoileCropCode ?? null;
    this.etoileCropInfoCode = x?.etoileCropInfoCode ?? null;
    this.etoileCngraCode = x?.etoileCngraCode ?? null;
  }
}

function getStrArray(x: null | undefined | Record<string, any>, prop: string): null | string[] {
  const val = x?.[prop];
  if (val instanceof Array && val.every(x => typeof x == 'string')) {
    return val;
  }

  return null;
}

function getNum(x: null | undefined | Record<string, any>, prop: string): null | number {
  const val = x?.[prop];
  if (typeof val == 'number') {
    return val;
  }

  return null;
}

function getStr(x: null | undefined | Record<string, any>, prop: string): null | string {
  const val = x?.[prop];
  if (typeof val == 'string') {
    return val;
  }
  return null;
}

export const GrpmVisitAction = [
  'preinspection-none',
  'validation-without-review',
  'validation-with-review',
  'monitoring-without-review',
  'monitoring-without-review-provisioning',
  'monitoring-with-review',
  'monitoring-with-review-provisioning',
  'claim-some',
  'claim-all',
  'claim-delivery',
] as const;
export type GrpmVisitAction = (typeof GrpmVisitAction)[number];

export const GrpmVisitTypeToActionMapping: Record<VisitType, GrpmVisitAction[]> = {
  'preinspection-visit': ['preinspection-none'],
  'validation-visit': ['validation-without-review', 'validation-with-review'],
  'monitoring-visit': [
    'monitoring-without-review',
    'monitoring-without-review-provisioning',
    'monitoring-with-review',
    'monitoring-with-review-provisioning',
  ],
  'claims-visit': ['claim-some', 'claim-all', 'claim-delivery'],
};

export class VisitCustomColumnsGrpm {
  visitAction: GrpmVisitAction | null;
  inseeShapes: Record<string, string>;
  constructor(x: Partial<VisitCustomColumnsGrpm> | null) {
    this.inseeShapes = x?.inseeShapes ?? {};
    this.visitAction = x?.visitAction ?? null;
  }
}

export class VisitCustomColumns {
  grpm: null | VisitCustomColumnsGrpm;
  // The values used to generate the final (signed) report.
  signedFarmReportValues?: FarmReportValues;
  // The report options used to generate the final (signed) report.
  signedVisitReportOptions?: VisitReportOptions;
  signedSignatures?: Omit<ReportSignature, 'signature'>[];

  constructor(x: Partial<VisitCustomColumns> | null) {
    this.grpm = x?.grpm ? new VisitCustomColumnsGrpm(x.grpm) : null;
    this.signedFarmReportValues = x?.signedFarmReportValues;
    this.signedVisitReportOptions = x?.signedVisitReportOptions;
    this.signedSignatures = x?.signedSignatures;
  }

  // Some custom_columns should not be duplicated when creating a duplicate visit. This
  // method ensures that all users of this class can rely on the same duplication logic.
  duplicate(): VisitCustomColumns {
    return new VisitCustomColumns({
      grpm: this.grpm,
    });
  }
}
