import {ClockI} from '../../Clock';
import {CommonApis} from '../../CommonApis';
import {BadStatusResponse, FailedToFetch, FetcherFunc, FetcherOpts} from '../../FetcherFunc';
import {getUuid} from '../../get-uuid';
import {
  ClaimDamageData,
  ClaimData,
  FarmData,
  FieldData,
  HarvestData,
  PolicyData,
  SampleData,
  VisitData,
} from '../../models/data';
import {
  Claim,
  ClaimDamage,
  Farm,
  Field,
  Harvest,
  Policy,
  Sample,
  UpdateLog,
  UserGroup,
  UserGroupMembership,
  Visit,
  VisitLite,
} from '../../models/interfaces';
import {EntityType, TableName} from '../../models/serialization';
import {DbFields, OmitDbColumns, Uuid} from '../../models/types';
import {toDateStr} from '../../util/date-util';
import {ValidateShape} from '../../util/obj-util';
import {DoesInterfaceCoverEnum} from '../../util/types';
import {entityToTransport, getPrimaryKeyName} from '../index';
import {DbMetaState, MutationQueueItem, MutationQueueItemInsertMany} from '../reducers/db';
import {ThunkAction} from '../types';
import {prepInsertedEntity, prepInsertedEntityBase} from './prepInsertedEntity';
import {ResetAction} from './reset';

export type DbResponseContents = {
  farm: Farm[];
  field: Field[];
  harvest: Harvest[];
  sample: Sample[];
  policy: Policy[];
  visit: VisitLite[];
  claim: Claim[];
  claim_damage: ClaimDamage[];

  update_log: UpdateLog[];
};

'covered' as DoesInterfaceCoverEnum<DbResponseContents, TableName | 'update_log'>;

// Note that AddToDbAction, UpdateDbAction, and DeleteDbEntriesAction are only for internal use by insert/update DB
// actions. They need to use fully-featured entities (i.e. non-Lite ones) so that the omitted fields are persisted to
// storage.
export interface AddToDbAction {
  type: 'ADD_TO_DB';
  farm: Farm[];
  field: Field[];
  harvest: Harvest[];
  sample: Sample[];
  policy: Policy[];
  visit: Visit[];
  claim: Claim[];
  claim_damage: ClaimDamage[];
}

'covered' as DoesInterfaceCoverEnum<AddToDbAction, TableName>;

export interface UpdateDbAction {
  type: 'UPDATE_DB';
  farm: {[k: string]: Partial<Farm>};
  field: {[k: string]: Partial<Field>};
  harvest: {[k: string]: Partial<Harvest>};
  sample: {[k: string]: Partial<Sample>};
  policy: {[k: string]: Partial<Policy>};
  visit: {[k: string]: Partial<Visit>};
  claim: {[k: string]: Partial<Claim>};
  claim_damage: {[k: string]: Partial<ClaimDamage>};
}

'covered' as DoesInterfaceCoverEnum<UpdateDbAction, TableName>;

export interface DeleteDbEntriesAction {
  type: 'DELETE_DB_ENTRIES';
  farm_ids: Uuid[];
  field_ids: Uuid[];
  harvest_ids: Uuid[];
  sample_ids: Uuid[];
  policy_ids: Uuid[];
  visit_ids: Uuid[];
  claim_ids: Uuid[];
  claim_damage_ids: Uuid[];
}

'covered' as DoesInterfaceCoverEnum<DeleteDbEntriesAction, `${TableName}_ids`>;

export type DbActionTypes = ResetAction | UpdateUserAction | AddToDbAction | UpdateDbAction | DeleteDbEntriesAction;

export interface SetUpdatedAtAction {
  type: 'SET_UPDATED_AT';
  updated_at: string;
}

interface UpdateUserAction {
  type: 'UPDATE_USER';
  email: string;
  name: string;
}

export interface SetLastFullSyncAction {
  type: 'SET_LAST_FULL_SYNC';
  lastFullSync: null | string;
}

// Inserts an item to the mutation queue and locks it until UnlockMutationQueue is called.
export interface AddToMutationQueue {
  type: 'ADD_TO_MUTATION_QUEUE';

  item: MutationQueueItem;
}

export interface LockMutationQueue {
  type: 'LOCK_MUTATION_QUEUE';
}

export interface UnlockMutationQueue {
  type: 'UNLOCK_MUTATION_QUEUE';
}

export interface RemoveFromInsertQueue {
  type: 'REMOVE_FROM_INSERT_QUEUE';
  requestId: string;
}

export interface SetUserGroups {
  type: 'SET_USER_GROUPS';
  userGroups: UserGroup[];
}

export interface SetUserGroupMemberships {
  type: 'SET_USER_GROUP_MEMBERSHIPS';
  userGroupMemberships: UserGroupMembership[];
}

export interface SetTodaysDate {
  type: 'SET_TODAYS_DATE';
  todaysDateUtc: string;
}

// Retry the mutation under requestId.
export interface RetryMutationItem {
  type: 'RETRY_MUTATION_ITEM';
  requestId: string;
  // Timestamp after which we should retry the given mutation.
  retryAfter: number;
}

export type DbMetaActions =
  | ResetAction
  | UpdateUserAction
  | SetLastFullSyncAction
  | SetUpdatedAtAction
  | AddToMutationQueue
  | LockMutationQueue
  | UnlockMutationQueue
  | RemoveFromInsertQueue
  | SetUserGroups
  | SetUserGroupMemberships
  | SetTodaysDate
  | RetryMutationItem;

export const _deleteDbEntries = ({
  farm,
  field,
  harvest,
  sample,
  policy,
  visit,
  claim,
  claim_damage,
}: Partial<{
  farm: Uuid[];
  field: Uuid[];
  harvest: Uuid[];
  sample: Uuid[];
  policy: Uuid[];
  visit: Uuid[];
  claim: Uuid[];
  claim_damage: Uuid[];
}>): DeleteDbEntriesAction => ({
  type: 'DELETE_DB_ENTRIES',
  farm_ids: farm || [],
  field_ids: field || [],
  harvest_ids: harvest || [],
  sample_ids: sample || [],
  policy_ids: policy || [],
  visit_ids: visit || [],
  claim_ids: claim || [],
  claim_damage_ids: claim_damage || [],
});

export function _addEntriesToDb({
  farm,
  field,
  harvest,
  sample,
  policy,
  visit,
  claim,
  claim_damage,
}: Partial<Omit<AddToDbAction, 'type'>>): AddToDbAction {
  return {
    type: 'ADD_TO_DB',
    farm: farm || [],
    field: field || [],
    harvest: harvest || [],
    sample: sample || [],
    policy: policy || [],
    visit: visit || [],
    claim: claim || [],
    claim_damage: claim_damage || [],
  };
}

export function setLastFullSync(lastFullSync: null | string): SetLastFullSyncAction {
  return {type: 'SET_LAST_FULL_SYNC', lastFullSync};
}

export function bindTodaysDate(clock: ClockI): ThunkAction<void, {dbMeta: DbMetaState}, SetTodaysDate> {
  return dispatch => {
    dispatch({type: 'SET_TODAYS_DATE', todaysDateUtc: toDateStr(clock.now())});

    clock.setInterval(
      () => {
        dispatch({type: 'SET_TODAYS_DATE', todaysDateUtc: toDateStr(clock.now())});
      },
      60 * 60 * 1000,
    );
  };
}

const kPostgresUniqueViolation = 23505;

export const MUTATION_RETRY_DELAY_IN_SECONDS = [5, 5, 10, 10, 30, 60];

export async function sendMutationToDb(authedFetcher: FetcherFunc, mutationItem: MutationQueueItem) {
  if (
    mutationItem.mutationType === 'delete' &&
    ['farm', 'field', 'harvest', 'policy', 'claim'].includes(mutationItem.table)
  ) {
    // this handles the cases, where we have a special SQL function to delete the entity and all related entities
    return await authedFetcher({
      method: 'POST',
      path: 'api/rpc/delete_' + mutationItem.table,
      json_body: {[getPrimaryKeyName(mutationItem.table)]: mutationItem.primaryKey},
    });
  }
  const opts: FetcherOpts = {
    method:
      mutationItem.mutationType === 'update' ? 'PATCH' : mutationItem.mutationType === 'delete' ? 'DELETE' : 'POST',
    path: mutationItem.mutationType === 'insert-many' ? 'api/rpc/insert_many' : 'api/' + mutationItem.table,
  };
  if (mutationItem.mutationType == 'insert-many') {
    opts.headers = [['Prefer', 'params=single-object']];
  }
  if (mutationItem.mutationType != 'insert' && mutationItem.mutationType != 'insert-many') {
    opts.params = [[getPrimaryKeyName(mutationItem.table), 'eq.' + mutationItem.primaryKey]];
  }
  if (mutationItem.mutationType != 'delete') {
    opts.json_body = mutationItem.entityTransport;
  }
  return await authedFetcher(opts);
}

// Returns the action that must happen based on the mutation error.
// Function handles the following errors:
// - FailedToFetch errors, which can happen when inserting entities while offline or when getOneAuthToken throws FailedToFetch.
//   Return 'STOP_PROCESSING' to stop processing the mutationQueue.
// - BadStatusResponse for already inserted objects (kPostgresUniqueViolation). Return 'REMOVE_FROM_QUEUE'
//   (as mutation item was already inserted) and continue processing the mutationQueue.
// - BadStatusResponse 408 and 5xx errors (e.g. if server is down). Return 'RETRY' to add a retry counter and stop processing the
//   mutationQueue.
// - For all others errors, log an error with onError to prevent data loss
//   and return 'REMOVE_FROM_QUEUE' if logServer was successful. Otherwise, return 'STOP_PROCESSING'.
// Note that STOP_PROCESSING and RETRY are very similar: both will do retry, the only difference is how long the code waits until it retries.
// STOP_PROCESSING will run the mutationItem again immediately when next sync is called again and RETRY will run it after retryAfter has passed.
// Tested requests documentation: https://github.com/greentriangle/agro/pull/3969#issuecomment-2258726679
type HandleMutationItemResult = 'STOP_PROCESSING' | 'RETRY' | 'REMOVE_FROM_QUEUE';

export async function processMutationError(
  err: unknown,
  mutationItem: MutationQueueItem,
  onError: (item: MutationQueueItem, err: unknown) => Promise<void>,
): Promise<HandleMutationItemResult> {
  if (err instanceof FailedToFetch) {
    // This can happen when inserting entities while offline or when getOneAuthToken throws FailedtoFetch.
    // Warn and stop processing the mutationQueue.
    // We kept this condition separated for now to minimize the changes. Later we may merge it with the RETRY condition.
    console.info(
      `Couldn't ${mutationItem.mutationType} ${mutationItem.requestId} to db (failed to fetch); item is in queue.`,
    );
    return 'STOP_PROCESSING';
  } else if (
    err instanceof BadStatusResponse &&
    err.json &&
    String(err.json.code) === String(kPostgresUniqueViolation) &&
    (mutationItem.mutationType === 'insert' || mutationItem.mutationType === 'insert-many')
  ) {
    // We already inserted this object in the queue, but didn't receive the acknowledgement from the server.
    // Remove item from queue and continue processing the mutationQueue.
    // Note that kPostgresUniqueViolation for insert-many is atomic and if any item fails to insert, the whole request fails.
    // We also remove the item from the queue in this case, because keeping and logging it is not useful after all.
    console.warn(
      mutationItem.mutationType === 'insert-many'
        ? `Insert many for ${mutationItem.requestId} failed with ${err.text}`
        : `Removing already inserted mutation from queue ${mutationItem.mutationType} ${mutationItem.requestId}`,
    );
    return 'REMOVE_FROM_QUEUE';
  } else if (
    err instanceof BadStatusResponse &&
    ((err.status >= 500 && err.status < 600) || err.status === 408 || err.status === 429) &&
    (!mutationItem.retries || mutationItem.retries < MUTATION_RETRY_DELAY_IN_SECONDS.length)
  ) {
    // Schedule 408 or 5xx errors for retry (e.g. if the server is down or 408 request time out).
    // If MUTATION_RETRY_DELAY_IN_SECONDS is reached, the mutationItem will be logged and removed from the queue (the if condition below).
    console.info('Scheduling mutationItem', mutationItem.mutationType, mutationItem.requestId, 'for retry.');
    return 'RETRY';
  } else {
    // For all other errors, we log the error and if successful, remove from queue. If unsuccessful, stop processing the queue.
    try {
      await onError(mutationItem, err);
      console.info(
        'Successfully logged mutationItem',
        mutationItem.mutationType,
        mutationItem.requestId,
        'and will remove it from the queue.',
      );
      return 'REMOVE_FROM_QUEUE';
    } catch (e) {
      console.error('Failed to log db-mutation to server', e);
      return 'STOP_PROCESSING';
    }
  }
}

export function syncQueuedMutations(
  // onError is a callback that will be called for each mutation that had an issue.
  // onError should try to resolve promptly as it blocks the mutation queue from proceeding.
  // In some cases, where we want to report errors and avoid data loss, it's Ok for it to take up to 1 minute.
  onError: (item: MutationQueueItem, err: unknown) => Promise<void>,
  authedFetcher: FetcherFunc,
  clock: ClockI,
): ThunkAction<
  Promise<void>,
  {
    dbMeta: DbMetaState;
  },
  LockMutationQueue | UnlockMutationQueue | RemoveFromInsertQueue | RetryMutationItem
> {
  return async (dispatch, getState) => {
    const dbMeta = getState().dbMeta;
    const mutationQueue = dbMeta.mutationQueue;
    if (dbMeta.mutationQueueLocked || dbMeta.mutationQueue.length == 0) {
      return;
    }
    dispatch({type: 'LOCK_MUTATION_QUEUE'});
    console.info('Syncing', mutationQueue && mutationQueue.length, 'mutation queue items.');
    try {
      for (const mutationItem of mutationQueue) {
        if (mutationItem.retryAfter && clock.now() < mutationItem.retryAfter) {
          // Break executing mutationQueue when retryAfter is defined and not yet beyond current date,
          // because the mutation queue needs to be executed in order (e.g. need to make sure farm is inserted before harvest).
          break;
        }

        let processResult: HandleMutationItemResult;
        try {
          await sendMutationToDb(authedFetcher, mutationItem);
          processResult = 'REMOVE_FROM_QUEUE';
        } catch (err) {
          processResult = await processMutationError(err, mutationItem, onError);
        }

        if (processResult === 'REMOVE_FROM_QUEUE') {
          // Currently remove from queue only in the following cases:
          // - processing the item was successful
          // - mutation item was already inserted, but we didn't receive an acknowledgment from the server
          // - mutation item can't be updated, because it was deleted
          // - MUTATION_RETRY_DELAY_IN_SECONDS is reached and onError was successful
          // - any other error and onError was successful
          dispatch({type: 'REMOVE_FROM_INSERT_QUEUE', requestId: mutationItem.requestId});
        } else if (processResult === 'STOP_PROCESSING') {
          // If we are unable to execute a mutation, we do not continue with the remaining mutations, because
          // mutations need to be executed in order. Otherwise, we would try to add a field before its farm, which
          // will cause an error.
          break;
        } else if (processResult === 'RETRY') {
          // Dispatch retry action to increase retries counter.
          dispatch({
            type: 'RETRY_MUTATION_ITEM',
            requestId: mutationItem.requestId,
            retryAfter: clock.now() + MUTATION_RETRY_DELAY_IN_SECONDS[mutationItem.retries] * 1000,
          });
          break;
        }
      }
    } finally {
      dispatch({type: 'UNLOCK_MUTATION_QUEUE'});
    }
  };
}

export type DbThunkAction<R> = ThunkAction<
  R,
  {dbMeta: DbMetaState},
  | AddToMutationQueue
  | UnlockMutationQueue
  | AddToDbAction
  | UpdateDbAction
  | RemoveFromInsertQueue
  | DeleteDbEntriesAction
>;

export function queueInsertMutation(table: TableName, entity: Partial<EntityType> | null): DbThunkAction<void> {
  return dispatch => {
    dispatch({
      type: 'ADD_TO_MUTATION_QUEUE',
      item: {
        requestId: getUuid(),
        mutationType: 'insert',
        table,
        entityTransport: entity ? entityToTransport(table, entity, {geo: 'wkt'}) : null,
        retries: 0,
      },
    });
    dispatch(_addEntriesToDb({[table]: [entity]}));
  };
}

export function queueUpdateMutation(
  table: TableName,
  entity: Partial<EntityType>,
  primaryKey: string,
): DbThunkAction<void> {
  return dispatch => {
    if (!primaryKey) {
      throw new Error(`No primary key to update ${entity}!`);
    }
    dispatch({
      type: 'ADD_TO_MUTATION_QUEUE',
      item: {
        requestId: getUuid(),
        mutationType: 'update',
        table,
        entityTransport: entity ? entityToTransport(table, entity, {geo: 'wkt'}) : null,
        retries: 0,
        primaryKey: primaryKey,
      },
    });
    dispatch({
      type: 'UPDATE_DB',
      farm: {},
      field: {},
      harvest: {},
      sample: {},
      policy: {},
      visit: {},
      claim: {},
      claim_damage: {},
      [table]: {[primaryKey!]: entity},
    });
  };
}

export function queueDeleteMutation(table: TableName, primaryKey: string): DbThunkAction<void> {
  return dispatch => {
    if (!primaryKey) {
      throw new Error(`No primary key to delete ${table}!`);
    }
    dispatch({
      type: 'ADD_TO_MUTATION_QUEUE',
      item: {
        requestId: getUuid(),
        mutationType: 'delete',
        table,
        retries: 0,
        primaryKey: primaryKey,
      },
    });
    dispatch(_deleteDbEntries({[table]: [primaryKey]}));
  };
}

type DbActionApis = Pick<CommonApis, 'getAuthToken' | 'authedFetcher' | 'clock'>;

export function insertFarm<T>(apis: DbActionApis, farm: ValidateShape<T, FarmData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeFarm: Farm = prepInsertedEntity(apis, getState(), farm, {farm_id: getUuid()});
    dispatch(queueInsertMutation('farm', completeFarm));
    return completeFarm.farm_id;
  };
}

export function updateEntity<T>(
  apis: DbActionApis,
  table: TableName,
  entity_id: Uuid,
  entity: ValidateShape<T, Partial<EntityType>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation(table, entity, entity_id));
}

export function updateFarm<T>(
  apis: DbActionApis,
  farm_id: Uuid,
  farm: ValidateShape<T, Partial<FarmData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('farm', farm, farm_id));
}

export function insertPolicy<T>(apis: DbActionApis, policy: ValidateShape<T, PolicyData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completePolicy: Policy = prepInsertedEntity(apis, getState(), policy, {policy_id: getUuid()});
    dispatch(queueInsertMutation('policy', completePolicy));
    return completePolicy.policy_id;
  };
}

export function updatePolicy<T>(
  apis: DbActionApis,
  policy_id: Uuid,
  policy: ValidateShape<T, Partial<PolicyData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('policy', policy, policy_id));
}

export function insertHarvest<T>(apis: DbActionApis, harvest: ValidateShape<T, HarvestData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeHarvest: Harvest = prepInsertedEntity(apis, getState(), harvest, {harvest_id: getUuid()});
    dispatch(queueInsertMutation('harvest', completeHarvest));
    return completeHarvest.harvest_id;
  };
}

export function insertClaim<T>(apis: DbActionApis, claim: ValidateShape<T, ClaimData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeClaim: Claim = prepInsertedEntity(apis, getState(), claim, {claim_id: getUuid()});
    dispatch(queueInsertMutation('claim', completeClaim));
    return completeClaim.claim_id;
  };
}

export function insertClaimDamage<T>(
  apis: DbActionApis,
  claimDamage: ValidateShape<T, ClaimDamageData>,
): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeClaimDamage = prepInsertedEntityBase(apis, getState(), claimDamage, {claim_damage_id: getUuid()});
    dispatch(queueInsertMutation('claim_damage', completeClaimDamage));
    return completeClaimDamage.claim_damage_id;
  };
}

export function updateClaim<T>(claim_id: Uuid, claim: ValidateShape<T, Partial<ClaimData>>): DbThunkAction<Uuid> {
  return dispatch => {
    dispatch(queueUpdateMutation('claim', claim, claim_id));
    return claim_id;
  };
}

export function updateClaimDamage<T>(
  claim_damage_id: Uuid,
  claimDamage: ValidateShape<T, Partial<ClaimDamageData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('claim_damage', claimDamage, claim_damage_id));
}

export function updateHarvest<T>(
  harvest_id: Uuid,
  harvest: ValidateShape<T, Partial<HarvestData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('harvest', harvest, harvest_id));
}

export function insertField<T>(
  apis: DbActionApis,
  field: ValidateShape<T, Omit<Field, 'field_id' | keyof DbFields>>,
): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeField: Field = prepInsertedEntity(apis, getState(), field, {field_id: getUuid()});
    dispatch(queueInsertMutation('field', completeField));
    return completeField.field_id;
  };
}

export function insertMany<F1, P, F2, H, V, C, CD>(
  apis: DbActionApis,
  // TODO(seb): Relax the entity types, to not require the <entity>_id to be present?
  partialFarms: ValidateShape<F1, OmitDbColumns<Farm>>[],
  partialPolicies: ValidateShape<P, OmitDbColumns<Policy>>[],
  partialFields: ValidateShape<F2, OmitDbColumns<Field>>[],
  partialHarvests: ValidateShape<H, OmitDbColumns<Harvest>>[],
  partialVisits: ValidateShape<V, OmitDbColumns<Visit>>[],
  partialClaims: ValidateShape<C, OmitDbColumns<Claim>>[],
  partialClaimDamages: ValidateShape<CD, OmitDbColumns<ClaimDamage>>[],
): DbThunkAction<{
  farm_ids: string[];
  policy_ids: string[];
  field_ids: string[];
  harvest_ids: string[];
  claim_ids: string[];
  claim_damage_ids: string[];
  visit_ids: string[];
}> {
  return (dispatch, getState) => {
    const farms: Farm[] = partialFarms.map(farm => prepInsertedEntity(apis, getState(), farm, {}));
    const policies: Policy[] = partialPolicies.map(policy => prepInsertedEntity(apis, getState(), policy, {}));
    const fields: Field[] = partialFields.map(field => prepInsertedEntity(apis, getState(), field, {}));
    const harvests: Harvest[] = partialHarvests.map(harvest => prepInsertedEntity(apis, getState(), harvest, {}));
    const claims: Claim[] = partialClaims.map(claim => prepInsertedEntity(apis, getState(), claim, {}));
    const claimDamages: ClaimDamage[] = partialClaimDamages.map(claimDamage =>
      prepInsertedEntityBase(apis, getState(), claimDamage, {}),
    );
    const visits: Visit[] = partialVisits.map(visit => prepInsertedEntity(apis, getState(), visit, {}));
    const item: MutationQueueItemInsertMany = {
      requestId: getUuid(),
      retries: 0,
      mutationType: 'insert-many',
      entityTransport: {
        farms: farms.map(farm => entityToTransport('farm', farm, {geo: 'wkt'})),
        policies: policies.map(policy => entityToTransport('policy', policy, {geo: 'wkt'})),
        fields: fields.map(field => entityToTransport('field', field, {geo: 'wkt'})),
        harvests: harvests.map(harvest => entityToTransport('harvest', harvest, {geo: 'wkt'})),
        claims: claims.map(claim => entityToTransport('claim', claim, {geo: 'wkt'})),
        claim_damages: claimDamages.map(claimDamage => entityToTransport('claim_damage', claimDamage, {geo: 'wkt'})),
        visits: visits.map(visit => entityToTransport('visit', visit, {geo: 'wkt'})),
      },
    };
    dispatch({type: 'ADD_TO_MUTATION_QUEUE', item: item});
    dispatch(
      _addEntriesToDb({
        farm: farms,
        policy: policies,
        field: fields,
        harvest: harvests,
        sample: [],
        claim: claims,
        claim_damage: claimDamages,
        visit: visits,
      }),
    );

    return {
      farm_ids: farms.map(x => x.farm_id),
      policy_ids: policies.map(x => x.policy_id),
      field_ids: fields.map(x => x.field_id),
      harvest_ids: harvests.map(x => x.harvest_id),
      claim_ids: claims.map(x => x.claim_id),
      claim_damage_ids: claimDamages.map(x => x.claim_damage_id),
      visit_ids: visits.map(x => x.visit_id),
    };
  };
}

export function updateField<T>(
  apis: DbActionApis,
  field_id: Uuid,
  field: ValidateShape<T, Partial<FieldData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('field', field, field_id));
}

export function insertSample<T>(apis: DbActionApis, sample: ValidateShape<T, SampleData>): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeSample: Sample = prepInsertedEntity(apis, getState(), sample, {sample_id: getUuid()});
    dispatch(queueInsertMutation('sample', completeSample));
    return completeSample.sample_id;
  };
}

export function updateSample<T>(
  apis: DbActionApis,
  sample_id: Uuid,
  sample: ValidateShape<T, Partial<SampleData>>,
): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('sample', sample, sample_id));
}

export function insertVisit<T>(
  apis: DbActionApis,
  visit: ValidateShape<T, VisitData>,
  id: null | string,
): DbThunkAction<Uuid> {
  return (dispatch, getState) => {
    const completeVisit: Visit = prepInsertedEntity(apis, getState(), visit, {visit_id: id ?? getUuid()});
    dispatch(queueInsertMutation('visit', completeVisit));
    return completeVisit.visit_id;
  };
}

export function updateVisit<T>(visit_id: Uuid, visit: ValidateShape<T, Partial<VisitData>>): DbThunkAction<void> {
  return dispatch => dispatch(queueUpdateMutation('visit', visit, visit_id));
}

export function deleteHarvest(harvest_id: Uuid): DbThunkAction<void> {
  return async dispatch => {
    if (!harvest_id) {
      throw new Error('deleteHarvest: harvest_id is null');
    }
    dispatch(queueDeleteMutation('harvest', harvest_id));
  };
}

export function deleteSample(sample_id: Uuid): DbThunkAction<void> {
  return async dispatch => {
    if (!sample_id) {
      throw new Error('deleteSample: sample_id is null');
    }
    dispatch(queueDeleteMutation('sample', sample_id));
  };
}

export function deleteClaimDamage<T>(claimDamageId: Uuid): DbThunkAction<void> {
  return dispatch => {
    dispatch(queueDeleteMutation('claim_damage', claimDamageId));
  };
}

export function deleteVisit(visit_id: Uuid): DbThunkAction<void> {
  return dispatch => {
    if (!visit_id) {
      throw new Error('deleteVisit: visit_id is null');
    }
    dispatch(queueDeleteMutation('visit', visit_id));
  };
}
