import {DatabaseFilled, PushpinOutlined} from '@ant-design/icons';
import {List} from 'antd';
import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {PALETTE_COLORS} from '../../../src/constants/colors';
import {getCrops} from '../../../src/selectors/crops';
import {formatDate} from '../../../src/text/date';
import {cropDesc, userEntityDesc} from '../../../src/text/desc';
import {ApisContext} from '../apis/ApisContext';
import SvgImage from '../components/SvgImage';
import './Card.css';
import {useUserEntitiesByEmail} from '../queries/options';
import {LocalizedEmptyImage} from './Dashboard';
import {DashboardCard} from './DashboardCard';
import {useSampleRows} from './queries';

const Item: React.FC<{
  sample_id: string;
  title: string;
  description: string;
  fieldLine: string;
  sampleLine: string;
}> = props => (
  <List.Item>
    <div className="list__item">
      <div className="list__item-title">
        {props.title}
        <div>
          <Link to={{pathname: '/map/base', search: '?sample_id=' + props.sample_id}}>
            <PushpinOutlined style={{color: PALETTE_COLORS.secondary}} />
          </Link>
          <Link to={{pathname: '/list/samples', search: '?sample_id=' + props.sample_id}}>
            <DatabaseFilled style={{color: PALETTE_COLORS.secondary}} />
          </Link>
        </div>
      </div>
      <div>{props.description}</div>
      <div>
        <SvgImage name="triangle" color={'#000000'} width={10} />
        {props.sampleLine}
      </div>
      <div>
        <SvgImage name="circle" color={'#000000'} width={10} />
        {props.fieldLine}
      </div>
    </div>
  </List.Item>
);

const LiveFeedCard: React.FC = () => {
  const {t} = useContext(ApisContext);
  const crops = useSelector(getCrops);
  const {data: userEntitiesByEmail} = useUserEntitiesByEmail();

  // Disable refetchInterval for the initial deployment phase, might enable later.
  const {data = [], isFetching} = useSampleRows({
    /*refetchInterval: 5 * 60 * 1000*/
  });

  const dataSource = data.map(s => {
    const title = (s.sample?.sample_date && formatDate(t, s.sample.sample_date)) ?? '';
    const description = t({
      type: 'AdjusterSampledFarm',
      added_by: userEntityDesc(s.sample?.added_by, userEntitiesByEmail),
      farm_name: s.farm?.farm_name ?? '',
    });
    const sampleLineArr = [
      s.sample?.crop_condition && t(s.sample.crop_condition),
      s.sample?.estimated_yield != null && t({type: 'YieldUnit', ...s.sample.estimated_yield}),
      t({type: 'NumPhotos', num_photos: s.sample?.images.length || 0}),
    ];
    const sampleLine = sampleLineArr.filter(x => !!x).join(' | ');
    const fieldLineArr = [];
    if (s.field && s.field.field_area) {
      fieldLineArr.push(t({type: 'AreaUnit', ...s.field.field_area}));
    }
    if (s.harvest?.crop_id) {
      fieldLineArr.push(cropDesc(t, crops, s.harvest?.crop_id));
    }
    const fieldLine = fieldLineArr.filter(x => !!x).join(' | ');
    return {sample_id: s.sample!.sample_id, title, description, fieldLine, sampleLine};
  });

  return (
    <DashboardCard title={t('LiveSampleFeed')} loading={isFetching} hasData={data.length > 0} long={true}>
      <List
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={Item}
        locale={{emptyText: <LocalizedEmptyImage />}}
      />
    </DashboardCard>
  );
};

export default LiveFeedCard;
