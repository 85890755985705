import {CheckCircleOutlined} from '@ant-design/icons';
import {Button, Spin, message} from 'antd';
import {FeatureCollection, Polygon} from 'geojson';
import {GeoJSONSourceRaw} from 'mapbox-gl';
import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';
import {deepCopy} from '../../../src/deepCopy';
import equal from '../../../src/fast-deep-equal';
import {getEnabledFlags} from '../../../src/feature-flags';
import {geometryAreaSqM, getBoundingBox} from '../../../src/geo';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {getStyleUrl} from '../../../src/layers/map-layers';
import {Farm, Field, Harvest, HarvestData, Policy} from '../../../src/models/interfaces';
import {TableName} from '../../../src/models/serialization';
import {AreaValue, ValueUnit} from '../../../src/models/types';
import {syncQueuedMutations, updateFarm, updateField, updateHarvest, updatePolicy} from '../../../src/redux/actions/db';
import {MutationQueueItem} from '../../../src/redux/reducers/db';
import {dedupeCustomColumns, dedupeMetadata} from '../../../src/util/dedupe';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {useApis} from '../apis/ApisContext';
import Mapbox from '../map/Mapbox';
import {fieldBgShapeLayer, fieldShapeLayerBase} from '../map/layers-specs';
import {reportErr} from '../util/err';
import './MergeEntity.css';

type ColumnName = keyof Farm | keyof Policy | keyof Field | keyof Harvest | keyof HarvestData;
type ColumnValue = Farm[keyof Farm] | Policy[keyof Policy] | Field[keyof Field] | Harvest[keyof Harvest] | number;
type Entity = {[P in ColumnName]?: ColumnValue};
type EntityType = 'farm' | 'policy' | 'field' | 'harvest';
interface ColumnOption {
  label: string;
  value: ColumnValue;
}

function getOptions(name: ColumnName, entities: Entity[]): ColumnOption[] {
  const options: ColumnOption[] = [];
  for (let entityIdx = 0; entityIdx < entities.length; ++entityIdx) {
    const value = entities[entityIdx][name];
    if (!value) {
      continue;
    }
    let option = options.find(x => equal(x.value, value));

    if (!option) {
      option = {value, label: ''};
      options.push(option);
    }
    if ((value as any)?.type == 'Polygon') {
      if (option.label) {
        option.label += ' / ';
      }
      option.label += '#' + (entityIdx + 1);
    } else {
      option.label = JSON.stringify(value);
    }
  }
  if (entities.some(x => x[name] == null)) {
    options.push({value: null, label: '<null>'});
  }

  if (name == 'editors') {
    const allEditors = Array.from(new Set(options.map(x => x.value as string[]).flat(1)));
    if (!options.find(x => equal(x.value, allEditors))) {
      options.unshift({value: allEditors, label: allEditors.join(',')});
    }
  }

  if (name == 'comments') {
    const allComments: string[] = Array.from(new Set(options.map(x => x.value as string)));
    if (allComments.length > 1) {
      const value = allComments.join(' -- ');
      options.unshift({value, label: value});
    }
  }

  if (name == 'field_area') {
    // TODO(savv): fix this by adding options for updating the area based on field shapes:
    //  shapeOptions = getOptions('field_shape', entities).reverse();
    const shapeOptions = [] as ColumnOption[];
    for (const option of options) {
      const vv = option.value as any;
      if (vv?.unit) {
        option.label = vv.val + vv.unit;
      }
    }
    for (const option of shapeOptions) {
      const value: AreaValue = {
        val: Number((geometryAreaSqM(option.value as Polygon) / 10000).toFixed(2)),
        unit: 'hectares',
      };
      options.unshift({label: value.val + value.unit + ' (area of shape ' + option.label + ')', value});
    }
  }

  return options.map(x => ({...x, label: x.label.slice(0, 75)}));
}

const ignoreFields: Set<ColumnName> = new Set([
  'farm_id',
  'policy_id',
  'field_id',
  'harvest_id',
  'user_group',
  'added_on',
  'added_by',
  'updated_at',
  'user_location',
  'field_area_ha',
]);

const hiddenFields: Set<ColumnName> = new Set(['metadata', 'custom_columns']);

function getInitialMergedEntity(columnNames: ColumnName[], entities: Entity[]) {
  const res: Entity = {};
  for (const col of columnNames) {
    const options = getOptions(col, entities);
    res[col] = options[0]?.value;
  }

  return res;
}

export default function MergeEntity() {
  const apis = useApis();
  const {
    params: {entity_type, entity_ids},
  } = useRouteMatch<{entity_ids: string; entity_type: TableName}>();
  if (entity_type != 'farm' && entity_type != 'policy' && entity_type != 'field' && entity_type != 'harvest') {
    throw new Error('Not implemented: ' + entity_type);
  }
  const ids = useMemo(() => entity_ids.split(','), [entity_ids]);
  const entities = useAsyncMemo(async () => {
    const res = await fetchEntities(apis.authedFetcher, entity_type as 'farm', ids);
    res.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    return res;
  }, [apis.authedFetcher, entity_type, ids]);

  const columnNames = useMemo(() => {
    if (!entities) {
      return [];
    }
    const columnSet: Set<ColumnName> = new Set(entities.map(x => Object.keys(x) as ColumnName[]).flat(1));
    // Sort columns according to their order. Assume a default order of 10 if missing.
    return Array.from(columnSet).sort((aName, bName) => {
      const a = ignoreFields.has(aName) ? -1 : getOptions(aName, entities).length;
      const b = ignoreFields.has(bName) ? -1 : getOptions(bName, entities).length;
      return b - a;
    });
  }, [entities]);

  if (!entities) {
    return null;
  }

  return <InnerMergeEntity entities={entities} entity_type={entity_type} columnNames={columnNames} />;
}

export function mergeEntitiesMetadata(entity_type: EntityType, entities: Entity[]): any | null {
  const sortedEntities = entities.sort((a, b) => {
    return new Date(a.added_on).getTime() - new Date(b.added_on).getTime();
  });
  let prevEntity: Entity = sortedEntities[0];
  const mergedMetadataChanges = {metadata: prevEntity.metadata};
  for (let i = 1; i < sortedEntities.length; i++) {
    dedupeMetadata(
      prevEntity as {metadata: any},
      sortedEntities[i] as {metadata: any},
      sortedEntities[i][(entity_type + '_id') as keyof Entity],
      mergedMetadataChanges,
    );
    prevEntity = sortedEntities[i];
  }
  return mergedMetadataChanges.metadata;
}

export function mergeEntitiesCustomColumns(entities: Entity[]): any | null {
  const sortedEntities = entities
    .sort((a, b) => {
      return new Date(a.added_on).getTime() - new Date(b.added_on).getTime();
    })
    .filter(x => !!x.custom_columns);
  if (sortedEntities.length == 0) {
    return null;
  }
  const lastEntity = sortedEntities[sortedEntities.length - 1];
  const customColChanges = {
    custom_columns: {},
  };
  for (let i = 1; i < sortedEntities.length; i++) {
    dedupeCustomColumns(
      sortedEntities[i - 1] as {custom_columns: any},
      sortedEntities[i] as {custom_columns: any},
      customColChanges,
      true,
    );
  }
  return {...customColChanges.custom_columns, ...lastEntity.custom_columns};
}

interface InnerMergeEntityProps {
  entity_type: EntityType;
  columnNames: ColumnName[];
  entities: Entity[];
}

function InnerMergeEntity({entity_type, columnNames, entities}: InnerMergeEntityProps) {
  const apis = useApis();
  const [stage, setStage] = useState<'select' | 'submitting' | 'error' | 'done'>('select');
  const [mergedEntity, setMergedEntity] = useState<Entity>(getInitialMergedEntity(columnNames, entities));

  const merge = useCallback(async () => {
    try {
      setStage('submitting');
      const _mergedEntity = deepCopy(mergedEntity);
      const entityPatch: {[k: string]: any} = {};
      const entityToUpdate = entities[0];
      const entityIds = entities.map(x => x[(entity_type + '_id') as keyof typeof x]);
      _mergedEntity.metadata = mergeEntitiesMetadata(entity_type, entities);
      _mergedEntity.custom_columns = mergeEntitiesCustomColumns(entities);

      let needsPatching = false;
      for (const _k in _mergedEntity) {
        const k = _k as keyof typeof entityToUpdate;
        if (!equal(_mergedEntity[k], entityToUpdate[k])) {
          console.info('Not equal', _mergedEntity[k], entityToUpdate[k as keyof typeof entityToUpdate]);
          entityPatch[k] = _mergedEntity[k];
          needsPatching = true;
        }
      }

      if (needsPatching) {
        console.info('Patching', entity_type, entityIds[0], 'with', entityPatch);
        if (entity_type == 'farm') {
          apis.store.dispatch(updateFarm(apis, entityIds[0], entityPatch as never));
        } else if (entity_type == 'policy') {
          apis.store.dispatch(updatePolicy(apis, entityIds[0], entityPatch as never));
        } else if (entity_type == 'field') {
          apis.store.dispatch(updateField(apis, entityIds[0], entityPatch as never));
        } else if (entity_type == 'harvest') {
          apis.store.dispatch(updateHarvest(entityIds[0], entityPatch as never));
        } else {
          throw new Error('Not implemented');
        }
        const onError = async (_: MutationQueueItem, err: unknown) => {
          reportErr(err);
          console.error('Unable to sync merges:', err);
          message.error(apis.t('UnableToUpload'));
        };

        await apis.store.dispatch(syncQueuedMutations(onError, apis.authedFetcher, apis.clock));
      } else {
        console.info('No patching for', entity_type, entityIds[0]);
      }
      const path =
        entity_type == 'farm'
          ? 'api/rpc/merge_farms'
          : entity_type == 'policy'
          ? 'api/rpc/merge_policies'
          : entity_type == 'field'
          ? 'api/rpc/merge_fields'
          : entity_type == 'harvest'
          ? 'api/rpc/merge_harvests'
          : '';
      const json_body =
        entity_type == 'farm'
          ? {farm_ids: entityIds}
          : entity_type == 'policy'
          ? {policy_ids: entityIds}
          : entity_type == 'field'
          ? {field_ids: entityIds}
          : entity_type == 'harvest'
          ? {harvest_ids: entityIds}
          : {};
      await apis.authedFetcher({method: 'POST', path, json_body});
      setStage('done');
    } catch (e: any) {
      const err = e.json ? e.json.message : e.text;
      reportErr(err, 'merge-' + entity_type);
      setStage('error');
    }
  }, [apis, entities, entity_type, mergedEntity]);

  const {t} = apis;
  if (stage == 'submitting') {
    return <Spin className="merge-entity" size="large" />;
  } else if (stage == 'done') {
    let id_name = (entity_type + '_id') as `${typeof entity_type}_id`;
    if (id_name == 'harvest_id') {
      id_name = 'field_id';
    }

    return (
      <div>
        <h1>{t('Done')}</h1>
        <CheckCircleOutlined />
        <Link to={`/map/satellite?${id_name}=${entities[0][id_name]}`}>Merged {entity_type}</Link>
      </div>
    );
  }

  const columnsToRender = columnNames.filter(x => !hiddenFields.has(x));

  return (
    <div className="merge-entity">
      <table className="merge-entity">
        <tbody>
          <tr className="merge-entity">
            <th className="merge-entity">#</th>
            <th className="merge-entity">merged</th>
            {entities.map((_, rowIdx) => (
              <th key={rowIdx} className="merge-entity">
                #{rowIdx + 1}
              </th>
            ))}
          </tr>
          {columnsToRender.map((colName, colIdx) => (
            <tr key={colIdx} className="merge-entity">
              <td className="merge-entity">{t(colName as I18nSimpleKey) || colName}</td>
              <td className="merge-entity">
                <Selector
                  mergedEntity={mergedEntity}
                  entities={entities}
                  setMergedEntity={setMergedEntity}
                  name={colName}
                />
              </td>
              {entities.map((entity, rowIdx) => (
                <td
                  key={rowIdx + ',' + colIdx}
                  className={equal(entity[colName as keyof typeof entity], mergedEntity[colName]) ? 'selected' : ''}>
                  <Value k={colName} v={entity[colName as ColumnName]} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        type="primary"
        size="large"
        className="formy-input formy-submit"
        onClick={merge}
        disabled={stage != 'select'}
        data-testid="merge-button">
        {apis.t('Merge')}
      </Button>
    </div>
  );
}

function Shape({x}: {x: FeatureCollection}) {
  const flags = useSelector(getEnabledFlags);
  const styleUrl = getStyleUrl(flags, 'satellite', null, window.location.origin);
  const bbox = getBoundingBox(x);
  if (!bbox) {
    return null;
  }

  const source: GeoJSONSourceRaw = {type: 'geojson', data: x};
  return (
    <div style={{width: 300, height: 150}}>
      <Mapbox
        marker={null}
        onMapboxMapInstance={() => null}
        styleUrl={styleUrl}
        bounds={bbox}
        sources={{
          [fieldShapeLayerBase.source as string]: {source},
        }}
        layers={[{layer: fieldBgShapeLayer, displayBefore: 'road-minor'}]}
      />
    </div>
  );
}

function Value({k, v}: {k: ColumnName; v: undefined | ColumnValue}) {
  if (typeof v == 'string') {
    if (k == 'farm_id') {
      return <Link to={`/map/satellite?farm_id=${v}`}>{v}</Link>;
    } else if (k == 'field_id') {
      return <Link to={`/map/satellite?field_id=${v}`}>{v}</Link>;
    }
    return <span>{v}</span>;
  } else if (v instanceof Date) {
    return <span>{v.toISOString()}</span>;
  } else if (v == null) {
    return null;
  } else if (typeof v == 'object') {
    if (v instanceof Array) {
      if ((v as any[]).every(x => typeof x == 'string')) {
        return <span>{v.join(', ')}</span>;
      } else if (v.length == 2 && typeof v[0] == 'number' && typeof v[1] == 'number') {
        return <Link to={`/map/satellite?location=${v[1]},${v[0]}`}>{v.join(',')}</Link>;
      }
      return <span>{JSON.stringify(v)}</span>;
    } else {
      const vv = v as any;
      if (vv['type'] == 'Polygon') {
        return (
          <Shape
            x={{
              type: 'FeatureCollection',
              features: [{type: 'Feature', geometry: v as Polygon, properties: {type: 'field'}}],
            }}
          />
        );
      } else if (typeof vv.unit == 'string' && vv.unit.length > 0) {
        const unit = v as ValueUnit;
        return <span>{unit.val + unit.unit}</span>;
      }
      return <span>{JSON.stringify(v)}</span>;
    }
  } else {
    return <span>{JSON.stringify(v)}</span>;
  }
}

interface SelectorProps {
  mergedEntity: Entity;
  entities: Entity[];
  setMergedEntity: Dispatch<SetStateAction<Entity>>;
  name: ColumnName;
}

function Selector({mergedEntity, entities, setMergedEntity, name}: SelectorProps) {
  if (ignoreFields.has(name)) {
    return null;
  }

  const options = getOptions(name, entities);
  if (options.length == 0 || options.every(x => !x.value && x.value != 0)) {
    return null;
  } else if (options.length == 1) {
    return <span>'(all equal)'</span>;
  } else {
    return (
      <select
        data-testid={`merge-${name}`}
        value={JSON.stringify(mergedEntity[name])}
        onChange={v => {
          setMergedEntity({...mergedEntity, [name]: JSON.parse(v.target.value)});
        }}>
        {options.map((v, idx2) => (
          <option value={JSON.stringify(v.value)} key={idx2}>
            {v.label}
          </option>
        ))}
      </select>
    );
  }
}
