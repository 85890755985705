import {CheckCircleOutlined, SyncOutlined} from '@ant-design/icons';
import React, {useCallback} from 'react';
import {UnreachableCaseError} from 'ts-essentials';
import {useSyncFormy} from '../../../src/Formy/hooks';
import {paAllGroups, paHiddenName} from '../../../src/constants/group-consts';
import {ImportedData} from '../../../src/gt-pack/gt-pack';
import {TelepacReportPackage} from '../../../src/report/report-types';
import {getArchivedTelepacReportFilename} from '../../../src/report/report-util';
import {FormySubmit} from '../Formy/FormySubmit';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {AllFieldsForm} from './EntityForms';
import {ImportUploadForm} from './ImportUploadForm';
import {TelepacImportSummary, generateTelepacReport} from './TelepacImportSummary';
import './import-data.css';
import {saveImportedData} from './save';
import {EntityFormStage} from './stages';
import {validateImportedData} from './validators';

type ImportStage = {type: 'upload-data'} | EntityFormStage;

export default function ImportData() {
  const apis = useApis();
  const [stage, setStage] = React.useState<ImportStage>({type: 'upload-data'});

  if (stage.type == 'upload-data') {
    return <ImportUploadForm onImportedData={setStage} />;
  } else if (stage.type == 'entity-form') {
    return <ImportDataForm telepacData={stage.telepacData} initialValues={stage.initialValues} />;
  } else {
    reportErr(new UnreachableCaseError(stage));
    return <h1>{apis.t('Error')}!</h1>;
  }
}

async function saveTelepacReport(apis: Apis, filename: string, fileBuf: string | ArrayBuffer) {
  console.info('Saving telepac report to', filename);
  apis.analytics.logEvent({
    event_name: 'saveTelepacReport',
    props: {filename},
  });
  apis
    .authedFetcher({
      method: 'PUT',
      path: 'photo/telepac-reports/' + filename,
      request_body: fileBuf,
    })
    .catch(e => reportErr(e, 'upload-telepac-report'));
}

interface ImportDataFormProps {
  telepacData: TelepacReportPackage | null;
  initialValues: ImportedData;
}

type Stage = 'entity-form' | 'saving' | 'done' | 'telepac-preview';

// This component handles the last parts of the import flow, such as the main form, saving, and telepac report generation.
function ImportDataForm({telepacData, initialValues}: ImportDataFormProps) {
  const apis = useApis();
  const [stage, setStage] = React.useState<Stage>('entity-form');
  const processPacFile = useCallback(
    async (farm_id: string) => {
      if (!telepacData) {
        return;
      }
      let deliveryEmails = [apis.store.getState().dbMeta.curUser?.email || ''];

      // Override email to use dedicated inbox
      if (paAllGroups.includes(telepacData.user_group)) {
        deliveryEmails.push('recap-PAC@ca-' + paHiddenName + '.fr');
      }

      const blob = await generateTelepacReport(apis, telepacData, deliveryEmails);
      if (blob) {
        if (telepacData.harvest_year) {
          const filename = getArchivedTelepacReportFilename(farm_id, telepacData.harvest_year);
          await saveTelepacReport(apis, filename, await blob.arrayBuffer());
        } else {
          reportErr('No harvest year in telepac report', 'save-telepac-report');
        }
      }
    },
    [apis, telepacData],
  );

  const onSubmit = useCallback(
    async (data: ImportedData) => {
      window.scrollTo(0, 0);
      setStage('saving');
      const entities = await saveImportedData(apis, data, false);
      if (telepacData) {
        let farm_id = data.farms[0]?.farm_id;
        if (!farm_id && entities) {
          for (const entity of entities) {
            if (entity.entity_type == 'farm') {
              farm_id = entity.entity_id;
              break;
            }
          }
        }
        if (farm_id) {
          await processPacFile(farm_id);
        }
        setStage('telepac-preview');
      } else {
        setStage('done');
      }
    },
    [apis, processPacFile, telepacData],
  );
  const formy = useSyncFormy(
    'new',
    () => initialValues,
    apis.t,
    onSubmit,
    x => validateImportedData(apis.store.getState().crops.crops, x),
    null,
  );

  if (stage == 'entity-form') {
    return (
      <span>
        <AllFieldsForm formy={formy} field="farms" />
        <span className="import-save">
          <FormySubmit label="Save" formy={formy} />
        </span>
      </span>
    );
  } else if (stage == 'saving') {
    return (
      <h1 className="import-done">
        {apis.t('Saving')} <SyncOutlined spin />
      </h1>
    );
  } else if (stage == 'done' || !telepacData) {
    return (
      <h1 className="import-done">
        {apis.t('Done')} <CheckCircleOutlined />
      </h1>
    );
  } else if (stage == 'telepac-preview') {
    return <TelepacImportSummary telepacData={telepacData} />;
  } else {
    console.error('Unreachable stage:', stage, new UnreachableCaseError(stage));
    return null;
  }
}
