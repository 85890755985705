import {Buffer} from 'buffer';
import {ClockI} from './Clock';
import {FetchFn, GetAuthToken} from './CommonApis';
import {PD} from './util/types';

export type Methods = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD';
export type FetcherOpts = {
  method: Methods;
  path: string;
  params?: [string, string][];
  headers?: [string, string][];
  request_body?: any; // The body of the request. Takes precedence over json body. Passed to fetch as-is.
  json_body?: any;
  responseType?: 'blob' | 'json' | 'arraybuffer' | 'response'; // defaults to json; if accept==octet, defaults to arraybuffer
  timeoutMs?: number; // Defaults to 50sec.
};

export type FetcherFunc = (opts: FetcherOpts, controller?: AbortController) => Promise<any>;

export function serializeParams(params: [string, string][]) {
  return params.map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
}

export class FailedToFetch extends Error {
  constructor(method: Methods, url: string) {
    super(`FailedToFetch: ${method} ${url}`);
  }
}

export class BadStatusResponse extends Error {
  public status: number;
  public text: string;
  public json?: any;
  private opts?: FetcherOpts;
  private url: string;

  constructor(status: number, text: string, url: string, opts?: FetcherOpts, reqHeaders?: Headers) {
    const calledWith = {...opts};
    // Remove auth header from error.
    // TODO(seb): Running into a typing issue between app and web, maybe there is a better solution than using unknown?
    calledWith.headers = reqHeaders
      ? (Array.from<[string, string]>(
          (reqHeaders as unknown as {entries: () => Iterable<[string, string]>}).entries(),
        ).filter(x => x[0].toLowerCase() !== 'authorization') as [string, string][])
      : undefined;
    super(`(${status}) for ${opts ? opts.method : ''} ${url}: ${text}. Called with: ${JSON.stringify(calledWith)}.`);
    this.status = status;
    this.text = text;
    this.url = url;
    this.opts = opts;
    try {
      this.json = JSON.parse(text);
    } catch (e) {}
  }
}

export function getUrl(baseUrl: string, path: string, params?: [string, string][]): string {
  let url = baseUrl;
  if (!baseUrl || !baseUrl.endsWith('/')) {
    url += '/';
  }
  url += path;
  if (params && params.length > 0) {
    url += '?' + serializeParams(params);
  }
  return url;
}

(global as any)['pendingFetches'] = 0;

export function isFailedToFetch(e: unknown) {
  if (e instanceof FailedToFetch) {
    return true;
  }

  const name = typeof e == 'object' ? (e as PD<string>)?.name : '';
  const message = typeof e == 'object' ? (e as PD<string>)?.message?.toLocaleLowerCase() : '';
  return (
    name == 'AbortError' ||
    name == 'Aborted' ||
    message == 'failed to fetch' ||
    message == 'network request failed' ||
    message == 'software caused connection abort' ||
    message == 'the request timed out' ||
    message?.includes('failed to connect to') ||
    message?.includes('unable to resolve host')
  );
}

export async function baseFetcher(
  baseUrl: string,
  clock: ClockI,
  opts: FetcherOpts,
  controller?: AbortController,
  fetchFn: FetchFn = fetch,
): Promise<any> {
  let {method, path, params, headers, json_body, request_body} = opts;
  const url = getUrl(baseUrl, path, params);

  const requestHeaders = new Headers();
  for (const [k, v] of headers || []) {
    requestHeaders.append(k, v);
  }

  if ((method === 'POST' || method === 'PATCH') && !requestHeaders.has('Content-Type')) {
    requestHeaders.append('Content-Type', 'application/json');
  }
  if (!requestHeaders.has('accept')) {
    requestHeaders.append('accept', 'application/json');
  }

  controller = controller ?? new AbortController();
  const fetchOptions: RequestInit = {
    method,
    headers: requestHeaders,
    body: request_body != null ? request_body : json_body && JSON.stringify(json_body),
    signal: controller.signal,
  };

  clock.setTimeout(() => controller!.abort(), opts?.timeoutMs ?? 50000);
  let response: Response;
  try {
    (global as any)['pendingFetches']++;
    response = await fetchFn(url, fetchOptions);
  } catch (e) {
    if (isFailedToFetch(e)) {
      throw new FailedToFetch(method, url);
    } else {
      throw e;
    }
  } finally {
    (global as any)['pendingFetches']--;
  }

  if (!response.ok) {
    throw new BadStatusResponse(response.status, await response.text(), url, opts, requestHeaders);
  }
  if (method == 'HEAD') {
    const responseHeaders: Record<string, string> = {};
    response.headers.forEach((value: string, key: string) => {
      responseHeaders[key] = value;
    });
    return responseHeaders;
  }
  if (requestHeaders.get('accept') === 'application/octet-stream' || opts.responseType == 'arraybuffer') {
    return Buffer.from(await response.arrayBuffer());
  } else if (opts.responseType == 'blob') {
    return await response.blob();
  } else if (opts.responseType == 'response') {
    return response;
  } else {
    const text = await response.text();
    try {
      return text ? JSON.parse(text) : null;
    } catch (e) {
      const message = e instanceof Error ? e.message : String(e);
      throw new Error("Couldn't parse JSON; Error: " + message + 'Response:\n' + text);
    }
  }
}

export function createAuthedFetcher(getAuthToken: GetAuthToken, fetcher: FetcherFunc): FetcherFunc {
  return async function (opts: FetcherOpts) {
    for (let i = 1; i >= 0; --i) {
      let token: string;
      try {
        token = await getAuthToken();
      } catch (e) {
        if (e instanceof FailedToFetch) {
          // Throw a FailedToFetch specific to this request.
          throw new FailedToFetch(opts.method, getUrl('', opts.path, opts.params));
        } else {
          throw e;
        }
      }

      opts = {...opts};
      opts.headers = opts.headers ? [...opts.headers.filter(x => x[0].toLowerCase() != 'authorization')] : [];
      opts.headers.push(['Authorization', 'Bearer ' + token]);
      try {
        return await fetcher(opts);
      } catch (e) {
        if (e instanceof BadStatusResponse && e.status == 401 && i > 0) {
          // If this was a 401 (Unauthorized) and we have retries left, then try again. It may have been a
          // dormant request whose token expired, but whose user still is logged in.
          console.warn(
            'Request failed with 401; retrying.',
            opts.method,
            opts.path,
            serializeParams(opts.params ?? []),
          );
        } else {
          throw e;
        }
      }
    }
  };
}

export const acceptSingleRow: [string, string] = ['Accept', 'application/vnd.pgrst.object+json'];
