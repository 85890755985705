import {defaultMemoize} from 'reselect';
import {ClockI} from '../Clock';
import {getCropPeriod} from '../crop-period';
import {I18nFunction, I18nSimpleKey} from '../i18n/i18n';
import {Field, Harvest, HarvestCrop, HarvestYear, VegetationStage} from '../models/interfaces';
import {IndexedCrops} from '../redux/reducers/crops';
import {parseDate} from '../util/date-util';
import {PostgrestQuery, PostgrestQueryAnd} from '../util/postgrest-query';

const PerennialCrop: HarvestCrop[] = [
  'grapes',
  'apples',
  'pears',
  'sugar-cane',
  'olives',
  'walnuts',
  'mangos',
  'grassland',
  'peaches',
  'apricots',
  'nectarines',
  'cherries',
];

function isPerennial(crop_id: string): boolean {
  return PerennialCrop.some(x => crop_id?.startsWith(x));
}
// A function to determine the approximate season of a harvest:
// * true means this harvest is winter-only (e.g. crop=wheat-winter, crop=corn-grain-first-crop)
// * false means this harvest is spring-only (e.g. crop=wheat-spring, crop=corn-grain-second-crop, harvest_crop=vegetables)
// * null means that the approximate season could not be determined, or it doesn't fall into a winter/spring cycle
//   e.g.: harvest_crop=wheat (without a crop), harvest_crop=grapes.
export function isWinterLike(crop_id: null | string): null | boolean {
  if (!crop_id) {
    return null;
  }
  if (crop_id.endsWith('-winter') || crop_id.endsWith('-first-crop')) {
    return true;
  }
  if (crop_id.endsWith('-spring') || crop_id.endsWith('-second-crop')) {
    return false;
  }
  if (isPerennial(crop_id)) {
    return null;
  }

  return true;
}

export function compareHarvestsChronologically<T extends Pick<Harvest, 'crop_id' | 'harvest_year'>>(
  a: T,
  b: T,
): number {
  if (!a.harvest_year && !b.harvest_year) {
    return 0;
  }
  if (!a.harvest_year) {
    return 1;
  }
  if (!b.harvest_year) {
    return -1;
  }
  if (Number(a.harvest_year) < Number(b.harvest_year)) {
    return 1;
  } else if (Number(a.harvest_year) > Number(b.harvest_year)) {
    return -1;
  } else {
    return (isWinterLike(b.crop_id) ? 1 : 0) - (isWinterLike(a.crop_id) ? 1 : 0);
  }
}

export function isActiveHarvest(
  todaysDateUtc: string,
  countryGroups: string[],
  field: Field,
  harvest: Harvest,
): boolean {
  const d = parseDate(todaysDateUtc),
    location = field.field_location;
  if (!harvest.harvest_year || !d) {
    return false;
  }

  if (PerennialCrop.includes(harvest.crop_id as any)) {
    // TODO(savv): consider special casing perennials, or at least making this work for SH.
    return String(d.getUTCFullYear()) == harvest.harvest_year;
  }

  const cropPeriod = location && getCropPeriod(location, countryGroups, harvest.crop_id);
  if (!cropPeriod) {
    return harvest.harvest_year == String(d.getUTCFullYear());
  }

  let [startDoy, endDoy] = cropPeriod;
  const fallowDays = endDoy > startDoy ? 365 - (endDoy - startDoy + 1) : startDoy - endDoy - 1;

  // Extend the crop period by a third in each direction.
  startDoy -= fallowDays / 3;
  endDoy += fallowDays / 3;

  const startHarvestYear = Number(harvest.harvest_year) - (startDoy > endDoy ? 1 : 0);
  const start = parseDate(startHarvestYear + '-01-01');
  const end = parseDate(harvest.harvest_year + '-01-01');
  start?.setUTCDate(startDoy);
  end?.setUTCDate(endDoy);
  return !!start && !!end && start.getTime() <= d.getTime() && d.getTime() <= end.getTime();
}

export function getActiveHarvest(
  todaysDateUtc: string,
  countryGroups: string[],
  field: Field,
  harvests: undefined | null | Harvest[],
): null | Harvest {
  if (!harvests) {
    return null;
  }

  let active: null | Harvest = null;
  for (const harvest of harvests) {
    if (!isActiveHarvest(todaysDateUtc, countryGroups, field, harvest)) {
      continue;
    }

    if (!active || compareHarvestsChronologically(active, harvest) == 1) {
      active = harvest;
    }
  }

  return active;
}

export function getLatestHarvest<T extends Pick<Harvest, 'crop_id' | 'harvest_year'>>(
  harvests: undefined | null | T[],
): null | T {
  if (!harvests) {
    return null;
  }

  let latest: null | T = null;
  for (const harvest of harvests) {
    if (!latest || compareHarvestsChronologically(latest, harvest) == 1) {
      latest = harvest;
    }
  }

  return latest;
}

// TODO(savv): Replace this with a column on data.crop.
export function getCropIdAggregationKey(crop_id: string): string {
  if (crop_id.startsWith('grapes') || crop_id.startsWith('vin-')) {
    return 'grapes';
  }

  return crop_id;
}

export const HarvestCropGroup = [
  'cereals',
  'corn',
  'oilseeds',
  'root-crops',
  'soybeans',
  'grapes',
  'orchards',
  'grassland',
  'pulses',
  'vegetables',
  'remaining-crops',
  'unknown',
] as const;
export type HarvestCropGroup = (typeof HarvestCropGroup)[number];

export const Legumes: HarvestCrop[] = ['peas', 'beans', 'lentils'];

export const Cereals: HarvestCrop[] = [
  'wheat',
  'wheat-hard',
  'barley',
  'barley-malting',
  'mixed-cereal',
  'rye',
  'spelt',
  'oats',
  'triticale',
  'rice',
  'millet',
  'sorghum',
  'sorghum-silage',
  'corn-silage',
  'corn-grain',
  'corn-seeds',
];

export const Sunflower: HarvestCrop[] = ['sunflower', 'sunflower-seeds'];

export const Fruits: HarvestCrop[] = [
  'apples',
  'apricots',
  'cherries',
  'grapes',
  'mangos',
  'nectarines',
  'olives',
  'peaches',
  'pears',
];

export function getVegStageForCropId(
  crops: IndexedCrops,
  t: I18nFunction,
  crop_id: null | undefined | string,
): [VegetationStage, string][] {
  const OrderedVegStages = [
    'germination',
    'leaf-development',
    'side-shoot',
    'vegetative-growth',
    'development',
    'inflorescence',
    'flowering',
    'fruit-development',
    'ripening',
    'senescence',
    'harvest',
  ] as const;
  let stages: [VegetationStage, I18nSimpleKey][] = OrderedVegStages.map(x => [x, x]);

  const family = crop_id ? crops[crop_id]?.harvest_crop : null;
  if (family == 'rapeseed') {
    stages = [
      ['germination', 'germination-rapeseed'],
      ['leaf-development', 'leaf-development-rapeseed'],
      ['vegetative-growth', 'vegetative-growth-rapeseed'],
      ['inflorescence', 'inflorescence-rapeseed'],
      ['flowering', 'flowering-rapeseed'],
      ['fruit-development', 'fruit-development-rapeseed'],
      ['ripening', 'ripening-rapeseed'],
    ];
  } else if (family == 'corn-grain' || family == 'corn-seeds' || family == 'corn-silage') {
    stages = [
      ['germination', 'germination-corn'],
      ['leaf-development', 'leaf-development-corn'],
      ['vegetative-growth', 'vegetative-growth-corn'],
      ['inflorescence', 'inflorescence-corn'],
      ['flowering', 'flowering-corn'],
      ['fruit-development', 'fruit-development-corn'],
      ['ripening', 'ripening-corn'],
    ];
  } else if (family == 'sorghum' || family == 'sorghum-silage') {
    stages = [
      ['germination', 'germination-sorghum'],
      ['leaf-development', 'leaf-development-sorghum'],
      ['side-shoot', 'side-shoot-sorghum'],
      ['vegetative-growth', 'vegetative-growth-sorghum'],
      ['development', 'development-sorghum'],
      ['inflorescence', 'inflorescence-sorghum'],
      ['flowering', 'flowering-sorghum'],
      ['fruit-development', 'fruit-development-sorghum'],
      ['ripening', 'ripening-sorghum'],
      ['senescence', 'senescence-sorghum'],
    ];
  } else if (family == 'sunflower' || family == 'sunflower-seeds') {
    stages = [
      ['germination', 'germination-sunflower'],
      ['leaf-development', 'leaf-development-sunflower'],
      ['inflorescence', 'inflorescence-sunflower'],
      ['flowering', 'flowering-sunflower'],
      ['fruit-development', 'fruit-development-sunflower'],
      ['ripening', 'ripening-sunflower'],
      ['senescence', 'senescence-sunflower'],
    ];
  } else if (family == 'soybeans') {
    stages = [
      ['germination', 'germination-soybeans'],
      ['leaf-development', 'leaf-development-soybeans'],
      ['vegetative-growth', 'vegetative-growth-soybeans'],
      ['flowering', 'flowering-soybeans'],
      ['fruit-development', 'fruit-development-soybeans'],
      ['ripening', 'ripening-soybeans'],
      ['senescence', 'senescence-soybeans'],
    ];
  } else if (family == 'sugar-beet') {
    stages = [
      ['germination', 'germination-sugar-beet'],
      ['leaf-development', 'leaf-development-sugar-beet'],
      ['vegetative-growth', 'vegetative-growth-sugar-beet'],
      ['development', 'development-sugar-beet'],
    ];
  } else if (family == 'grapes') {
    stages = [
      ['germination', 'germination-grapes'],
      ['leaf-development', 'leaf-development-grapes'],
      ['inflorescence', 'inflorescence-grapes'],
      ['flowering', 'flowering-grapes'],
      ['fruit-development', 'fruit-development-grapes'],
      ['ripening', 'ripening-grapes'],
      ['senescence', 'senescence-grapes'],
    ];
  } else if (Fruits.includes(family as HarvestCrop)) {
    stages = [
      ['germination', 'germination-fruits'],
      ['leaf-development', 'leaf-development-fruits'],
      ['vegetative-growth', 'vegetative-growth-fruits'],
      ['inflorescence', 'inflorescence-fruits'],
      ['flowering', 'flowering-fruits'],
      ['fruit-development', 'fruit-development-fruits'],
      ['ripening', 'ripening-fruits'],
      ['senescence', 'senescence-fruits'],
    ];
  } else if (Legumes.includes(family as HarvestCrop)) {
    stages = [
      ['germination', 'germination-legumes'],
      ['leaf-development', 'leaf-development-legumes'],
      ['vegetative-growth', 'vegetative-growth-legumes'],
      ['inflorescence', 'inflorescence-legumes'],
      ['flowering', 'flowering-legumes'],
      ['fruit-development', 'fruit-development-legumes'],
      ['ripening', 'ripening-legumes'],
    ];
  } else if (Cereals.includes(family as HarvestCrop)) {
    // NOTE: some cereals take precedence above, like sorghum and corn.
    stages = [
      ['germination', 'germination-cereals'],
      ['leaf-development', 'leaf-development-cereals'],
      ['side-shoot', 'side-shoot-cereals'],
      ['vegetative-growth', 'vegetative-growth-cereals'],
      ['development', 'development-cereals'],
      ['inflorescence', 'inflorescence-cereals'],
      ['flowering', 'flowering-cereals'],
      ['fruit-development', 'fruit-development-cereals'],
      ['ripening', 'ripening-cereals'],
    ];
  }

  return stages.map(([k, msg]) => [k, t(msg)]);
}

export const getSelectableHarvestYears = defaultMemoize((clock: ClockI): HarvestYear[] => {
  const years: number[] = [];
  const currentYear = new Date(clock.now()).getUTCFullYear();
  for (let year = currentYear; year < currentYear + 5; ++year) {
    years.push(year);
  }
  for (let year = currentYear - 1; year >= currentYear - 10; --year) {
    years.push(year);
  }

  return years.map(x => String(x) as HarvestYear).filter(x => HarvestYear.includes(x));
});

export const getFarmHarvestsByYearFilter = (
  harvestYear: string | null | undefined,
  farmId: string | null | undefined,
): null | PostgrestQuery => {
  const farmFilter = getFarmHarvestFilter(farmId);
  const harvestYearFilter = getHarvestYearFilter(harvestYear);
  if (!farmFilter || !harvestYearFilter) {
    return null;
  }
  return {
    and: [harvestYearFilter, ...farmFilter.and],
  };
};

export function getFarmHarvestFilter(farmId: null | undefined): null;
export function getFarmHarvestFilter(farmId: string): PostgrestQueryAnd;
export function getFarmHarvestFilter(farmId: string | null | undefined): null | PostgrestQueryAnd;
export function getFarmHarvestFilter(farmId: string | null | undefined): null | PostgrestQueryAnd {
  if (!farmId) {
    return null;
  }
  return {
    and: [
      {column: 'farm_id', operator: 'eq', value: farmId},
      {column: 'field_id', operator: 'is', value: null},
    ],
  };
}

export const getHarvestYearFilter = (harvestYear: string | null | undefined): null | PostgrestQuery => {
  if (!harvestYear) {
    return null;
  }
  return {column: 'harvest_year', operator: 'eq', value: harvestYear};
};

// TODO(seb): Some of should eventually become HarvestCrop entries, see Fruits above.
//  For the rest, we should re-consider having `isFruit` make use of `getBaseCrop()`.
const cropIdsUsingSubplots: string[] = [
  'citrus',
  'clementines',
  'cocoa',
  'coffee',
  'kiwi',
  'mandarins',
  'nashi-pear',
  'oranges',
  'orchard',
  'other-fruits',
  'peach-blood',
  'peach-flat',
  'peaches-pre-prod',
  'plum',
  'walnuts',
  'peanuts',
];

export function usesSubplots(crop_id: string | undefined | null): boolean {
  return isGrapes(crop_id) || isFruits(crop_id) || cropIdsUsingSubplots.includes(crop_id ?? '');
}

export function isFruits(crop_id: null | undefined | string): boolean {
  return (Fruits as String[]).includes(crop_id ?? '') || Fruits.some(f => crop_id?.startsWith(f + '-'));
}

export function isGrapes(crop_id: null | undefined | string): boolean {
  return crop_id?.startsWith('grapes') || crop_id?.startsWith('vin-') || crop_id?.startsWith('vignes-') || false;
}
