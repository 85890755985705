import fastDeepEqual from '../fast-deep-equal';
import {isObjEmpty} from './obj-util';

export function dedupeMetadata(
  a: {metadata: any},
  b: {metadata: any},
  deduplicatedId: string,
  changes: Partial<{metadata: any}>,
) {
  let newMetadata: any = {};
  if (b.metadata == null || fastDeepEqual(a.metadata, b.metadata)) {
    newMetadata = a.metadata ?? {};
  } else if (typeof a.metadata == 'object' && typeof b.metadata == 'object') {
    newMetadata = {...a.metadata, ...b.metadata};
  }

  newMetadata.deduplicatedIds = [
    ...(changes.metadata?.deduplicatedIds ?? []),
    ...(newMetadata.deduplicatedIds ?? []),
    deduplicatedId,
  ];
  changes.metadata = newMetadata;
}

export function coalesceObjects<Y extends Record<string, any>, X extends Y>(
  changes: Partial<Y>,
  a: X,
  b: X,
  cols: null | (keyof Y)[],
  bestEffort: boolean = false,
): boolean {
  if (cols == null) {
    cols = Object.keys(b) as (keyof Y)[];
  }
  for (const k of cols) {
    const aVal = a[k],
      bVal = b[k];
    if (aVal != null && bVal != null && !fastDeepEqual(aVal, bVal)) {
      if (bestEffort) {
        if (a['added_on'] && b['added_on'] && b['added_on'] > a['added_on']) {
          changes[k] = bVal;
        }
      } else {
        return false;
      }
    }
    if (aVal == null && bVal != null) {
      changes[k] = bVal;
    }
  }
  return true;
}

export function dedupeCustomColumns<T extends Record<string, any>, E extends {custom_columns: null | T}>(
  a: E,
  b: E,
  changes: Partial<{custom_columns: null | T}>,
  bestEffort: boolean,
): boolean {
  if (b.custom_columns == null || fastDeepEqual(a.custom_columns, b.custom_columns)) {
    return true;
  }

  const customColChanges = {};
  if (a.custom_columns && !coalesceObjects(customColChanges, a.custom_columns, b.custom_columns, null, bestEffort)) {
    return false;
  }
  if (isObjEmpty(customColChanges)) {
    return true;
  }
  changes.custom_columns = {...changes.custom_columns, ...a.custom_columns!, ...customColChanges};

  return true;
}
