import React, {ChangeEventHandler} from 'react';
import {FormyComponent, FormyComponentProps} from '../../../src/Formy/FormyComponent';
import {FormyUnitBase} from '../../../src/Formy/FormyTextBase';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {ValueUnit} from '../../../src/models/types';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';

interface FormyTextStrProps<F, Fk extends keyof F> extends FormyComponentProps<F, Fk> {
  type?: 'text' | 'email';
  label?: I18nSimpleKey;
}

export class FormyTextStr<F extends {[P in Fk]: null | string}, Fk extends keyof F> extends FormyComponent<
  F,
  Fk,
  FormyTextStrProps<F, Fk>
> {
  static contextType = ApisContext;
  context!: Apis;

  render() {
    let className = 'formy-item-style';
    if (this.error) {
      className += ' formy-item-error';
    }
    return (
      <input
        data-testid={`FormyTextStr-${String(this.props.field)}`}
        className={className}
        placeholder={this.props.label && this.context.t(this.props.label)}
        type={this.props.type ?? 'text'}
        disabled={this.mode === 'view'}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        value={this.value ?? ''}
      />
    );
  }

  onChange: ChangeEventHandler<HTMLInputElement> = x => this.handleChange(x.target.value as any);
}

export class FormyUnit<U extends ValueUnit, F extends {[P in Fk]: null | U}, Fk extends keyof F> extends FormyUnitBase<
  U,
  F,
  Fk
> {
  render() {
    let className = 'formy-item-style';
    if (this.error) {
      className += ' formy-item-error';
    }
    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    return (
      <span style={this.props.inline ? {...this.props.style, display: 'flex'} : this.props.style}>
        <input
          data-testid={`FormyUnit-${String(this.props.field)}`}
          className={className}
          style={this.props.inline ? {minWidth: '150px'} : {}}
          disabled={this.mode === 'view'}
          onChange={x => this.onChangeText(x.target.value)}
          onBlur={this.handleBlur}
          value={this.state.valueStr ?? ''}
          placeholder={this.props.label && this.props.formy.t(this.props.label)}
        />
        {this.props.units.length > 1 && (
          <select
            id="country-select"
            className="crop-mon-selector"
            value={this.value?.unit}
            style={this.props.inline ? {width: '100%'} : {}}
            onChange={x => this.onChangeUnit(x.target.value as any)}>
            {this.props.units.map(v => (
              <option value={v} key={v}>
                {this.props.formy.t(v)}
              </option>
            ))}
          </select>
        )}
      </span>
    );
  }
}
