import {CheckCircleOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';
import {saveAs} from 'file-saver';
import React from 'react';
import {useSelector} from 'react-redux';
import {TelepacReportPackage} from '../../../src/report/report-types';
import {getCurUserEmail} from '../../../src/selectors/dbMeta';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {TelepacReport} from '../report/TelepacReport';
import {reportErr} from '../util/err';

type TelepacImportSummaryProps = {
  telepacData: TelepacReportPackage;
};

const headerStyle = {padding: '1em'};

export function TelepacImportSummary(props: TelepacImportSummaryProps) {
  const apis = useApis();
  const {t, clock} = apis;
  const email = useSelector(getCurUserEmail);

  async function downloadTelepacReport() {
    message.loading({
      content: t('Downloading') + '...',
      duration: 0,
      key: 'download',
      icon: <SpinningDots size={16} />,
    });
    const blob = await generateTelepacReport(apis, props.telepacData);
    if (!blob) {
      message.error({content: t('Error'), duration: 5, key: 'download'});
      return false;
    }
    saveAs(blob, props.telepacData.filename);
    message.success({content: t('Done'), duration: 2, key: 'download'});
    return true;
  }

  if (props.telepacData) {
    return (
      <div>
        <div className="no-print" style={headerStyle}>
          <p>{t({type: 'TelepacReportEmail', email})}</p>
          <Button onClick={downloadTelepacReport}>{t('Download')}</Button>
        </div>
        <TelepacReport {...props.telepacData} clock={clock} t={t} />
      </div>
    );
  } else {
    return (
      <h1 className="import-done">
        {t('Done')} <CheckCircleOutlined />
      </h1>
    );
  }
}

export async function generateTelepacReport(
  apis: Apis,
  telepacData: TelepacReportPackage,
  emails?: string[],
): Promise<Blob | null> {
  try {
    const res = await apis.authedFetcher({
      method: 'POST',
      path: 'api2/standalone-report',
      params: [['locale', apis.locale], ...(emails ?? []).map(x => ['email', x] as [string, string])],
      json_body: telepacData,
      responseType: 'response',
    });
    return await res.blob();
  } catch (err) {
    reportErr(err);
    return null;
  }
}
