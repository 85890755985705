import {defaultMemoize} from 'reselect';
import {LngLat} from '../geo';
import {GrapeVarieties} from '../grape-variety';
import {I18nFunction, I18nParametric, I18nSimpleKey} from '../i18n/i18n';
import {AreaValue, Crop, Farm, Field, Harvest, HarvestCrop, Policy, Sample, UserEntity} from '../models/interfaces';
import {IndexedCrops} from '../redux/reducers/crops';
import {getBaseCrop} from '../selectors/crops';
import {HarvestKey} from '../selectors/harvest-key';
import {remove} from '../util/arr-util';
import {VisitWithClaim, extractCropIdsFromVisitWithClaim, extractHarvestYearFromVisitWithClaim} from '../util/claims';
import {formatDate} from './date';

function getPredictedCropName(crop_id: string) {
  crop_id = crop_id.replace(/^grapes-/, '');
  return crop_id.replace(/-/g, ' ');
}

// Returns a translated description for the given crop_id; in order of preference:
// * if a msg exists for that crop_id, then it will be used
// * if it's possible to infer a crop + season combo, then that will be used
// * else, we look for a translation in the crop index
// * otherwise we return the id itself
export function cropDesc(t: I18nFunction, crops: IndexedCrops, crop_id: null | undefined | string): string {
  if (crop_id) {
    const cropStr = t(crop_id as I18nSimpleKey);
    if (cropStr) {
      return cropStr;
    }

    const m = crop_id.match(/^(.*)-(winter|spring|first-crop|second-crop|cover-crop)$/);
    if (m) {
      const cropStr = t(m[1] as I18nSimpleKey);
      const seasonStr = t(m[2] as I18nSimpleKey);
      // TODO(savv): consider omitting the season, if it's unique for that user. That is, we should only show
      //  Corn (grain) for user's in France, not Corn (grain), Spring.
      if (cropStr && seasonStr) {
        const cropsByBase = getCropsByBase(crops);
        const baseCrop = getBaseCrop(crops, crop_id);
        if (!baseCrop || (baseCrop && (cropsByBase[baseCrop]?.length ?? 0) > 1)) {
          return `${cropStr}, ${seasonStr}`;
        } else {
          return cropStr;
        }
      } else {
        return getPredictedCropName(crop_id);
      }
    } else if (crops[crop_id]?.name) {
      return crops[crop_id]!.name!;
    } else {
      // TODO(savv): Given that all crop_ids start with a base crop type, we could try to return something nicer here -
      //  e.g. grapes-vdp-vin-de-montreux could be translated, in French, as "Vins Vdp De Montreux".
      return getPredictedCropName(crop_id);
    }
  }

  return t('unknown');
}

export interface PartialHarvestDesc {
  crop_id: Harvest['crop_id'];
  harvest_year: Harvest['harvest_year'];
  variety?: Harvest['variety'];
  harvest_area?: Harvest['harvest_area'];
  comments?: Harvest['comments'];
  external_harvest_id?: Harvest['external_harvest_id'];
  organic?: Harvest['organic'];
  irrigated?: Harvest['irrigated'];
}

// Wheat, Spring 2020, 639134
export function harvestDesc(
  t: I18nFunction,
  crops: IndexedCrops,
  harvest: PartialHarvestDesc,
  // TODO(savv): use location and countryGroups to determine whether this is a multi-year crop, such as for winter crops
  //  in Europe.
  location: LngLat | null,
  countryGroups: string[],
  policy?: Policy | null,
  detailed?: boolean,
): string {
  if (!harvest) {
    return '(null harvest)';
  }

  let s = cropDesc(t, crops, harvest.crop_id);
  s += harvest.harvest_year ? ' ' + t(harvest.harvest_year) : '';

  const detailedDesc: string[] = [];
  if (policy?.policy_number) {
    detailedDesc.push(policy.policy_number);
  }
  if (detailed) {
    if (harvest.variety) {
      detailedDesc.push(harvest.variety);
    }
    detailedDesc.push(...irrigatedOrganicDesc(t, harvest.irrigated ?? null, harvest.organic ?? null));
    if (harvest.harvest_area) {
      detailedDesc.push(t('HarvestArea') + ': ' + t({type: 'ValueUnit', ...harvest.harvest_area}));
    }
    if (harvest.external_harvest_id && getBaseCrop(crops, harvest.crop_id) == 'grapes') {
      detailedDesc.push(harvest.external_harvest_id);
    }
  }

  if (detailedDesc.length) {
    s += ' (' + detailedDesc.join(', ') + ')';
  }

  return s;
}

// Field "i5,p1" 5.3 ha, 13.0km away, Wheat, Spring 2020, Contract 639134
export function fieldDesc(
  t: I18nFunction,
  crops: IndexedCrops,
  countryGroups: string[],
  field: Pick<Field, 'field_area' | 'field_location' | 'external_field_id'>,
  harvest?: null | PartialHarvestDesc,
  policy?: null | Policy,
  dist_km?: number | null,
  short?: boolean,
  fieldWord?: boolean,
  detailedHarvest?: boolean,
): string {
  if (!field) {
    return '(null field)';
  }

  let descs = [];
  if (field.field_area) {
    descs.push(t({type: 'AreaUnit', ...field.field_area}));
  }
  if (dist_km != null) {
    descs.push(t({type: 'DistKmAway', dist_km: dist_km}));
  }
  if (harvest) {
    descs.push(harvestDesc(t, crops, harvest, field.field_location, countryGroups, policy, detailedHarvest));
  }

  let s = fieldWord == false ? '' : t('Field');
  if (field.external_field_id) {
    s += ` "${field.external_field_id}"`;
  }

  if (short) {
    descs = descs.slice(0, 1);
  }
  if (descs.length) {
    s += ' ' + descs.join(', ');
  }

  return s;
}

export function policyDesc(t: I18nFunction, policy: Pick<Policy, 'policy_number'> | null | undefined): string {
  if (!policy) {
    return '(null policy)';
  }

  const policy_number = policy.policy_number || '-';
  return t({type: 'PolicyDesc', policy_number});
}

export function farmDesc(
  t: I18nFunction,
  farm: Pick<Farm, 'farm_name' | 'address' | 'external_farm_id'> | null | undefined,
): string {
  if (!farm) {
    return '(null farm)';
  }

  const farm_name = farm.farm_name || '-';
  const address = farm.address || '-';
  const external_farm_id = farm.external_farm_id ?? undefined;
  return t(
    external_farm_id
      ? {type: 'FarmDesc_reference', farm_name, address, external_farm_id}
      : {type: 'FarmDesc', farm_name, address},
  );
}

// Shorted Farm Description, only Name + ExternalId
export function farmDescShort(farm: Pick<Farm, 'farm_name' | 'external_farm_id'>): string {
  if (!farm) {
    return '(null farm)';
  }

  const {farm_name, external_farm_id} = farm;
  const optionalExternal = external_farm_id && external_farm_id !== farm_name ? ' (' + external_farm_id + ')' : '';
  return farm_name + optionalExternal;
}

export function visitDesc(t: I18nFunction, crops: IndexedCrops, visit: VisitWithClaim, farm: Farm): string {
  if (!visit) {
    return '(null visit)';
  }

  const parts = [farmDesc(t, farm)];
  parts.push(`${t('HarvestYear')}: ${extractHarvestYearFromVisitWithClaim(visit)} (${t(visit.visit_type || '-')})`);
  const cropIds = extractCropIdsFromVisitWithClaim(visit);
  if (cropIds.length) {
    parts.push(cropIds.map(x => cropDesc(t, crops, x)).join(', '));
  }
  if (visit.claim?.external_claim_id?.length) {
    parts.push(t('ClaimNumber') + ': ' + visit.claim.external_claim_id);
  }
  return parts.join('\n');
}

export function userEntityDesc(
  email: string | null | undefined,
  userEntities: Record<string, UserEntity> | undefined,
): string {
  const userEntity = email ? userEntities?.[email] : undefined;
  return [
    userEntity?.first_name,
    userEntity?.last_name,
    (userEntity?.first_name || userEntity?.last_name) && email ? '(' + email + ')' : email,
  ]
    .filter(remove.nulls)
    .join(' ');
}

const getCropsByBase = defaultMemoize((crops: IndexedCrops) => {
  const byBase: {[P in HarvestCrop]?: Crop[]} = {};
  for (const crop_id in crops) {
    const crop = crops[crop_id];
    if (!crop?.harvest_crop) {
      continue;
    }
    if (!byBase[crop.harvest_crop]) {
      byBase[crop.harvest_crop] = [];
    }
    byBase[crop.harvest_crop]!.push(crop);
  }

  return byBase;
});

export const grapeVarietyDesc = (variety: string): string => {
  const varietyDetails = GrapeVarieties[variety];
  if (!varietyDetails) {
    return variety;
  }

  return `${variety} ${varietyDetails.synonym ? ` (${varietyDetails.synonym})` : ''}${
    varietyDetails.origin ? ` - ${varietyDetails.origin}` : ''
  }`;
};

export function subplotHarvestDesc(
  t: (k: I18nSimpleKey | I18nParametric) => string,
  harvest: Harvest & {
    numSamples: number | null;
  },
  numSamples: (
    harvest: Harvest & {
      numSamples: number | null;
    },
  ) => string,
) {
  return `${t('HarvestPlotId')}: ${harvest.external_harvest_id ?? '-'}, ${t('HarvestArea')}: ${
    harvest.harvest_area ? t({type: 'AreaUnit', ...harvest.harvest_area}) : '-'
  }\n${numSamples(harvest)}, ${t('GrapeVariety')}: ${harvest.variety ?? '-'}`;
}

function irrigatedOrganicDesc(t: I18nFunction, irrigated: boolean | null, organic: boolean | null) {
  return [
    organic === null ? null : organic ? t('Organic') : t('NonOrganic'),
    irrigated === null ? null : irrigated ? t('Irrigated') : t('NonIrrigated'),
  ].filter(remove.nulls);
}

// Currently used in visit mode as users couldn't differentiate between harvests of the same crops and needed more info.
// Example:
// Wheat (Soft), Winter, Variety1
// 4.59 ha (Organic, Non-Irrigated)
export function cropDescWithVarietyOrganicIrrigated(
  t: I18nFunction,
  crops: Readonly<IndexedCrops>,
  crop_id: null | undefined | string,
  variety: string | null,
  area: AreaValue | null,
  irrigated: boolean | null,
  organic: boolean | null,
): string {
  const irrigatedOrganic = irrigatedOrganicDesc(t, irrigated, organic);
  return (
    cropDesc(t, crops, crop_id) +
    (variety ? ` ${variety} ` : '') +
    '\n' +
    (area ? t({type: 'AreaUnit', ...area}) : '- ha') +
    (irrigatedOrganic.length ? ' (' + irrigatedOrganic.join(', ') + ')' : '')
  );
}

// Currently used in visit mode as users couldn't differentiate between harvests of the same crops and needed more info.
// Example:
// Wheat (Soft), Winter, Variety1
// 4.59 ha (Organic, Non-Irrigated)
export function harvestKeyDesc(
  t: I18nFunction,
  crops: Readonly<IndexedCrops>,
  harvestKey: HarvestKey,
  withYear = false,
): string {
  const description = [
    harvestKey.organic === null ? null : harvestKey.organic ? t('Organic') : t('NonOrganic'),
    harvestKey.irrigated === null ? null : harvestKey.irrigated ? t('Irrigated') : t('NonIrrigated'),
  ].filter(remove.nulls);

  return (
    cropDesc(t, crops, harvestKey.crop_id) +
    (withYear ? ` ${harvestKey.harvest_year}` : '') +
    (harvestKey.variety ? ` ${harvestKey.variety}` : '') +
    (description.length ? ' (' + description.join(', ') + ')' : '')
  );
}

// Currently used in the visit report (Groupama only)
export function sampleComment(t: I18nFunction, sample: Sample, includeDetails: boolean): string {
  if (!includeDetails) {
    return sample.comments ?? '';
  }

  const commentSampleFieldMapping: {
    key: keyof Sample;
    label: I18nSimpleKey;
    format: (v: Sample[keyof Sample]) => string;
  }[] = [
    {key: 'sowing_date', label: 'SowingDate', format: value => formatDate(t, value)},
    {key: 'sowing_density', label: 'SowingDensity', format: value => t({type: 'ValueUnit', ...value})},
    {key: 'insecticide', label: 'InsecticideApplied', format: value => t(value ? 'Yes' : 'No')},
    {key: 'herbicide', label: 'HerbicideApplied', format: value => t(value ? 'Yes' : 'No')},
    {key: 'fungicide', label: 'FungicideApplied', format: value => t(value ? 'Yes' : 'No')},
    {key: 'vegetation_stage', label: 'VegetationStage', format: value => t(value)},
    {key: 'crop_condition', label: 'CropCondition', format: value => t(value)},
    {key: 'comments', label: 'Comments', format: value => value},
  ];
  const sampleComment: string[] = commentSampleFieldMapping.reduce((acc: string[], {key, label, format}) => {
    const value = sample[key];
    if (value !== null) {
      acc.push(`${t(label)}: ${format(value)}`);
    }
    return acc;
  }, []);

  return sampleComment.join(', ');
}
