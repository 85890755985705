import fastDeepEqual from '../fast-deep-equal';
import cmp from './cmp';

export function insertToSorted<T>(element: T, arr: T[], gt: (a: T, b: T) => boolean) {
  arr.splice(locationOf(arr, element, gt), 0, element);
}

export function sorted<T>(x: T[]): T[] {
  const y = [...x];
  y.sort();
  return y;
}

export function locationOf<T>(arr: T[], el: T, gt: (a: T, b: T) => boolean) {
  var m = 0;
  var n = arr.length - 1;
  while (m <= n) {
    var k = (n + m) >> 1;
    if (gt(el, arr[k])) {
      m = k + 1;
    } else if (gt(arr[k], el)) {
      n = k - 1;
    } else {
      return k;
    }
  }

  return m;
}

export function maxNumStr<T extends string>(arr: T[]): null | T {
  let max: null | T = null;
  for (const x of arr) {
    if (max == null || cmp(max, x) == 1) {
      max = x;
    }
  }

  return max;
}

export const aggregate: {
  // Will return the sum of all non-null values, or null if all values are null.
  sum: [(agg: number | null, val: number | null) => number | null, number | null];
} = {
  sum: [(agg: number | null, val: number | null) => (val == null ? agg : (agg ?? 0) + val), null],
};

export const remove: {
  nulls: <T>(x: T | undefined | null) => x is T;
  falsy: <T>(x: T | undefined | null) => x is T;
  duplicatesWith: <T>(
    a: (T | undefined | null)[] | Set<T | undefined | null> | null | undefined,
  ) => (x: T | undefined | null) => boolean;
} = {
  nulls: <T>(x: T | undefined | null): x is T => x != null,
  falsy: <T>(x: T | undefined | null): x is T => !!x,
  duplicatesWith: a => {
    const set = a instanceof Set ? a : new Set(a);
    return x => !set.has(x);
  },
};

export function filterNulls<T>(arr: (null | undefined | T)[]): T[] {
  return arr.filter(remove.nulls);
}

export function filterFalsy<T>(arr: (null | undefined | T)[]): T[] {
  return arr.filter(remove.falsy);
}

export function filterArr<T>(arr: any[], fn: (x: any) => x is T): T[] {
  return arr.filter(fn);
}

export function andPredicate<A, B>(fnA: (x: any) => x is A, fnB: (x: any) => x is B): (x: any) => x is A & B {
  return (x): x is A & B => fnA(x) && fnB(x);
}

export function isSubsetOf<T>(subset: null | Set<T>, set: null | Set<T>) {
  if (!subset) {
    return true;
  }
  if (!set) {
    return false;
  }

  for (let elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export function setEquality<T>(a: null | Set<T>, b: null | Set<T>) {
  return isSubsetOf(a, b) && isSubsetOf(b, a);
}

export function unique<T extends string | number>(arr: T[], sort?: 'asc' | 'desc'): T[] {
  const res = Array.from(new Set(arr));
  if (sort) {
    res.sort((a, b) => cmp(a as string, b as string) * (sort == 'asc' ? 1 : -1));
  }

  return res;
}

export function uniqueByFastDeepEqual<T>(array: T[]): T[] {
  return array.reduce((uniqueItems, currentItem) => {
    if (!uniqueItems.some(item => fastDeepEqual(item, currentItem))) {
      uniqueItems.push(currentItem);
    }
    return uniqueItems;
  }, [] as T[]);
}

export function getDistinctByKey<T>(values: T[], keyFn: (v: T) => string): T[] {
  const result: T[] = [];
  const uniqueCategories = new Set<string>();
  for (const obj of values) {
    const key = keyFn(obj);
    if (!uniqueCategories.has(key)) {
      uniqueCategories.add(key);
      result.push(obj);
    }
  }
  return result;
}

export function sumByKey<T extends {[P in K]: null | number | string}, K extends keyof T>(
  data: T[],
  groupingColumn: K,
  valueColumn: K,
): Map<T[K], number> {
  const groupedDataMap = new Map<T[K], number>();
  for (const row of data) {
    const currentSum = groupedDataMap.get(row[groupingColumn]) || 0;
    let currentValue = row[valueColumn] || 0;
    groupedDataMap.set(row[groupingColumn], currentSum + currentValue);
  }
  return groupedDataMap;
}

// Split the input values into sub-arrays with no more than chunkLength elements each.
export function chunk<T>(values: T[], chunkLength: number): T[][] {
  const numArrays = Math.ceil(values.length / chunkLength);
  return Object.values(groupBy(values, (_, i) => i % numArrays));
}

// Group the input values by the key returned by the key function.
export function groupBy<T, K extends string | number | symbol>(
  values: T[],
  key: (v: T, i: number) => K,
): Record<K, T[]> {
  return values.reduce(
    (acc, v, i) => {
      const k = key(v, i);
      if (!acc[k]) {
        acc[k] = [];
      }
      acc[k].push(v);
      return acc;
    },
    {} as Record<K, T[]>,
  );
}

// Group the input values by the key returned by the key function
// and then aggregate the grouped values using the agg function.
export function aggregateBy<T, K extends string | number | symbol, R>(
  values: T[],
  key: (v: T, i: number) => K,
  agg: (values: T[]) => R,
): Record<K, R> {
  return Object.fromEntries(
    (Object.entries(groupBy<T, K>(values, key)) as [K, T[]][]).map(([k, values]) => {
      return [k, agg(values)];
    }),
  ) as Record<K, R>;
}
