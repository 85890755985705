import {Popover} from 'antd';
import React, {useMemo} from 'react';
import './EmailList.css';
import {userEntityDesc} from '../../../src/text/desc';
import {useUserEntitiesByEmail} from '../queries/options';
import './EmailList.css';

interface EmailListProps {
  emails: string[];
}

interface EmailParts {
  localPart: string;
  domain: string;
  email: string;
}

// A component to render a list of Emails, shortening the domain part of the email addresses to minimize used space.
// A popover will show all email addresses in full on hover.
export const EmailList: React.FC<EmailListProps> = ({emails}) => {
  const {data: userEntitiesByEmail, isFetching} = useUserEntitiesByEmail();
  const emailParts: EmailParts[] = useMemo(
    () => emails.map(email => getParts(userEntityDesc(email, userEntitiesByEmail))),
    [emails, userEntitiesByEmail],
  );
  const popoverContent = useMemo(
    () => (
      <div>
        {emailParts.map(({localPart, domain, email}) => {
          return (
            <div key={email}>
              <span className="email-list-local">{localPart}</span>
              <span className="email-list-domain">@{domain}</span>
            </div>
          );
        })}
      </div>
    ),
    [emailParts],
  );
  if (!emails || emails.length === 0 || isFetching) {
    return null;
  }
  return (
    <Popover content={popoverContent}>
      <span className="email-list">
        {emailParts.map(({localPart, domain, email}) => {
          return (
            <span key={email}>
              <span className="email-list-local">{localPart}</span>
              <span className="email-list-domain">@{domain.substring(0, 3)}...</span>
            </span>
          );
        })}
      </span>
    </Popover>
  );

  function getParts(email: string): EmailParts {
    const [localPart = '', domain = ''] = email.split('@');
    return {localPart, domain, email};
  }
};
