import {CloseOutlined, DeleteOutlined, FormOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, Col, Divider, PageHeader, Row} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {FormyErrors} from '../../../src/Formy';
import {useFormyValue, useSyncFormy} from '../../../src/Formy/hooks';
import {I18nParametric, I18nSimpleKey} from '../../../src/i18n/i18n';
import {ClaimDamageData} from '../../../src/models/data';
import {
  Claim,
  ClaimDamage,
  CoverageType,
  Field,
  HarvestYear,
  Loss,
  LossCause,
  VegetationStage,
  YieldUnit,
} from '../../../src/models/interfaces';
import {OmitDbColumns, Uuid} from '../../../src/models/types';
import {
  deleteClaimDamage,
  insertClaim,
  insertClaimDamage,
  updateClaim,
  updateClaimDamage,
} from '../../../src/redux/actions/db';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {harvestAggregationKeyEq} from '../../../src/selectors/harvest-key';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {fieldDesc, userEntityDesc} from '../../../src/text/desc';
import {remove, unique} from '../../../src/util/arr-util';
import {
  createVisitCandidates,
  extractAssignedTo,
  extractAssignedToEmails,
  extractCropIds,
  extractHarvestYear,
  getUpdatedClaimAssignedTo,
} from '../../../src/util/claims';
import {fetchEntitiesBy, fetchEntity} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import {PostgrestQuery} from '../../../src/util/postgrest-query';
import {Nullable} from '../../../src/util/types';
import {isEmail, isStr} from '../../../src/validator-constraints';
import {FormyBool} from '../Formy/FormyBool';
import {FormyDatePicker} from '../Formy/FormyDatePicker';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {FormyEnum, FormyMultiEnum} from '../Formy/FormyEnum';
import {Label} from '../Formy/FormyLabel';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyTextStr, FormyUnit} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';
import {CropTag, useCropTags} from '../components/CropTags';
import {useUserEntitiesByEmail} from '../queries/options';
import {State} from '../redux';
import {deleteClaim} from '../redux/actions/db';
import {commitMutations} from './commit';

interface LossForm {
  crop_id: ClaimDamage['crop_id'];
  losses: Loss[];
  field_ids: Uuid[];
  vegetation_stage: ClaimDamage['vegetation_stage'];
  organic: ClaimDamage['organic'];
  irrigated: ClaimDamage['irrigated'];
  variety: ClaimDamage['variety'];

  // used only for the web form select to populate the losses: Loss[], which are then saved to DB
  loss_causes: LossCause[];
}

class ClaimForm implements Nullable<OmitDbColumns<Claim>, 'claim_id' | 'farm_id'> {
  claim_id: Uuid | null;
  assigned_to: Claim['assigned_to'];
  comments: Claim['comments'];
  contact_email: Claim['contact_email'];
  contact_first_name: Claim['contact_first_name'];
  contact_last_name: Claim['contact_last_name'];
  contact_phone: Claim['contact_phone'];
  coverage_type: Claim['coverage_type'];
  external_claim_id: Claim['external_claim_id'];
  manager_email: Claim['manager_email'];
  manager_first_name: Claim['manager_first_name'];
  manager_last_name: Claim['manager_last_name'];
  manager_phone: Claim['manager_phone'];
  policy_id: Claim['policy_id'];
  farm_id: Claim['farm_id'] | null;
  status: Claim['status'];
  closed_on: Claim['closed_on'];
  metadata: Claim['metadata'];
  custom_columns: Claim['custom_columns'];
  claimDamage: ClaimDamage[];
  harvest_year: HarvestYear;
  user_assigned_to: string[];
  lossForm: LossForm[];

  // used only for the web form select to populate the lossForm: LossForm[], which is then saved to DB
  crop_ids: string[];

  constructor(
    claim: Claim | undefined,
    claimDamage: ClaimDamage[] | undefined,
    user: {
      email: string;
      name?: string;
      locale?: string;
    },
  ) {
    this.claim_id = claim?.claim_id ?? null;
    this.assigned_to = claim?.assigned_to ?? [];
    this.user_assigned_to = extractAssignedToEmails(claim ?? null, null);
    this.comments = claim?.comments ?? null;
    this.contact_email = claim?.contact_email ?? null;
    this.contact_first_name = claim?.contact_first_name ?? null;
    this.contact_last_name = claim?.contact_last_name ?? null;
    this.contact_phone = claim?.contact_phone ?? null;
    this.coverage_type = claim?.coverage_type ?? null;
    this.external_claim_id = claim?.external_claim_id ?? null;
    this.manager_email = claim ? claim.manager_email : user.email;
    this.manager_first_name = claim ? claim.manager_first_name : user.name?.split(' ')[0] ?? null;
    this.manager_last_name = claim ? claim.manager_last_name : user.name?.split(' ')[1] ?? null;
    this.manager_phone = claim?.manager_phone ?? null;
    this.policy_id = claim?.policy_id ?? null;
    this.claimDamage = claimDamage ?? [];
    this.status = 'created-unassigned';
    this.closed_on = null;
    this.metadata = null;
    this.custom_columns = null;
    this.lossForm = [];

    // TODO(kristjan): policy will most likely be connected to farm (after integration with quotation tool).
    // When that happens, we should default to policy's farm and possibly also crop_ids and harvest_year.
    // It may be possible that one policy will have multiple farms.
    this.farm_id = claim?.farm_id ?? null;
    // get list of crop_ids from claim damages
    this.crop_ids = extractCropIds(claimDamage);
    // all claim damages should have the same harvest_year, so we take the first one
    this.harvest_year = extractHarvestYear(claimDamage);

    // generate single loss form from all ClaimDamage with same crop_id
    for (const cropId of this.crop_ids) {
      const claimDamagesForCrop = claimDamage?.filter(claimDamage => claimDamage.crop_id === cropId) ?? [];
      // TODO(savv): refactor the below to claimDamage.loss, and specify the source (e.g. from the farmer).
      const losses =
        claimDamagesForCrop.map(claimDamage => claimDamage.insurance_loss_estimation).filter(remove.nulls)[0] ?? [];
      this.lossForm.push({
        crop_id: cropId,
        losses: losses,
        field_ids: claimDamagesForCrop.map(claimDamage => claimDamage.field_id).filter(remove.nulls),
        vegetation_stage: claimDamagesForCrop.map(claimDamage => claimDamage.vegetation_stage).filter(remove.nulls)[0],
        irrigated: claimDamagesForCrop.map(claimDamage => claimDamage.irrigated).filter(remove.nulls)[0],
        organic: claimDamagesForCrop.map(claimDamage => claimDamage.organic).filter(remove.nulls)[0],
        variety: claimDamagesForCrop.map(claimDamage => claimDamage.variety).filter(remove.nulls)[0],
        loss_causes: losses.map(loss => loss.loss_cause).filter(remove.nulls),
      });
    }
  }
}

const maxWidthResponsiveHeight = {width: '100%', height: 'inherit'};
const errorColor = {color: '#C23B22'};

function validateClaimForm(x: ClaimForm): FormyErrors<ClaimForm> {
  return {
    farm_id: !x.farm_id || !isStr(x.farm_id),
    contact_email: !isEmail(x.contact_email),
    manager_email: !isEmail(x.manager_email),
  };
}

const createClaimDamage = (
  selectedLoss: LossForm,
  currentClaimId: string,
  fieldId: string | null,
  claimForm: ClaimForm,
): ClaimDamageData => {
  return {
    crop_id: selectedLoss.crop_id,
    claim_id: currentClaimId,
    harvest_id: null,
    field_id: fieldId,
    harvest_year: claimForm.harvest_year,
    vegetation_stage: selectedLoss.vegetation_stage,
    irrigated: selectedLoss.irrigated,
    organic: selectedLoss.organic,
    variety: selectedLoss.variety,
    // TODO(kristjan): refactor the below to claimDamage.loss, and specify the source (e.g. from the insurance).
    insurance_loss_estimation: selectedLoss.losses,
  };
};

export const AddOrEditClaim: React.FC = () => {
  const apis = useApis();
  const location = useLocation();

  const claimId = useMemo(() => new URLSearchParams(location.search).get('claim_id') ?? '', [location]);
  const form = useAsyncMemo(async () => {
    if (!claimId) {
      return new ClaimForm(undefined, undefined, apis.getUser());
    }
    const claim = await fetchEntity(apis.authedFetcher, 'claim', claimId);
    const claimDamage = await fetchEntitiesBy(apis.authedFetcher, 'claim_damage', {
      column: 'claim_id',
      operator: 'eq',
      value: claimId,
    });
    return new ClaimForm(claim, claimDamage, apis.getUser());
  }, [apis, claimId]);
  return form ? <AddOrEditClaimForm claimForm={form} /> : null;
};

function getClaimDamageUpdates(harvest_year: HarvestYear, lossForClaimDamage: LossForm): Partial<ClaimDamageData> {
  return {
    harvest_year: harvest_year,
    irrigated: lossForClaimDamage.irrigated,
    organic: lossForClaimDamage.organic,
    vegetation_stage: lossForClaimDamage.vegetation_stage,
    // TODO(kristjan): refactor the below to claimDamage.loss, and specify the source (e.g. from the insurance).
    insurance_loss_estimation: lossForClaimDamage.losses,
  };
}

const AddOrEditClaimForm: React.FC<{claimForm: ClaimForm}> = ({claimForm}) => {
  const apis = useApis();
  const history = useHistory();
  const {data: userEntitiesByEmail} = useUserEntitiesByEmail();
  const countryGroups: string[] = useSelector(getCountryCodeGroups);
  const allCrops: IndexedCrops = useSelector((state: State) => state.crops.crops);
  const [error, setError] = React.useState<I18nSimpleKey | I18nParametric | null>(null);

  const onSubmit = useCallback(
    async (claimForm: ClaimForm) => {
      const {claimDamage, claim_id, farm_id, lossForm, user_assigned_to, harvest_year, crop_ids, ...claim} = claimForm;

      if (!farm_id) {
        // this shouldn't happen, as formy validation will fail for empty farm_id
        throw new Error('onSubmit must not be called with empty farm_id.');
      }

      claim.assigned_to = getUpdatedClaimAssignedTo(
        claim.assigned_to,
        createVisitCandidates(user_assigned_to, 'assigned'),
      );

      if (claim.status === 'created-unassigned' && extractAssignedTo(claim.assigned_to, ['assigned']).length > 0) {
        claim.status = 'awaiting-adjuster-acceptance';
      }

      const currentClaimId = apis.store.dispatch(
        claim_id ? updateClaim(claim_id, {...claim, farm_id}) : insertClaim(apis, {...claim, farm_id}),
      );

      for (const oldClaimDamage of claimDamage) {
        const lossForClaimDamage = lossForm.find(
          loss =>
            harvestAggregationKeyEq(oldClaimDamage, {...loss, harvest_year: harvest_year}) &&
            ((oldClaimDamage.field_id && loss.field_ids.includes(oldClaimDamage.field_id)) ||
              (!oldClaimDamage.field_id && !loss.field_ids.length)),
        );

        // no lossForm was found for existing claim damage, delete it
        if (!lossForClaimDamage) {
          apis.store.dispatch(deleteClaimDamage(oldClaimDamage.claim_damage_id));
        } else {
          // else update
          apis.store.dispatch(
            updateClaimDamage(oldClaimDamage.claim_damage_id, getClaimDamageUpdates(harvest_year, lossForClaimDamage)),
          );
        }
      }

      lossForm.forEach(loss => {
        if (
          loss.field_ids.length == 0 &&
          !claimDamage.some(
            claimDamage =>
              harvestAggregationKeyEq(claimDamage, {
                ...loss,
                harvest_year: harvest_year,
              }) && !claimDamage.field_id,
          )
        ) {
          apis.store.dispatch(insertClaimDamage(apis, createClaimDamage(loss, currentClaimId!, null, claimForm)));
        } else {
          for (const fieldId of loss.field_ids) {
            const exists = claimDamage.some(damage => damage.crop_id === loss.crop_id && damage.field_id === fieldId);

            if (!exists) {
              apis.store.dispatch(
                insertClaimDamage(apis, createClaimDamage(loss, currentClaimId!, fieldId, claimForm)),
              );
            }
          }
        }
      });

      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    },
    [history, apis],
  );

  const formy = useSyncFormy(
    claimForm.claim_id ? 'edit' : 'new',
    () => claimForm,
    apis.t,
    onSubmit,
    validateClaimForm,
    null,
  );
  const lossForm = useFormyValue(formy, 'lossForm', true);
  const farmId = useFormyValue(formy, 'farm_id');
  const fields = useAsyncMemo(async () => {
    if (!farmId) {
      return [];
    }
    return await fetchEntitiesBy(apis.authedFetcher, 'field', {
      column: 'farm_id',
      operator: 'eq',
      value: farmId,
    });
  }, [apis, farmId]);
  const cropIds = useAsyncMemo(async () => {
    if (!farmId) {
      return [];
    }
    // get crop ids from field and farm level harvests
    const harvests = await fetchEntitiesBy(apis.authedFetcher, 'harvest', {
      or: [
        {
          column: 'farm_id',
          operator: 'eq',
          value: farmId,
        } as PostgrestQuery,
        fields && fields.length > 0
          ? ({
              column: 'field_id',
              operator: 'in',
              value: fields.map(value => value.field_id),
            } as PostgrestQuery)
          : null,
      ].filter(remove.nulls),
    });
    return unique(harvests.map(value => value.crop_id).filter(remove.nulls));
  }, [apis, farmId, fields]);
  const cropTags = useCropTags(cropIds ?? Object.keys(allCrops));
  const lossAdjusters = useMemo((): [string, string][] => {
    return Object.values(userEntitiesByEmail ?? {})
      .filter(userEntity => userEntity.email)
      .map(userEntity => {
        const status = claimForm?.assigned_to?.find(assignee => assignee?.email === userEntity.email)
          ?.visit_candidate_status;
        return [
          userEntity.email!, // TS doesn't detect that we filtered by null already, so we use !
          userEntityDesc(userEntity.email, userEntitiesByEmail) +
            (status ? '(' + apis.t(('VisitCandidateStatus_' + status) as I18nSimpleKey) + ')' : ''),
        ];
      });
  }, [apis, claimForm?.assigned_to, userEntitiesByEmail]);
  formy.watchValue('crop_ids', () => {
    const selectedCropIds = formy.getValue('crop_ids');
    const newLossForm = formy
      .getValue('lossForm')
      .filter((lossForm: LossForm) => selectedCropIds.includes(lossForm.crop_id!));
    for (const cropId of selectedCropIds) {
      // add new claim damages
      if (newLossForm.every(value => value.crop_id !== cropId)) {
        newLossForm.push({
          crop_id: cropId,
          field_ids: [],
          losses: [],
          loss_causes: [],
          vegetation_stage: null,
          organic: null,
          irrigated: null,
          variety: null,
        });
      }
    }
    formy.getChangeHandler('lossForm')(newLossForm);
  });
  formy.watchValue('farm_id', () => {
    formy.getChangeHandler('lossForm')([]);
    formy.getChangeHandler('crop_ids')([]);
  });
  const deleteClaimAction = useCallback(async () => {
    if (claimForm.claim_id) {
      const visits = await fetchEntitiesBy(apis.authedFetcher, 'visit', {
        column: 'claim_id',
        operator: 'eq',
        value: claimForm.claim_id,
      });
      if (visits.length) {
        setError({
          type: 'NoDeleteClaim',
          visitCount: visits.length,
        });
        return;
      }
      await apis.store.dispatch(deleteClaim(apis.authedFetcher, claimForm.claim_id));
      if (await commitMutations(apis)) {
        history.goBack();
      } else {
        setError('FailedToSyncChanges');
      }
    }
  }, [apis, claimForm, history]);
  return (
    <div className={'claims-form'}>
      <PageHeader
        title={claimForm.claim_id ? apis.t('EditClaim') : apis.t('AddClaim')}
        className="no-print" //Hide header when printing
        avatar={{icon: <FormOutlined />}}
      />
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Policy')}:</Label>
          <FormyEntitySelector entityType={'policy'} onNewEntity={null} formy={formy} field={'policy_id'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Farm')}:</Label>
          <FormyEntitySelector field="farm_id" formy={formy} entityType="farm" onNewEntity={null} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('ExternalClaimId')}:</Label>
          <FormyTextStr formy={formy} field={'external_claim_id'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('Comments')}:</Label>
          <FormyTextStr formy={formy} field={'comments'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('CropCoverage')}:</Label>
          <FormyEnum
            formy={formy}
            field={'coverage_type'}
            selectMsg={'Select'}
            options={CoverageType.map(coverageType => [coverageType, apis.t(coverageType)])}
          />
        </Col>
        <Col span={6}>
          <Label>{apis.t('HarvestYear')}:</Label>
          <FormyEnum
            formy={formy}
            field={'harvest_year'}
            selectMsg={'Select'}
            options={HarvestYear.map(harvestYear => {
              return [harvestYear, apis.t(harvestYear)];
            })}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('AssignedLossAdjusters')}:</Label>
          <FormyMultiEnum
            style={maxWidthResponsiveHeight}
            formy={formy}
            maxTagCount={20}
            field={'user_assigned_to'}
            selectMsg={'Select'}
            options={lossAdjusters ?? []}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        {farmId && (
          <Col span={6}>
            <Label>{apis.t('AffectedCrops')}:</Label>
            <FormyMultiEnum
              style={maxWidthResponsiveHeight}
              maxTagCount={20}
              formy={formy}
              field={'crop_ids'}
              options={cropTags.map(cropTag => {
                return [cropTag.cropId, cropTag.label];
              })}
              selectMsg={'Select'}
            />
          </Col>
        )}
      </Row>

      {Object.values(lossForm ?? []).map((lossFormValue, lossFormIndex) => {
        const sectionFormy = formy.getSectionFormy('lossForm').getSectionFormy(lossFormIndex);
        sectionFormy.watchValue('loss_causes', () => {
          const selectedLossCauses = sectionFormy.getValue('loss_causes');
          // keep only losses for selected loss causes
          const newLosses = (sectionFormy.getValue('losses') ?? []).filter(
            (loss: Loss) => loss.loss_cause && selectedLossCauses.includes(loss.loss_cause),
          );
          for (const loss of selectedLossCauses) {
            // add new losses
            if (newLosses.filter(value => value.loss_cause === loss).length === 0) {
              newLosses.push({
                loss_cause: loss,
                loss: null,
                loss_event_date: new Date().toISOString(),
                loss_recognized: null,
              });
            }
          }
          sectionFormy.getChangeHandler('losses')(newLosses);
        });
        return (
          <Row key={'lossForm-' + lossFormIndex} gutter={16}>
            <Col span={4} style={{marginTop: '25px'}}>
              <CropTag {...cropTags.find(t => t.cropId === lossFormValue.crop_id)!} />
            </Col>
            <Col span={5}>
              <Label>{apis.t('LossCause')}:</Label>
              <FormyMultiEnum
                style={maxWidthResponsiveHeight}
                formy={sectionFormy}
                maxTagCount={20}
                field={'loss_causes'}
                selectMsg={'SelectLossCause'}
                options={LossCause.map(lossCause => {
                  return [lossCause, apis.t(lossCause)];
                })}
              />
            </Col>
            <Col span={5}>
              <Label>{apis.t('VegetationStage')}:</Label>
              <FormyEnum
                formy={sectionFormy}
                field={'vegetation_stage'}
                selectMsg={'Select'}
                options={Array.from(VegetationStage)
                  .sort((a, b) => apis.t(a).localeCompare(apis.t(b)))
                  .map(vegetationStage => [vegetationStage, apis.t(vegetationStage)])}
              />
            </Col>
            <Col span={5}>
              <Label>{apis.t('DamagedFields')}:</Label>
              <FormyMultiEnum
                style={maxWidthResponsiveHeight}
                formy={sectionFormy}
                maxTagCount={20}
                field={'field_ids'}
                selectMsg={'SelectField'}
                options={
                  fields?.map((field: Field) => [field.field_id, fieldDesc(apis.t, allCrops, countryGroups, field)]) ??
                  []
                }
              />
            </Col>
            <Col span={2}>
              <FormyBool selectMsg={null} label={'IrrigatedQuestionMark'} formy={sectionFormy} field={'irrigated'} />
            </Col>
            <Col span={2}>
              <FormyBool selectMsg={null} label={'OrganicQuestionMark'} formy={sectionFormy} field={'organic'} />
            </Col>

            {sectionFormy.getValue('losses').map((loss, lossIndex) => (
              <React.Fragment key={'loss-' + lossFormIndex + '-cause-' + lossIndex}>
                <Col span={4} />
                <Col span={5} style={{display: 'flex', alignItems: 'center'}}>
                  <div
                    style={{
                      padding: '4px',
                      fontWeight: 'bold',
                    }}>
                    {loss.loss_cause ? apis.t(loss.loss_cause) : ''}
                  </div>
                </Col>
                <Col span={5}>
                  <Label>{apis.t('EstimatedYieldLoss')}:</Label>
                  <FormyUnit
                    units={YieldUnit}
                    formy={sectionFormy.getSectionFormy('losses').getSectionFormy(lossIndex)}
                    field={'loss'}
                    inline={true}
                  />
                </Col>
                <Col span={5}>
                  <Label>{apis.t('LossEventDate')}:</Label>
                  <FormyDatePicker
                    formy={sectionFormy.getSectionFormy('losses').getSectionFormy(lossIndex)}
                    field={'loss_event_date'}
                    label={'PleaseEnterValue'}
                  />
                </Col>
                <Col span={2} />
              </React.Fragment>
            ))}
            <Divider />
          </Row>
        );
      })}
      <Row gutter={16}>
        <h3>{apis.t('Manager')}</h3>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('FirstName')}:</Label>
          <FormyTextStr formy={formy} field={'manager_first_name'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('LastName')}:</Label>
          <FormyTextStr formy={formy} field={'manager_last_name'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Email')}:</Label>
          <FormyTextStr formy={formy} field={'manager_email'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('Phone')}:</Label>
          <FormyTextStr formy={formy} field={'manager_phone'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Divider />
      <h3>{apis.t('FarmerRepresentative')}</h3>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('FirstName')}:</Label>
          <FormyTextStr formy={formy} field={'contact_first_name'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('LastName')}:</Label>
          <FormyTextStr formy={formy} field={'contact_last_name'} label={'PleaseEnterValue'} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Label>{apis.t('Email')}:</Label>
          <FormyTextStr formy={formy} field={'contact_email'} label={'PleaseEnterValue'} />
        </Col>
        <Col span={6}>
          <Label>{apis.t('Phone')}:</Label>
          <FormyTextStr formy={formy} field={'contact_phone'} label={'PleaseEnterValue'} />
        </Col>
      </Row>

      <div className="add-farms-buttons">
        <Button type="default" icon={<CloseOutlined />} onClick={() => history.goBack()}>
          {apis.t('Back')}
        </Button>
        <FormySubmit type="primary" icon={<SaveOutlined />} formy={formy} label="Save" />
        {claimForm.claim_id && (
          <Button icon={<DeleteOutlined />} onClick={deleteClaimAction}>
            {apis.t('Delete')}
          </Button>
        )}
      </div>
      <div style={errorColor}>{error && apis.t(error)}</div>
    </div>
  );
};
