import {I18nFunction, I18nSimpleKey, Locale} from '../i18n/i18n';

export function formatMonthYear(t: I18nFunction, d: string | Date): string {
  if (typeof d == 'string') {
    if (d.length == 7) {
      d += '-01';
    }
    d = new Date(d);
  }

  const monthStr = t(('month_' + d.getUTCMonth()) as I18nSimpleKey);
  return `${monthStr} '${d.getUTCFullYear() - 2000}`;
}

export function formatDateToLocale(date: string | Date | null, locale: string): string {
  if (typeof date == 'string') {
    date = new Date(date);
  }
  if (!date || isNaN(date.getTime())) {
    return '-';
  }
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export function formatDate(t: I18nFunction, d: string | Date | null): string {
  if (typeof d == 'string') {
    d = new Date(d);
  }

  if (!d || isNaN(d.getTime())) {
    return '-';
  }

  const monthStr = t(('month_' + d.getUTCMonth()) as I18nSimpleKey);
  return `${d.getUTCDate()} ${monthStr} ${d.getUTCFullYear()}`;
}

export function formatDateRange(t: I18nFunction, min: string, max: string): string {
  const [minYear, minMonth, minDay] = min.split('-');
  const [maxYear, maxMonth, maxDay] = max.split('-');
  if (min == max) {
    return formatDate(t, min);
  } else if (minYear == maxYear && minMonth == maxMonth) {
    const monthStr = t(('month_' + String(parseInt(minMonth) - 1)) as I18nSimpleKey);
    return `${minDay} - ${maxDay} ${monthStr} ${minYear}`;
  } else if (minYear == maxYear) {
    const minMonthStr = t(('month_' + String(parseInt(minMonth) - 1)) as I18nSimpleKey);
    const maxMonthStr = t(('month_' + String(parseInt(maxMonth) - 1)) as I18nSimpleKey);
    return `${minDay} ${minMonthStr} - ${maxDay} ${maxMonthStr} ${minYear}`;
  } else {
    return formatDate(t, min) + ' - ' + formatDate(t, max);
  }
}
